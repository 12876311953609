import I18n from "i18n-js";

export class CustomEditorText {
  id;
  callbackMentionUrl;
  instance;
  status;
  constructor(options) {
    this.status = options.status; // normal or text box
    this.id = options.id,
    this.callbackMentionUrl = options.callbackMentionUrl    

    this.quill = init(options);
    this.textbox = document.getElementById(`${this.id}_normal`);
    
    if(options.submit){
      addBindingCreated(this.quill,options.submit)   
      function handleKeyupTextBox(e) {
        if (e.keyCode === 13 && !e.shiftKey) {  
            options.submit();   
            e.preventDefault();
            e.stopPropagation();
        }
      }   
      this.textbox.removeEventListener("keydown", handleKeyupTextBox, true); 
      this.textbox.addEventListener("keydown", handleKeyupTextBox,true);
      
    }
    else{
      // function handleKeyupTextBoxDefault(e) {
      //   if(e.which == 13) {
      //     var s = $(this).val();
      //     $(this).val(s+"\n");  //\t for tab
      //   }
      // } 
      // this.textbox.removeEventListener("keyup", handleKeyupTextBoxDefault, true);
      // this.textbox.addEventListener("keyup", handleKeyupTextBoxDefault)
    }
    
    
   

    var editor = document.querySelector(`#${this.id}_container`);
    this.toggle_tool = editor.querySelector("[name=toggle_tool]");
    Object.defineProperties(this.toggle_tool, {
      editor: {
        value: this,
        writable: true,
      },
    });
    this.toggle_tool.addEventListener("click", toggled, false);
    set_value_init(this);
    
  }
  reset() {
    // this.instance
    // var editor = document.querySelector(`#${this.id}_container`);
    // editor.querySelector('.ql-editor').replaceChildren();
    // editor.querySelector('.ql-editor').innerHTML = '<p></p';
    this.quill.setText("");
    this.textbox.value = "";
    this.focus();
  }
  focus() {
    if (this.toggle_tool.checked) {
      this.quill.focus();
    } else {
      this.textbox.focus();
    }
  }
  clear() {
    var editor = document.querySelector(`#${this.id}_container`);
    editor.querySelector(".ql-editor").replaceChildren();
    editor.querySelector(".ql-editor").innerHTML = "<p></p";
  }
  value() {
    if (this.toggle_tool.checked) {
      return this.quill.getLength() > 1 ? this.quill.root.innerHTML : "";
    } else {
      return this.textbox.value;
    }
  }
}
function init(options) {
  // https://stackoverflow.com/questions/43728080/how-to-add-font-types-on-quill-js-with-toolbar-options
  let Font = Quill.import("formats/font");
  Font.whitelist = [
    "mspgothic",
    "msmincho",
    "timenewroman",
    "arial",
    "sansserif",
  ];
  Quill.register(Font, true);
  
  return new Quill(`#${options.id}`, getSetting(options));
}

function getSetting(options) {
  // const toolbarOptions = [
  //   ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //   ['blockquote', 'code-block'],
  //   ['link', 'image', 'video', 'formula'],

  //   [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  //   [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  //   [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //   [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //   [{ 'direction': 'rtl' }],                         // text direction

  //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  //   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  //   [{ 'font': [] }],
  //   [{ 'align': [] }],

  //   ['clean']                                         // remove formatting button
  // ];
  var tollMention = options.callbackMentionUrl ? {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    source: function (searchTerm, renderList, mentionChar) {
      var content_comment = document.querySelector(".ql-editor").innerHTML;
      var doc = new DOMParser().parseFromString(content_comment, "text/html");
      var list_mention_user = doc.getElementsByClassName("mention");
      if (list_mention_user.length > 0) {
        var arr_mention_user = [];
        $.each(list_mention_user, function (index, elem) {
          arr_mention_user.push($(elem).data("id"));
        });
      }
      $.ajax({
        url: options.callbackMentionUrl,
        type: "get",
        data: {
          q: searchTerm,
          without_user: arr_mention_user,
        },
        async: true, //This works but freezes the UI
      }).done(function (values) {
        renderList(values, searchTerm);
      });
    },
  } : null;
  return {
    modules: {
      toolbar: `#${options.id}_container #${options.id}_toolbar-container`,     
      mention: tollMention
    },
    bounds: `#${options.id}`,
    theme: "snow",
  };
}

function toggled(ev) {
  var toggle = ev.target;
  if (toggle.checked) {
    toggle.editor.quill.setText(toggle.editor.textbox.value);
    const length = toggle.editor.quill.getLength(); // Lấy độ dài nội dung hiện tại
    toggle.editor.quill.setSelection(length - 1, 0); // Đặt con trỏ ở vị trí cuối cùng

  } else {
    // var value =
    //   toggle.editor.quill.getLength() > 1
    //     ? toggle.editor.quill.root.innerHTML
    //     : "";
    // var plainText = remove_tags_keep_break_line(value).trim();
    var delta = toggle.editor.quill.getContents();
    var json_string = JSON.stringify(delta);
    var ops = JSON.parse(json_string).ops;
    var plainText = "";
    for (let index = 0; index < ops.length; index++) {
      const element = ops[index];
      if(typeof element.insert == 'string'){
        // if(element.insert.trim() != '\n' && element.insert.trim() != ''){
        //   plainText += ( element.insert.trim());
        // }
        plainText += element.insert;
      }
      
    }    
    if (plainText.trim() != "") {
      var m = confirm(I18n.t("quill_tooltip_toolbar.warning"));
      if (m) {
        toggle.editor.textbox.value = plainText.replace(/\n\s*\n/g, '\n').trim();
        toggle.editor.focus();
      } else {
        ev.preventDefault();
      }
    }
  }
  toggle.editor.focus();
}

function set_value_init(editor) {
  if (isHTML(editor.value())) {
    editor.toggle_tool.checked = true;
  }
}

function isHTML(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}


function addBindingCreated(quill,submit){
    const keyboard = quill.getModule('keyboard');
    delete keyboard?.bindings['Enter']; // HERE clear all Enter bindings

    quill.keyboard.addBinding(    {
        key: 13, //Enter key    
        shiftKey: false,   
        handler: submit
    }	
    );
	quill.keyboard.addBinding(    {
        key: 13, //Enter key
		    shiftKey: true, 
      
      handler: function(range, context) {
		    // this.editor.insertText(range.index, '\n');
        
        this.quill.setSelection(range.index,'silent');
        this.quill.insertText(range.index, '\n', 'user')
        this.quill.setSelection(range.index +1,'silent');
        this.quill.format('linebreak', true,'user');
	  }}	
    );
}

