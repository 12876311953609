$(document).on('turbo:load', function() {
	if ($("#tree_view_data_folder_share_outer").length){
    initTreeDataFolderShareOuter();
  }

  $('body').delegate('#btn_delete_file_share_outer', 'click', function(e) {
    // var lst_selected = GetListIdSelected(tableDataFolderFileShareOuter);
    var lst_selected = [];
    $.each($('.tbl-file-data-folder-share-outer tbody tr'), function (i, row) {
      if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
        lst_selected.push($(row).data('item-id'));
      } 
    });
    var folder_id = getSelectedTreeDataFolderShareOuter("#tree_view_data_folder_share_outer");  
    var view_token = $(this).attr('data-view-token');
    if (lst_selected.length == 0) return;
    $.ajax({
      type: 'GET',
      url: "/confirm_delete_multiple_file_share_outer",
      dataType: 'script',
      data: {
        files: lst_selected,
        data_folder_id: folder_id,
        view_token: view_token
      },
      success: function (data, textStatus, jqXHR) {
        window.$("#loadMe").modal('hide');
      },
      error: function (jqXHR, textStatus, errorThrown) {
        window.$("#loadMe").modal('hide');
        // console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  // change select role
  $('body').delegate('.select_role_share_outer', 'change', function(e) {
    var role = this.value;
    var record_selected = this.parentElement.parentElement.dataset.itemId;

    $.ajax({
      type: 'GET',
      url: "/update_role_permission_data_folder_share_outer",
      dataType: 'script',
      data: {
        role: role,
        record_selected: record_selected,
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
      }
    });
  });

  // move data folder share outer
  $('body').delegate('.block-data-folder-share-outer-relocation', 'click', function(e) {   
    var changed_parent_id = parseInt($(this).find(".data-folder-share-outer-id").text());
    $.ajax({
      type:"GET",
      url:"/sub_data_folder_share_outer_relocation",
      dataType:"script",
      data: {
        id: getSelectedTreeDataFolderShareOuter("#tree_view_data_folder_share_outer"),
        parent_id: changed_parent_id,
        view_token: document.getElementById("view_token").value
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR.responseText)
      }
    })
  });

  // move multi file data folder share outer
  $('body').delegate('#btnMoveMultipleFileShareOuter', 'click', function(e) {      
    var lst_selected = [];
    $.each($('.tbl-file-data-folder-share-outer tbody tr'), function (i, row) {
      if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
        lst_selected.push($(row).data('item-id'));
      } 
    });
    var folder_id = $("#folder-id-relocation").val();
    var old_folder_id = getSelectedTreeDataFolderShareOuter("#tree_view_data_folder_share_outer");
    var view_token = document.getElementById("view_token").value;
    if (lst_selected.length == 0) return;
    $.ajax({
      type: 'GET',
      url: "/update_location_multiple_file_share_outer",
      dataType: 'script',
      data: {
        files: lst_selected,
        folder_id: folder_id,
        old_folder_id: old_folder_id,
        view_token: view_token
      },
      success: function (data, textStatus, jqXHR) {
        window.$("#loadMe").modal('hide');
      },
      error: function (jqXHR, textStatus, errorThrown) {
      }
    });
  });

  $('body').delegate('.block-file-relocation-share-outer', 'click', function(e) {   
    var changed_parent_id = parseInt($(this).find(".folder-id").text());
    $.ajax({
      type:"GET",
      url:"/sub_folder_relocation_data_file_share_outer",
      dataType:"script",
      data: {
        id: changed_parent_id,
        view_token: document.getElementById("view_token").value
      },
      success: function(data, textStatus, jqXHR){
            
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR.responseText)
      }
    });
  });


});

window.initTreeDataFolderShareOuter = function (){
  var elemtree = document.getElementById('data_folder_share_outer_js');
  if(!elemtree) return;
  
  var datajs = elemtree.value;
  
  datajs = datajs.replace(/'/g, '"');
  datajs = JSON.parse(datajs);

  $('#tree_view_data_folder_share_outer').jstree({    
  core: {
    // themes: { dots: false},
    "check_callback": true,
    'data' : datajs
  }
})
.on('loaded.jstree', function(e,data) {
  var folder_id = $("#current-folder-id").val();
  var childrens = $("#tree_view_data_folder_share_outer").jstree("get_children_dom","#");
  if(childrens.length>0) {
    if (folder_id != null) {
      SetSelectedTreeDataFolderShareOuter(folder_id, "#tree_view_data_folder_share_outer");
    }
    else {
      $('#tree_view_data_folder_share_outer').jstree('select_node', childrens[0].id);
    }
    $("#tree_view_data_folder_share_outer").jstree("open_node", childrens[0].id, false, true);
    $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');
  }

})
.on("select_node.jstree", function (e, data) { 
  if(data){
  	var view_token = document.getElementById("view_token").value;
    $.ajax({
      type: "GET",
      url: "/sub_data_folder_share_outer",
      dataType: "script",
      data: { 
      	data_folder_id: data.node.id,
      	view_token: view_token
      },
      success: function (data, textStatus, jqXHR) {
        formatTableDataFolderFileShareOuter();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    });
  }
})

  $("#tree_view_data_folder_share_outer").on('open_node.jstree', function (event, data) {
    $('#' + data.node.id).find('i.jstree-icon.jstree-ocl').first().addClass('icon-plus');

    $('li.jstree-open > i.jstree-icon.jstree-ocl').addClass('icon-plus');
    $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');

  });
}
  
window.SetSelectedTreeDataFolderShareOuter = function (node_id, id_tree_view){
  $(id_tree_view).jstree().deselect_all(true);
  $(id_tree_view).jstree('select_node',node_id);
}

window.getSelectedTreeDataFolderShareOuter = function (id_tree_view){
  var node_selected = $(id_tree_view).jstree("get_selected");
  if(node_selected.length>0){
    return node_selected[0];
  }
  else{
    return "0";
  }
}

window.formatTableDataFolderFileShareOuter = function() {
  var tblFile = document.getElementsByClassName("tbl-file-data-folder-share-outer");
  if (tblFile.length > 0) {
    $('body').delegate('#btn_download_file_data_folder_share_outer', 'click', function(e) {   
      var lst_selected = [];
      $.each($('.tbl-file-data-folder-share-outer tbody tr'), function (i, row) {
        if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
          lst_selected.push($(row).data('item-id'));
        } 
      });
      var folder_id = getSelectedTreeDataFolderShareOuter("#tree_view_data_folder_share_outer");  
      if (lst_selected.length == 0) return;  
      window.location = '/download_multiple_file_data_folder_share_outer?files=' + lst_selected + '&folder_id=' + folder_id;
      hideFormLoading();
    });
  }
}

window.changeTextNodeGeneral = function(node,name_folder, id_tree_view) {
  $(id_tree_view).jstree('rename_node', node, name_folder);
  $('li.jstree-open > i.jstree-icon.jstree-ocl').addClass('icon-plus');
  $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');
}

window.appendChildNodeTreeViewGeneral = function (idChild,text,text_folder_parent,icon_folder,id_tree_view, view_token){
  var node_selected = $(id_tree_view).jstree("get_selected");
  if(node_selected.length>0){
     $(id_tree_view).jstree().create_node(node_selected[0], {
      "id": idChild,
      "text": text,
      "icon": icon_folder
    }, "last", function() {
        var first_node = $(id_tree_view).jstree("get_children_dom","#")[0].id;
        $('#' + node_selected[0]).find('i.jstree-icon.jstree-ocl').first().addClass('icon-plus');
        if (first_node != node_selected[0]) {
          $(id_tree_view).jstree('rename_node', node_selected[0], text_folder_parent);
        }
        $(id_tree_view).jstree("open_node", $('#' + node_selected[0]));
        $('li.jstree-open > i.jstree-icon.jstree-ocl').addClass('icon-plus');
        $('li.jstree-closed > i.jstree-icon.jstree-ocl').addClass('icon-plus');
        $.ajax({
          type: "GET",
          url: "/sub_data_folder_share_outer",
          dataType: "script",
          data: { 
          	data_folder_id: node_selected[0],
          	view_token: view_token
          },
          success: function (data, textStatus, jqXHR) {
          },
          error: function (jqXHR, textStatus, errorThrown) {
          }
        });
    });
  }
}

window.formatBootstrapDatePickerEventChangeDate = function(element_input) {
  $(element_input).datepicker({
    language: get_locate(),
    format: 'yyyy-mm-dd',
    autoclose: true,
  }).on('changeDate', function(e) {
    console.log(e.date);
    var item_id = this.parentElement.parentElement.dataset.itemId;
    $.ajax({
      type: "GET",
      url: "/update_time_permission_data_folder_share_outer",
      dataType: "script",
      data: { 
        item_id: item_id,
        date_selected: e.date
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
      }
    });
  });
}

window.formatBootstrapDatePicker = function(element_input) {
  $(element_input).datepicker({
    language: get_locate(),
    format: 'yyyy-mm-dd',
    autoclose: true,
  })
}


