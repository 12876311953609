$(document).on('turbo:load', function() {
  $("body").delegate('#btn_save_wiki', 'click', function() {
    $('#btn_submit_description_wiki').click();
    $(this).attr('disabled', 'disabled')
    showFormLoading();
  });

  // $('body').delegate('#btn_upload_file_wiki', 'click', function (e) {
  //   $("#js_add_more_attachment_wiki").click()
	// });

  // $('body').delegate('#select_file_for_wiki_box', 'cocoon:after-insert', function (e) {
  //   $(this).find("div.nested-fields:last input:first").click();
  //   $(this).find("div.nested-fields:last").addClass("d-none");
	// });

  $('body').delegate('#select_file_for_wiki_box div.nested-fields .js_input_attachment', 'cancel', function() {
		if (this.files.length < 1) {
			$(this).parent().find(".js_remove_attachment").click()
		}
	})

//   $("body").delegate(
//     "#select_file_for_wiki_box div.nested-fields .js_input_attachment",
//     "change",
//     function () {
//       var $input = $(this);
//       if (this.files && this.files.length) {
//         $(this).parent().parent().removeClass("d-none");
//         var size = this.files[0].size;
//         var name = this.files[0].name;
//         var extension = name.split(".").pop();
//         if (size >= 1000000) {
//           size = (size / 1000000).toFixed(2).toString() + " MB";
//         } else if (size >= 1000) {
//           size = (size / 1000).toFixed(2).toString() + " KB";
//         } else {
//           size = size.toString() + " B";
//         }
//         let reader = new FileReader();
//         this.enabled = false;
//         reader.onload = function (e) {
//           let template = [];
//           template.push(
//             '<div class="name-file"><div class="fw-bold d-flex font-12 mx-2 mt-1"><div class="text-truncate">' +
//               name +
//               "</div><span>." +
//               extension +
//               '</span></div><p class="mb-1 font-10 mx-2">' +
//               size +
//               "</p></div>"
//           );
//           $input.prev(".js_add_attachment").html(template.join(""));
//         };
//         $(this)
//           .parent()
//           .find(".js_input_attachment_type")
//           .val(this.files[0].type);
//         $(this)
//           .parent()
//           .find(".js_label_attachment")
//           .html(`<label>${this.files[0].name}</label>`);
//         reader.readAsDataURL(this.files[0]);
//       }
//     }
//   );
});

window.dropzoneWiki = function () {
  if ($("#wiki_form").length > 0) {
    var max_file_sizeKb =
      (parseInt(document.getElementById("file_maximum_capacity").value) *
        1000 *
        1000) /
      (1024 * 1024);
    $("#myDropzoneWiki").dropzone({
      url: $("#myDropzoneWiki").attr("action"),
      autoProcessQueue: false,
      paramName: "attachment[]",
      maxFilesize: max_file_sizeKb,
      parallelUploads: 100,
      // addRemoveLinks: true,
      previewTemplate: document.querySelector("#uploadPreviewTemplateWki")
        .innerHTML,
      maxFiles: 100,
      acceptedFiles: getListAcceptFileType("default"),
      clickable: true,
      accept: function (file, done) {
        defineIcon(file);
        done();
      },

      init: function () {
        setMessageErrorFile(this.options);
        var isSent = false;
        var myDropzone = this;

        document
          .getElementById("btnSaveAttachmentWki")
          .addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          });

        this.on("error", function (file, message, xhr) {
          messageErrorFile(file, message);
          defineIcon(file);
          $(file.previewElement).find(".id_file_wiki").removeClass("id_file_wiki");
          var count_file_upload = document.getElementsByClassName("id_file_wiki").length;
          if (count_file_upload == 1) {
            document.getElementById("is_file_upload_wiki").value = 0;
          }
        });

        this.on("addedfile", function (file) {
          document.getElementById("borrow_id_wiki").style.display = "block";
          document.getElementById("is_file_upload_wiki").value = 1;
          var nameElement = file.previewElement.querySelector("[data-dz-name]");
          nameElement.title = file.name;
        });

        this.on("success", function (file, progress, bytesSent) {
          isSent = true;
        });

        this.on("removedfile", function (file) {
          // var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
          var count_file_upload =
            document.getElementsByClassName("id_file_wiki").length;
          if (count_file_upload == 1) {
            document.getElementById(
              "is_file_upload_wiki"
            ).value = 0;
          }
        });

        this.on("complete", function (file) {
          if ( this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent
          ) {
            window.location.href = "/project_manager/master_plans/" + $("#overview_master_plan_id").val() + "/overview"
          }
        });
      },
    });
  }
};
