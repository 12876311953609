import {TextLayerBuilder} from './text_layer_builder'  ;
import { ANNOTATION } from '../edit/annotation';
import { init } from '../fabric/init';
import { add_element_annotation_to_content } from '../fabric/load_json';
import { factory } from '../annotation/factory';
import { canvas_helper } from '../canvas/canvas_helper';
// class create Container contain content of file

export class PDFContent{
  baseView  
  
  constructor(baseView){
    this.baseView = baseView
  }

  create(){  
    this.baseView.viewComponents.viewContainer.replaceChildren(); 
    load(this.baseView);
  }   
   
  renderPage(pageNumber,force = false){
    renderPage(this.baseView.file.pages[pageNumber],this.baseView,force);
  }
  
  renderViewPage(baseView){
    var divPage = baseView.viewComponents.viewContainer.querySelector(`#page-${baseView.currentPageNumber}`);
    var page = baseView.file.pages[baseView.currentPageNumber-1];
    var rotate = (page.rotate + page.viewRotate) % 360;  
    var viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
    divPage.style.width = viewport.width + "px";
    divPage.style.height = viewport.height + "px";    
    createcanvas(viewport,page,divPage,baseView);
  }

  updateScale(baseView){
    var page,viewport,rotate;
    var divPage,divLoadingIcon;
    var i;
    for (i = 0; i < baseView.file.pages.length; i++) {  
        page = baseView.file.pages[i];
        rotate = (page.rotate + page.viewRotate) % 360;  
        viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
        divPage = baseView.viewComponents.viewContainer.querySelector(`#page-${i+1}`);
        divPage.style.width = viewport.width + "px";
        divPage.style.height = viewport.height + "px";
        var formLayer = divPage.querySelector('.formLayer')
        if(formLayer){
          formLayer.replaceChildren();
        } 
        if(i== baseView.currentPageNumber-1){
          updatecanvas(viewport,page,divPage,baseView);  
        }
        else{
          divPage.replaceChildren();
          divLoadingIcon = document.createElement("div");
          divLoadingIcon.setAttribute("class", "loadingIcon notVisible");
          divLoadingIcon.setAttribute("role", "img");
          divLoadingIcon.setAttribute("aria-label","Loading...");        
          divPage.appendChild(divLoadingIcon);           
          divPage.setAttribute("data-loaded",false);    
        }
      
    }
    
  }

  add_annotation(canvas,object_annotation,page_index){    
    if(!canvas){return;}  
    add_element_annotation_to_content(canvas,object_annotation,this.baseView,page_index) ;
  }

  update_annotation(canvas,object_annotation,page_index){   
    if(!canvas){return;}
    this.remove_annotation(canvas,object_annotation); 
    this.add_annotation(canvas,object_annotation,page_index);    
  }
  remove_annotation(canvas,annotation){
    var success = false;
    canvas.getObjects().forEach((element) => {
        if(element.id == annotation.id){
          factory.delete(canvas,element);
          success = true;          
          canvas.renderAll();
          return true;
        }
          
    });             
    if(!success){
      console.log("cannot remove id:" + annotation.id)
    }
    
    var elem = this.baseView.viewComponents.viewContainer.querySelector(`[id='${annotation.id}']`);
    if(elem){
      elem.remove();
    }
    return success;
  }
  
}



function load(baseView){
    var page,viewport,rotate;
    var divPage,divLoadingIcon;
    var i;
    baseView.fabricpageAnnotations = [];
    for (i = 0; i < baseView.file.pages.length; i++) {  
        page = baseView.file.pages[i];
        rotate = (page.rotate + page.viewRotate) % 360;  
        viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate});
        divPage = document.createElement("div");
        divPage.setAttribute("id", "page-" + (page.pageNumber));
        divPage.setAttribute("class", "page");
        divPage.setAttribute("data-page-number", page.pageNumber);
        divPage.setAttribute("aria-label","Page " + (page.pageNumber));
        divPage.setAttribute("style", "position: relative");        
        divPage.style.width = viewport.width + "px";
        divPage.style.height = viewport.height + "px";

        divLoadingIcon = document.createElement("div");
        divLoadingIcon.setAttribute("class", "loadingIcon notVisible");
        divLoadingIcon.setAttribute("role", "img");
        divLoadingIcon.setAttribute("aria-label","Loading...");        
        divPage.appendChild(divLoadingIcon);  
        baseView.viewComponents.viewContainer.appendChild(divPage);      
        baseView.fabricpageAnnotations.push(null);
    }
}

function createElementCanvasannotation(canvas){
  var canvasannotation = canvas.cloneNode();
  canvasannotation.width = canvas.width;
  canvasannotation.height = canvas.height;
  return canvasannotation;
}

function createcanvas(viewport,page,div_page,baseView){
  var is_data_loaded = div_page.getAttribute("data-loaded");  
  if(is_data_loaded == "rendering") {return;}  
  div_page.setAttribute("data-loaded",'rendering');  
  var canvas = document.createElement("canvas");  
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  var divcanvasWrapper = document.createElement("div");  
  divcanvasWrapper.setAttribute("class", "canvasWrapper");  
  divcanvasWrapper.style.width = viewport.width + "px";
  divcanvasWrapper.style.height = viewport.height + "px";
  divcanvasWrapper.appendChild(canvas);
  

  var textLayerDiv = document.createElement("div");
  textLayerDiv.setAttribute("class", "textLayer");

  textLayerDiv.style.width = viewport.width + "px";
  textLayerDiv.style.height = viewport.height + "px";
  

  var canvasannotation = createElementCanvasannotation(canvas);   

  var annotationLayer = document.createElement("div");
  annotationLayer.setAttribute("class", "annotationLayer"); 
  const renderContext = {
    annotationMode:  2,
    background: 'white',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  // if (baseView.renderTask){
  //   // baseView.renderTask.cancel();
  //   page.cleanup();
  // }
  baseView.renderTask = page.render(renderContext);

  // Wait for rendering to finish
  baseView.renderTask.promise.then(function() {  
    page.getTextContent().then(function(textContent) {
      // Set it's class to textLayer which have required CSS styles
     
      const eventBus = new pdfjsViewer.EventBus();
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });
      
      page.getAnnotations().then(function (annotationsData) {    
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService,
          textLayerMode: 2  
        });        
      })
   
      var textLayer = new TextLayerBuilder({
        textLayerDiv: textLayerDiv, 
        pageIndex: page._pageIndex,
        viewport: viewport
      });
  
      textLayer.setTextContent(textContent);      
      textLayer.render();     
      div_page.replaceChildren();
      div_page.appendChild(divcanvasWrapper);        
      div_page.appendChild(canvasannotation);  
      div_page.appendChild(textLayerDiv);
      div_page.appendChild(annotationLayer);
      var fcanvas = init(canvasannotation,baseView,page._pageIndex);
      baseView.fabricpageAnnotations[page._pageIndex] = fcanvas;     
      div_page.setAttribute("data-loaded",true);   
    });       
  }).internalRenderTask;
  
  
    
}
function updatecanvas(viewport,page,div_page,baseView){
  // if (baseView.renderTask) {
  //   baseView.renderTask.cancel();
  // }
  var is_data_loaded = div_page.getAttribute("data-loaded");  
  if(is_data_loaded == "rendering") {return;}  
  div_page.setAttribute("data-loaded",'rendering');  
  var divcanvasWrapper = div_page.querySelector(".canvasWrapper");  
  divcanvasWrapper.replaceChildren();
  var textLayerDiv,annotationLayer,textLayer;
  textLayerDiv = div_page.querySelector(".textLayer");
  textLayerDiv.replaceChildren();

  annotationLayer = div_page.querySelector(".annotationLayer");
  annotationLayer.replaceChildren();
  
  // Set id attribute with page-#{pdf_page_number} format
  divcanvasWrapper.style.width = viewport.width + "px";
  divcanvasWrapper.style.height = viewport.height + "px";  

  // var canvas = divcanvasWrapper.querySelector('CANVAS');
  var canvas = document.createElement("canvas");  
 

  canvas.height = viewport.height;
  canvas.width = viewport.width;
  divcanvasWrapper.appendChild(canvas);
  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

  var canvasContainer = div_page.querySelector(".canvas-container");   
  var canvasannotation = createElementCanvasannotation(canvas);
  if(canvasContainer){
    canvasContainer.replaceWith(canvasannotation); 
  } 

  var fcanvas = init(canvasannotation,baseView,page._pageIndex);
  baseView.fabricpageAnnotations[page._pageIndex] = fcanvas; 
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const renderContext = {
    annotationMode:  2,
    // background: 'white',
    canvasContext: ctx,
    viewport: viewport,
  };
 
  baseView.renderTask = page.render(renderContext);
  const eventBus = new pdfjsViewer.EventBus();
  const pdfLinkService = new pdfjsViewer.PDFLinkService({
    eventBus,
  });
  baseView.renderTask.promise.then(function() {    
    page.getTextContent().then(function(textContent) {
      textLayerDiv.style.width = viewport.width + "px";
      textLayerDiv.style.height = viewport.height + "px";        
      
      page.getAnnotations().then(function (annotationsData) {    
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService
        });
        
      })    

      textLayer = new TextLayerBuilder({
        textLayerDiv: textLayerDiv, 
        pageIndex: page._pageIndex,
        viewport: viewport
      });
  
      textLayer.setTextContent(textContent);      
      textLayer.render();         
      div_page.setAttribute("data-loaded",true);     
    });       
  }).internalRenderTask;
  
}
function renderPage(page,baseView,force = false) {
    try{   
      var div_page = baseView.viewComponents.viewContainer.querySelector(`#page-${page.pageNumber}`);
      var is_data_loaded = div_page.getAttribute("data-loaded");  
      if(is_data_loaded == "true" && !force ) {return;}  
      var rotate = (page.rotate + page.viewRotate) % 360;  
      var viewport = page.getViewport({scale: baseView.currentScaleValue,rotation:rotate}); 
      createcanvas(viewport,page,div_page,baseView);
    
  }
  catch(error)
  {  
    console.log("Render error page" + page.pageNumber);
    console.log(error.message);
    throw error;
  }
}

function settingDisplay(baseView,div){
  if(baseView.current_annotation != ANNOTATION.NONE )
  {
    div.classList.add("no-display");
  }
}