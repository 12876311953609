import { PDFBaseViewer } from './PDFBaseViewer';
import { ANNOTATION,FORM_STATUS } from '../edit/annotation';
import {TextHelper} from '../edit/text_helper';
import {fabricUltil} from '../fabric/events/fabric_helper';
import {toolBarHelper} from '../edit/toolbar_helper';
import {PDFSave} from '../edit/save_helper';
import {Config} from '../define/AppConfig';
import {TYPE_HISTORY,TYPE_OBJECT} from '../edit/undo_redo';
import {TakeScreenShot} from '../features/take_screen_shot';
import {configHelper} from '../edit/config_helper';
import {element_helper} from '../edit/element_helper';
import { PDFSaveAnnotation } from '../edit/save_annotation';
import { ultil_mdethods } from '../common/ultil_methods';
import { factory } from '../annotation/factory';
import { fabric_detail_event } from '../fabric/events/fabric_detail_events';
import { PDFDownloadHelper } from '../helper/pdf_download_helper';
import { stamp_helper } from '../common/stamp_helper';
import { url_helper } from '../../../packs/supports/url_helper';
import { canvas_helper } from '../canvas/canvas_helper';
import { ObjectHelper } from '../fabric/events/object_helper';
import I18n from 'i18n-js';
import { download_helper } from '../../../packs/supports/download_helper';
import { RealTime } from '../realtime/realtime';
export class PDFViewer extends PDFBaseViewer{   
    current_annotation = ANNOTATION.NONE
    pointMouseDown = null
    pointMouseUp = null
    _activeObject = null
    _activeCanvas = null
    _activeElement = null
    takeScreenShot = null
    mouseDown    
    origX 
    origY
    objectDrawing   
    image_selected
    set_annotation(annotation){
      if(this.file.encripted && (annotation != (ANNOTATION.HAND || ANNOTATION.NONE || ANNOTATION.SCREENSHOT))){
        if(annotation != ANNOTATION.NONE && annotation != ANNOTATION.HAND && annotation != ANNOTATION.SCREENSHOT){
          alert( I18n.t('pdf.common.file_encript'))
          return;
        }
       
      }
      this.current_annotation = annotation;
      this._matching_annotation(annotation);         
      if(this.current_annotation == ANNOTATION.HAND){
        this.viewComponents.viewContainer.setAttribute('text-selection',true);       
        this.handTool.activate();
        }
        else{
        this.handTool.deactivate();
        if(this.current_annotation == ANNOTATION.NONE 
          || this.current_annotation == ANNOTATION.TEXT_HIGHLIGHT 
          || this.current_annotation == ANNOTATION.TEXT_STROKEOUT 
          || this.current_annotation == ANNOTATION.TEXT_UNDERLINE
          ){
            this.viewComponents.viewContainer.setAttribute('text-selection',true);       
          }
          else{    
            this.viewComponents.viewContainer.setAttribute('text-selection',false);
        }  
        }
      toolBarHelper.hideToolProperties();
      toolBarHelper.setdisplay(this.current_annotation );
      if(this.current_annotation != ANNOTATION.SCREENSHOT && this.takeScreenShot){
        this.takeScreenShot.end();
      }       
      url_helper.add_params_to_url("annotation",this.current_annotation)        
    }
    update_realtime(data){
      var realtime = new RealTime(this)
      realtime.process(data)
    }
    // update_annotation(annotation,action,user_update_id){            
     
    //   var is_current_user = (parseInt( user_update_id) == parseInt(this.user_id))
    //   if(is_current_user){return;}

    //   var page_index = parseInt(annotation.page-1)
    //   var object_annotation = JSON.parse(annotation.json);
    //   var canvas = this.fabricpageAnnotations[page_index];     
    //   var item = canvas_helper.check_exist_item(this.file.pages[page_index].annotation,object_annotation);
    //   if(action == 'update' && item == null){
    //     action = 'create';
    //   }
    //   console.log('Async -> action: '  + action + '  ID: '  + annotation.id)
    //   if(action == 'create'){               
    //     canvas_helper.add_annotation_to_list(this.file.pages[page_index].annotation,object_annotation); 
    //     this.pdfThumbnail.add_annotation(object_annotation,page_index);
    //     this.pdfContent.add_annotation(canvas,object_annotation,page_index)
    //   }
    //   if(action == 'update'){        
    //     for (var i = this.file.pages[page_index].annotation.length - 1; i >= 0; --i) {
    //         if (this.file.pages[page_index].annotation[i].id == object_annotation.id) {
    //           this.file.pages[page_index].annotation[i] = object_annotation;
    //           break;
    //         }
    //     }
       
    //     this.pdfThumbnail.update_annotation(object_annotation,page_index)
    //     this.pdfContent.update_annotation(canvas,object_annotation,page_index)
    //   }

    //   if(action == 'delete'){                 
    //     for (var i = this.file.pages[page_index].annotation.length - 1; i >= 0; --i) {
    //         if (this.file.pages[page_index].annotation[i].id == object_annotation.id) {
    //           this.file.pages[page_index].annotation.splice(i,1);
    //           break;
    //         }
    //     }
        
    //     this.pdfThumbnail.remove_annotation(object_annotation.id,page_index)
    //     this.pdfContent.remove_annotation(canvas,object_annotation);
    //   }
      
     
    // }
    // create_realtime(data){
    //   var annotation = data.annotation;
      
    //   if(parseInt(annotation.user_id) != parseInt(this.user_id))
    //   {
    //     console.log(data.action)
    //     var page_index = parseInt(annotation.page)-1
    //     var object_annotation = JSON.parse(annotation.json)
    //     canvas_helper.add_annotation_to_list(this.file.pages[page_index].annotation,object_annotation); 
    //     this.pdfThumbnail.add_annotation(object_annotation,page_index);
    //     this.pdfContent.add_annotation(this.fabricpageAnnotations[page_index],object_annotation,page_index)
    //   }
    // }
    // update_realtime(data){
    //   var annotation = data.annotation;
      
    //   var page_index = parseInt(annotation.page)-1   
    //   var object_annotation = JSON.parse(annotation.json)
    //   for (var i = this.file.pages[page_index].annotation.length - 1; i >= 0; --i) {
    //     if (this.file.pages[page_index].annotation[i].id == object_annotation.id) {
    //       this.file.pages[page_index].annotation[i] = object_annotation;
    //       break;
    //     }
    //   }       
    //   this.pdfThumbnail.update_annotation(object_annotation,page_index)
    //   this.pdfContent.update_annotation(this.fabricpageAnnotations[page_index],object_annotation,page_index)
    // }
    get activeObject(){
      return this._activeObject;
    }
    set activeObject(value){
      this._activeElement = null;
      this._activeObject = value;
    }

    get activeElement(){
      return this._activeElement;
    }
    set activeElement(value){
      this._activeObject = null;
      this._activeElement = value;
    }
    
    async reloadData(url){     
      await this.reload(url);        
    }  
    page_change(num){
      this.renderPage(num-1);      
      this.activeCanvas = canvas_helper.getFabricItem(this.fabricpageAnnotations,num-1);
    }
    select_text(){
      this.set_annotation(ANNOTATION.NONE);
    }
    select_annotation(){
      this.set_annotation(ANNOTATION.MOVE);
    }
    select_hand(){
      this.set_annotation(ANNOTATION.HAND);
    }
    
    mousedown(e){
      this.pointMouseDown = {clientX: e.clientX, clientY : e.clientY};   
    }
    mouseup(e){
      this.pointMouseUp = {clientX: e.clientX, clientY : e.clientY}; 
      var selection;    
      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.selection) {
        selection = document.selection.createRange();
      }    
      if (selection.rangeCount) {       
        if(this.current_annotation == ANNOTATION.TEXT_HIGHLIGHT 
          || this.current_annotation == ANNOTATION.TEXT_STROKEOUT 
          || this.current_annotation == ANNOTATION.TEXT_UNDERLINE){
          TextHelper.addHightLight(selection,this);     
          clearSelection();
        }          
      }
    }
  // start action annotation
    add_text(){
      this.set_annotation(ANNOTATION.ADD_TEXT);
    }
    highlight(){
      this.set_annotation(ANNOTATION.TEXT_HIGHLIGHT);
    }
    strikeout(){
      this.set_annotation(ANNOTATION.TEXT_STROKEOUT);
    }
    underline(){
      this.set_annotation(ANNOTATION.TEXT_UNDERLINE);
    }
    draw_line(){
      this.set_annotation(ANNOTATION.DRAW_LINE);; 
    }
    draw_arrow(){
      this.set_annotation(ANNOTATION.DRAW_ARROW);
    }
    draw_ellipse(){
      this.set_annotation(ANNOTATION.DRAW_ELLIPSE);
    }
    draw_rectangle(){
      this.set_annotation(ANNOTATION.DRAW_RECTANGLE);
    }
    mark_x(data){
     
      this.set_annotation(ANNOTATION.ADD_XMARK);
    }
    mark_v(data){
      this.set_annotation(ANNOTATION.ADD_CHECKMARK);
    }
    mark_o(data){
      this.set_annotation(ANNOTATION.ADD_DOT);
    }
    form_textbox(){
      this.set_annotation(ANNOTATION.ADD_TEXTBOX);
    }
    form_textarea(){
      this.set_annotation(ANNOTATION.ADD_TEXT_AREA);
    }
    form_radio(){
      this.set_annotation(ANNOTATION.ADD_RADIO); 
    }
    form_checkbox(){
      this.set_annotation(ANNOTATION.ADD_CHECKBOX);
    }
    form_dropdown(){
      this.set_annotation(ANNOTATION.ADD_DROPDOWN); 
    }
    add_image(){
      this.set_annotation(ANNOTATION.ADD_IMAGE);
      showModal(window.location.origin + "/pdf/personal_image");

    }
    pen(){
      this.set_annotation(ANNOTATION.PENCIL);
    }
    free_draw(){
      this.set_annotation(ANNOTATION.FREE_DRAW); 
    }
    erase(){
      this.set_annotation(ANNOTATION.ERASE);
    }
    link(){
      this.set_annotation(ANNOTATION.ADD_LINK);
    }
    screenshot(data){
      this.set_annotation(ANNOTATION.SCREENSHOT);
      this.takeScreenShot = new TakeScreenShot(this)
      this.takeScreenShot.start();
      
    }
    
    // common events
    thick_item(data){
      var element = data.element;
      var thick = element.value;        
      configHelper.set_selected_thick(element);
      if(element.closest('.toolbarItem')){
        configHelper.select_thick(data, this.current_annotation);      
        configHelper.saveConfig();       
      }
      else{
        var thick = parseInt(element.getAttribute('data-item'));

        var stroke = ultil_mdethods.get_stroke_by_scale(this.activeObject.origin_strokeDashArray,thick,this.currentScaleValue);   
        element_helper.set_thick(this.activeObject,stroke.strokeWidth);
        element_helper.set_dash(this.activeObject,stroke.strokeDashArray);

        this.activeObject.origin_strokeWidth = thick;
        PDFSaveAnnotation.saveElement(this,this.activeObject)
        this.activeCanvas.renderAll();
      }  

    }
    update_thumnail(data){
      console.log('update_thumnail');
    }
    dash_item(data){
      var element = data.element;
      var dash =   element.getAttribute('dash');
      if(dash){
          dash = JSON.parse(dash);
      }   
      configHelper.set_selected_dash(element);
      if(element.closest('.toolbarItem')){
        configHelper.select_dash(dash, this.current_annotation);
        configHelper.saveConfig();
      }
      else{            
        var stroke = ultil_mdethods.get_stroke_by_scale(dash,this.activeObject.origin_strokeWidth,this.currentScaleValue);  
        element_helper.set_thick(this.activeObject,stroke.strokeWidth);
        element_helper.set_dash(this.activeObject,stroke.strokeDashArray);
        this.activeObject.origin_strokeDashArray = dash;
        PDFSaveAnnotation.saveElement(this,this.activeObject)
        this.activeCanvas.renderAll();
      }
    }
    arrow_item(data){
      var element = data.element;
      var arrow = parseInt(element.getAttribute('arrow'));
      
      configHelper.set_selected_arrow(element);
      if(element.closest('.toolbarItem')){
        Config.shape.arrow.position_arrow = arrow;
        configHelper.saveConfig();
      }
      else{             
        element_helper.set_arrow(this.activeObject,arrow);
        PDFSaveAnnotation.saveElement(this,this.activeObject);
        this.activeCanvas.renderAll();
      }
    }
    color_item(data){
      var element = data.element;
      var  color =   element.style.backgroundColor;  
      configHelper.select_color(element, color); 
      var object = this.activeObject;
      if(object && object.annotation == ANNOTATION.ADD_TEXT && object.name == "date"){
        this.set_corlor_to_element(color);
        var stamp = stamp_helper.get_stamp_by_date(object);
        stamp.color = color;
        stamp_helper.saveStamp(stamp,this);
      }
      else
      {
        if(element.closest('.toolbarItem')){
          configHelper.set_config_color(element,color,this.current_annotation);
          configHelper.saveConfig();        
        }
        else{
          this.set_corlor_to_element(color);
        }
      }
      
    }
    color_pick(data){
      var element = data.element;
      var color = element.value;        
      configHelper.color_pick(element, color);      

      var object = this.activeObject;
      if(object &&  object.annotation == ANNOTATION.ADD_TEXT && object.name == "date"){
        this.set_corlor_to_element(color);
        // var stamp = stamp_helper.get_stamp_by_date(object);
        // stamp.color = color;
        // stamp_helper.saveStamp(stamp,this);
      } 
      else
      {
        if(element.closest('.toolbarItem')){
          configHelper.set_config_color(element,color,this.current_annotation);
          configHelper.saveConfig();         
        }
        else{
          this.set_corlor_to_element(color);
        }
      }     
      
    }
    set_corlor_to_element(color){
      // change an element property     
      var targetObject = this.activeObject;
      if(targetObject == null && this.activeElement){
        targetObject = this.activeElement;
      }
      if(!targetObject) return;
      element_helper.set_color(targetObject,color);    
      PDFSaveAnnotation.saveElement(this,targetObject)  ;
      this.activeCanvas.renderAll();
    }
    opacity_item(data){      
      var element = data.element;
      var opacity = element.value;        
      if(element.closest('.toolbarItem')){
        configHelper.select_opacity(data, this.current_annotation);      
        configHelper.saveConfig();       
      }
      else{
        element_helper.set_opacity(this.activeObject,opacity);        
        this.activeCanvas.renderAll();
        PDFSaveAnnotation.saveElement(this,this.activeObject)
      }      
    }
    width_item(data){
      configHelper.select_width(data, this.current_annotation);      
      configHelper.saveConfig();
    }

    height_item(data){
      configHelper.select_height(data, this.current_annotation);      
      configHelper.saveConfig();
    }
    font_family(data){
      var element = data.element;
      var value = element.value;  
      if(element.closest('.toolbarItem')){
        configHelper.select_font_family(this.current_annotation, value);      
        configHelper.saveConfig();      
      }
      else{
        element_helper.set_font_family(this.activeObject,value);
        this.activeCanvas.renderAll();
        PDFSaveAnnotation.saveElement(this,this.activeObject);
      }
    }
    font_size(data){
      var element = data.element;
      var value =  parseFloat(element.value);  
      if(element.closest('.toolbarItem')){
        configHelper.select_font_size(this.current_annotation, value);      
        configHelper.saveConfig();      
      }
      else{
        element_helper.set_font_size(this.activeObject,value*this.currentScaleValue);
        this.activeObject.originFontSize = value;
        this.activeCanvas.renderAll();
        PDFSaveAnnotation.saveElement(this,this.activeObject);
      }
    }
    bold_text(data){
      var element = data.element;
      var value = false;        
      if(element.closest('.toolbarItem')){
        value = configHelper.select_bold_text(this.current_annotation, element);    
        configHelper.saveConfig();      
      }
      else{
        value = (this.activeObject.fontWeight=="normal");
        element_helper.set_bold_text(this.activeObject,value);
        this.activeCanvas.renderAll();
        PDFSaveAnnotation.saveElement(this,this.activeObject);
      }
      configHelper.setSelected(element,value == 1);
    }
    italic_text(data){
      var element = data.element;
      var value = false;   
      if(element.closest('.toolbarItem')){
        value = configHelper.select_italic_text(this.current_annotation);      
        configHelper.saveConfig();      
      }
      else{
        value = (this.activeObject.fontStyle=="normal");
        element_helper.set_italic_text(this.activeObject,value);
        this.activeCanvas.renderAll();
        PDFSaveAnnotation.saveElement(this,this.activeObject);
      }
      configHelper.setSelected(element,value == 1);
    }
   
    // end common events
    keyPress(e){
      if(e.key === "Escape") {
        // write your logic here.        
        if(this.current_annotation == ANNOTATION.SCREENSHOT){
          this.takeScreenShot.end();
          this.takeScreenShot = null;
        }
        this.set_annotation(ANNOTATION.NONE);
    }
    }
    customize_tool(){
      document.getElementById('pop-up-edit-tool').style.display= 'block';
    }
    close_tool(){
      document.getElementById('pop-up-edit-tool').style.display= 'none';
    }
    undo(){      
      const state = this.undoRedoHandler.getPrevState();    
      if (state) {
        this.applyPreview(state);
      }
      this.updateStatusUndoRedo();
    }
    applyPreview(state){
      switch(state.typeHistory){
        case TYPE_HISTORY.ADD:
          state.typeHistory = TYPE_HISTORY.DELETE;
          this.undo_delete(state);          
          break;
        case TYPE_HISTORY.DELETE:
          state.typeHistory = TYPE_HISTORY.ADD;
          this.redo_add(state);          
          break;
        
      }     
    }
    redo(){      
      const state = this.undoRedoHandler.getNextState();    
      if (state) {
        this.applyPreview(state);
      }
      this.updateStatusUndoRedo();
    }   
    
    async download(data){    
      showFormLoading();
      if(this.file.encripted){
        download_helper.downloadPDF_by_base64(this.file.bytes,el("name_file").value)
      }
      else{
        var helper = new PDFDownloadHelper(this);
        toolBarHelper.hideToolProperties();
        await helper.download(this.file.bytes,el("name_file").value,this.app_type); 
      }     
      hideFormLoading();      
    }
    async saveComplete(data){
      showFormLoading();
      setFabricItemDeselectAll(this.fabricpageAnnotations);
      var helper = new PDFDownloadHelper(this);
      await helper.saveComplete(); 
      hideFormLoading(); 
    }
    async saveArrageFile(){
      var saveHelper = new PDFSave(this);
      showFormLoading();
      toolBarHelper.hideToolProperties();  
      await saveHelper.saveArrageFile();
    }
    async saveMergeFile(){
      var saveHelper = new PDFSave(this);
      showFormLoading();
      toolBarHelper.hideToolProperties();  
      await saveHelper.saveMergeFile();
    }
    async downloadSplitFile(){
      var helper = new PDFDownloadHelper(this)
      showFormLoading();
      toolBarHelper.hideToolProperties();  
      await helper.downloadSplitFile(this.file.bytes);
    }
    async save(){
      showFormLoading();
      this.form_status = FORM_STATUS.VIEWING;
      this.viewComponents.viewContainer.removeAttribute('edit');
      var saveHelper = new PDFSave(this);
      toolBarHelper.hideToolProperties();  
      await saveHelper.save(); 
    }
    
   
    canvas_after_render(data){  
   }

   saveElementAnnotation(data){
    if(this.current_annotation != ANNOTATION.NONE){
      const {canvas,ev} = data;     
      var targets =  ev.target;
      if(targets){        
        if(targets._objects && targets._objects.length > 0){
      
          targets._objects.forEach(element => {
            PDFSaveAnnotation.saveElement(this,element);
          });
        }
        else{
          PDFSaveAnnotation.saveElement(this,targets);
        }
      }     
    }
   }

    mouseDownEvent(data){
      const {canvas,ev} = data;
      this.activeCanvas = canvas;
      this.activeObject = canvas.getActiveObject();
       
      if(this.activeObject){         
        //toolBarHelper.showtoolObject(this.activeObject, this);           
      }
      else{              
        toolBarHelper.hideToolProperties();
        fabric_detail_event.mouseDown(ev,canvas,this);    
      }    
      
  }
 
  fabric_mouse_over(data){
    const {canvas,ev} = data;
    // toolBarHelper.hideToolProperties();
    fabric_detail_event.fabric_mouse_over(ev,canvas,this);   
    
}


  formLayerClick(ev){
    // this.set_annotation(this.current_annotation);
  }
  object_added(data){
    this.form_status = FORM_STATUS.EDITTING;
    this.viewComponents.viewContainer.setAttribute('edit', true);
    var object = data.ev.target;
    if(!object.user_id || object.user_id == undefined){
      object.user_id = this.user_id;
    }
    
    //this.updateStatusUndoRedo();this.updateStatusUndoRedo();
    // const {canvas,ev} = data;
    // fabricUltil.objectModified(ev,this.current_annotation);    
  }
  object_removed(data){
    const {canvas,ev} = data;
    
    this.form_status = FORM_STATUS.EDITTING;
    this.viewComponents.viewContainer.setAttribute('edit', true);

    var current_object = ev.target;
    if(current_object.isReplace == true){
      return;
    }
    PDFSaveAnnotation.delete_element(this, current_object); 
    this.objectDrawing = null;
    // if(current_object.annotation == ANNOTATION.ADD_STAMP && current_object.hasDate){
    //   var date = stamp_helper.get_date_by_stamp(current_object);
    //   if(date){
    //     PDFSaveAnnotation.delete_element(this, date);   
    //   }
           
    // }
    if(current_object.annotation == ANNOTATION.ADD_TEXT && current_object.name == "date"){
      var stamp = stamp_helper.get_stamp_by_date(current_object);
      if(stamp){
        stamp.hasDate = false;  
        stamp_helper.saveStamp(stamp,this);     
      }      
    }
  }
  itemannotation_click(data){
    var elem = data.element;
    var pageNum = parseInt(elem.getAttribute('page-num'));
    this.currentPageNumber = pageNum;
    if(elem.getAttribute('type') == 'canvas'){
      var canvas = canvas_helper.getFabricItem(this.fabricpageAnnotations,pageNum-1);
      if(canvas){
        canvas.getObjects().forEach((obj) => {
          if(obj.id === elem.id){
            setFabricItemDeselectAll(this.fabricpageAnnotations);
            canvas.setActiveObject(obj);
            canvas.renderAll();
            return;
          }            
        });
      }
      
    }
    else{
      var currentPageDiv = document.getElementById("page-"+ pageNum);
      var item = currentPageDiv.querySelector(`[id-object='${elem.id}']`);
      if(item){
        if(item.className.includes('ff-editable-inner')){
          item.querySelector('.f-input').focus();
        }
        else{
          item.focus();
        }
      }
      
      
    }
  }
  object_modified(data){
    const {canvas,ev} = data;    
    fabric_detail_event.objectModified(this,data.canvas,data.ev);

    var object = ev.target;
    if(object && object.type == "circle" && (object.annotation == ANNOTATION.DRAW_ARROW || object.annotation == ANNOTATION.DRAW_LINE)){ 
      var line = object.line;
      PDFSaveAnnotation.saveElement(this,line);   
    }
    if(object && object.type == "image" && object.annotation == ANNOTATION.ADD_STAMP){ // stamp
      if(object.hasDate == true || object.hasDate == "true"){
        var date = stamp_helper.get_date_by_stamp(object);
        if(date){         
          PDFSaveAnnotation.saveElement(this,date);       
        }      
      }    
    }
  }
  mousedblclick(data){
    if(this.activeObject){     
      toolBarHelper.showtoolObject(this.activeObject, this);  
    }   
        
  }
  path_created(data){
    fabric_detail_event.pathCreated(data,this.current_annotation,this)
  }
  object_moving(data){
    fabric_detail_event.objectMoving(data.canvas,data.ev,this);
  }
  before_render(data){
    data.canvas.isDrawingMode = false;
  }
  mouse_move(data){
    fabric_detail_event.mouseMove(this,data.canvas,data.ev);
  }
  mouse_down_before(data){
    fabric_detail_event.mouse_down_before(this,data.canvas);
  }
  mouse_up(data){
    if(this.activeObject){         
      // toolBarHelper.showtoolObject(this.activeObject, this);           
    }
    else{              
      fabric_detail_event.mouseUp(this,data.canvas,data.ev);
    }   
    
  }
  object_selected(data){
    fabric_detail_event.objectSelected(data.canvas,data.ev,this);
  }
  selection_cleared(data){
    fabric_detail_event.selectionCleared(data.canvas,data.ev,this);
  }
  //
  // start properties
  increaseSizeText(){
    if(this.activeObject){
      this.activeObject.set("fontSize", this.activeObject.fontSize + 1);    
      this.activeCanvas.renderAll();    
    }
  }
  decreaseSizeText(){
    if(this.activeObject){
      this.activeObject.set("fontSize", this.activeObject.fontSize - 1);    
      this.activeCanvas.renderAll();    
    }
  }
  boldtext(){
    if(this.activeObject){
      if(this.activeObject.fontWeight=="bold")
        {
          this.activeObject.set("fontWeight", 'normal');
        }
      else
      {
        this.activeObject.set("fontWeight", 'bold');
      }    
      this.activeCanvas.renderAll();
    }
  }
  italictext(){
    if(this.activeObject){
      if(this.activeObject.fontStyle!="italic"){
        this.activeObject.set("fontStyle", 'italic');
      }
      else
      {
        this.activeObject.set("fontStyle", 'normal');
      }
      
      this.activeCanvas.renderAll();
    }
  }
  fontTextChange(data){
    if(this.activeObject){
      this.activeObject.set("fontFamily", data.value);
      this.activeCanvas.renderAll();
    }
  }
  textColor(data){
    if(this.activeObject){
      this.activeObject.set("fill", data.value);
      PDFSaveAnnotation.saveElement(this,this.activeObject);
      this.activeCanvas.renderAll();
    }
  }
  savelink(data){
      this.activeObject.link_type = data.link;
      Config.link.radio = data.link.link_type;
      Config.link.url = data.link.url;
      Config.link.mail = data.link.email;
      Config.link.phone = data.link.phone;
      Config.link.page = data.link.page;
      toolBarHelper.hideToolProperties();  
      configHelper.saveConfig(); 
      PDFSaveAnnotation.saveElement(this,this.activeObject);
  }
  commonDeleteObject(data){
    toolBarHelper.hideToolProperties();   
    if(this.activeObject){    
      factory.delete(this.activeCanvas,this.activeObject);    
      // PDFSaveAnnotation.delete_element(this,this.activeObject)
    }
    else{
      this.delete_element(); 
    }
  }
  commonClone(data){
    if(this.activeObject){    
      var target = this.activeObject;
      var canvas = target.canvas;
      var viewer = this;
     switch (target.annotation) {
      case ANNOTATION.DRAW_LINE:
        factory.create.clone_line(canvas,target,viewer)
        break;
      case ANNOTATION.DRAW_ARROW:
          factory.create.clone_arrow(canvas,target,viewer)
          break;
      default:
        factory.create.clone_object(target,viewer)
        break;
     }
    }
  
  }
  keyDelete(data){    
    var element = data.element;
    if(element.tagName == "INPUT"){
      return;
    }
    toolBarHelper.hideToolProperties();   
    if(this.activeCanvas && this.activeCanvas.getActiveObjects().length > 0){
      if(this.activeCanvas.getActiveObjects().length == 1){
        this.activeObject = this.activeCanvas.getActiveObject();
        if(this.activeObject.annotation == ANNOTATION.ADD_TEXT && !this.activeObject.selected) return;      
        factory.delete(this.activeCanvas,this.activeObject);       
      }
      else{
        delete_objects_selected(this.activeCanvas);
      }
    }
    
    else{
      this.delete_element(); 
    }
  }

  
  
  delete_object_in_canvas_by_id(canvas,element){
    factory.delete(canvas,element);       
    this.undoRedoHandler.removeItemStack(element);
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack); 
    this.updateStatusUndoRedo();
    toolBarHelper.hideToolProperties();   
    setFabricItemDeselectAll(this.fabricpageAnnotations);    
  }

  
  selectStamp(data){
    this.image_selected = data.element;
    this.set_annotation(ANNOTATION.ADD_STAMP);
    if(this.app_type == 1) // pdf edit
    {
      var offcanvas = el("offcanvasRight");
      if(offcanvas){
        var buttonhide = offcanvas.querySelector(`button[data-bs-dismiss=offcanvas]`);
        if(buttonhide){ buttonhide.click()}
      }      
    }    
  }

  async show_modal_share(data){
    setFabricItemDeselectAll(this.fabricpageAnnotations);    
    toolBarHelper.hideToolProperties();
    if ($('.fmPdfUser').length == 1) {
      var pdf_id = $('.fmPdfUser').attr('id')
    }
    else {
      var pdf_id = fileSelectd.id;
    }
    var id = data.source
    $.ajax({
      type: 'GET',
      url: "add_share_pdf",
      dataType: 'script',
      data: {
        pdf_id:pdf_id
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }

  async show_modal_reject(id){
    setFabricItemDeselectAll(this.fabricpageAnnotations);    
    toolBarHelper.hideToolProperties();
    $.ajax({
      type: 'GET',
      url:!check_is_stamp_on_mobile() ?  window.origin +  "/pdf/reject/" + id : window.origin +  "/mobile/pdf/reject/" + id,
      dataType: 'script',
      data: {
        id: id
      },
      success: function(data, textStatus, jqXHR){
        
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }
  async show_modal_final(id){
    setFabricItemDeselectAll(this.fabricpageAnnotations);    
    toolBarHelper.hideToolProperties();
    window.$('#finish-modal').modal('show');
  }
  
  image_item(data){
    this.image_selected = data.element;
  }
  toolButton(data){
    var button = data.element;
    var toolItem = button.closest('.tool-item');
    var selected = toolItem.getAttribute('selected');
    var tool_type = toolItem.getAttribute('type');
    var containnerleftButton =  document.querySelector('.top-col-left');
    var button = containnerleftButton.querySelector(`.edit-button[type="${tool_type}"]`);
    var button2 = containnerleftButton.querySelector(`.secondary-button-list .dropdown-item[type="${tool_type}"]`);
    if(selected=='true'){
      toolItem.setAttribute('selected',false);  
      button.setAttribute('display',false); 
      button2.setAttribute('display',false); 
      save_status_button(tool_type,false);
    }
    else{
      toolItem.setAttribute('selected',true);         
      button.setAttribute('display',true); 
      button2.setAttribute('display',true); 
      save_status_button(tool_type,true);
    }
    toolBarHelper.setHiddenButton();
  }
  brushChangeStrokeWidth(data){
    if(this.current_annotation == ANNOTATION.PENCIL){
      Config.pencil.strokeWidth = data.strokeWidth;
    }
    if(this.current_annotation == ANNOTATION.FREE_DRAW){
      Config.freedraw.strokeWidth = data.strokeWidth;
    } 

    if(this.current_annotation == ANNOTATION.DRAW_LINE){
      Config.shape.line.strokeWidth = data.strokeWidth
    }
    if(this.current_annotation == ANNOTATION.DRAW_ELLIPSE){
      Config.shape.ellipse.strokeWidth = data.strokeWidth;
    } 

    if(this.current_annotation == ANNOTATION.DRAW_RECTANGLE){
      Config.shape.rectangle.strokeWidth = data.strokeWidth;
    } 

    configHelper.saveConfig();

  }
  brushChangeStrokeColor(data){
    if(this.current_annotation == ANNOTATION.PENCIL){
      Config.pencil.color = data.color;
    }
    if(this.current_annotation == ANNOTATION.FREE_DRAW){
      Config.freedraw.color = data.color;
    } 
    configHelper.saveConfig();
  }

  

  updateLineWidth(data){
    var ob =  this.activeObject;
      if(ob){
        var widthElem = parseInt(data.width);
        var scaleDash = widthElem/ob.strokeWidth;
        var strokeDashArray = ultil_mdethods.convertArray(ob.strokeDashArray,scaleDash);
        ob.set("strokeWidth", widthElem);
        ob.set("strokeDashArray", strokeDashArray);
        if(ob.name == 'arrow'){    
          ob.line1.set("strokeWidth", widthElem);          
          ob.line2.set("strokeWidth", widthElem);
          ob.line1.set("strokeDashArray", strokeDashArray);
          ob.line2.set("strokeDashArray", strokeDashArray);
          if(ob.number_of_head == 2){
            ob.line3.set("strokeWidth", widthElem);
            ob.line4.set("strokeWidth", widthElem);
            ob.line3.set("strokeDashArray", strokeDashArray);
            ob.line4.set("strokeDashArray", strokeDashArray);
          }  
          ob.circle1.set('radius', widthElem);
          ob.circle2.set('radius', widthElem);
          fabricUltil.setMovingArrow(ob,this.activeCanvas,this);
        }     
        this.activeCanvas.renderAll();      
      } 

  }
  updateLineOpacity(data){
    var ob =  this.activeObject;
    if(ob){    
      var opa = parseFloat(data.opacity);
      ob.set("opacity", parseFloat(opa));
      if(ob.name == 'arrow'){
        ob.line1.set("opacity", opa);
        ob.line2.set("opacity", opa);
        if(ob.number_of_head == 2){
          ob.line3.set("opacity", opa);
          ob.line4.set("opacity", opa);
        }  
        ob.circle1.set('opacity', opa);
        ob.circle2.set('opacity', opa);
      }
      this.activeCanvas.renderAll();
    } 
  }

  updateLineColor(data){
    var ob =  this.activeObject;
      if(ob){      
        var color = data.color;
        ob.set("stroke", color);
        
        if(ob.name == 'arrow'){
          ob.line1.set("stroke", color);
          ob.line2.set("stroke", color);
          ob.line1.set("fill", color);
          ob.line2.set("fill", color);
          PDFSaveAnnotation.saveElement(this,ob.line1);
          PDFSaveAnnotation.saveElement(this,ob.line2);
          if(ob.number_of_head == 2){
            ob.line3.set("stroke", color);
            ob.line4.set("stroke", color);
            ob.line3.set("fill", color);
            ob.line4.set("fill", color);
            PDFSaveAnnotation.saveElement(this,ob.line3);
            PDFSaveAnnotation.saveElement(this,ob.line4);
          }  
          ob.circle1.set('fill', color);
          ob.circle2.set('fill', color);
          ob.circle1.set('stroke', color);
          ob.circle2.set('stroke', color);
          ob.set("fill", color);     
        }        
        PDFSaveAnnotation.saveElement(this,ob);
        this.activeCanvas.renderAll();
      } 
  }
  close_dialog(data){
    toolBarHelper.hideToolProperties();  
  }
  updateBackgroundColor(data){
    var ob =  this.activeObject;
    if(ob){
      ob.set("fill", data.color);
      this.activeCanvas.renderAll();
      PDFSaveAnnotation.saveElement(this,ob);
    }  
  }

  changeformcontrolwidth(data){
    if(this.current_annotation == ANNOTATION.ADD_TEXTBOX){      
      Config.form.textfield.width = data.width;    
    }    
    if(this.current_annotation == ANNOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.width = data.width;     
    }    
    if(this.current_annotation == ANNOTATION.ADD_RADIO){      
      Config.form.radio.width = data.width;     
    }   
    if(this.current_annotation == ANNOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.width = data.width;      
    }   
    if(this.current_annotation == ANNOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.width = data.width;   
    }   
  
    if(this.activeElement){
      this.activeElement.parentElement.style.width = data.width*this.currentScaleValue + 'px';
    }
    configHelper.saveConfig();
  }
  changeformcontrolborderwidth(data){
    if(this.current_annotation == ANNOTATION.ADD_TEXTBOX){
      Config.form.textfield.borderWidth = data.width;
    }    
    if(this.current_annotation == ANNOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.borderWidth = data.width; 
    }    
    if(this.current_annotation == ANNOTATION.ADD_RADIO){      
      Config.form.radio.borderWidth = data.width;    
    }   
    if(this.current_annotation == ANNOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.borderWidth = data.width;
    }   
    if(this.current_annotation == ANNOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.borderWidth = data.width; 
    }   
  
    if(this.activeElement){
      this.activeElement.style.borderWidth = data.width*this.currentScaleValue + 'px';
    }
    configHelper.saveConfig();
  }

  changeformcontrolHeight(data){
    if(this.current_annotation == ANNOTATION.ADD_TEXTBOX){      
      Config.form.textfield.height = data.height;    
    }    
    if(this.current_annotation == ANNOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.height = data.height;     
    }    
    if(this.current_annotation == ANNOTATION.ADD_RADIO){      
      Config.form.radio.height = data.height;     
    }   
    if(this.current_annotation == ANNOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.height = data.height;      
    }   
    if(this.current_annotation == ANNOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.height = data.height;   
    }   
  
    if(this.activeElement){
      this.activeElement.parentElement.style.height = data.height*this.currentScaleValue + 'px';
    }
    configHelper.saveConfig();
  }

  changeformcontrolColor(data){
    if(this.current_annotation == ANNOTATION.ADD_TEXTBOX){
      Config.form.textfield.color = data.color;   
    }    
    if(this.current_annotation == ANNOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.color = data.color;         
    }    
    if(this.current_annotation == ANNOTATION.ADD_RADIO){      
      Config.form.radio.color = data.color;  
    }   
    if(this.current_annotation == ANNOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.color = data.color;    
    }   
    if(this.current_annotation == ANNOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.color = data.color;   
    }
    configHelper.saveConfig();
  }
  changeformcontrolBorderColor(data){
    if(this.current_annotation == ANNOTATION.ADD_TEXTBOX){
      Config.form.textfield.borderColor = data.color;   
    }    
    if(this.current_annotation == ANNOTATION.ADD_TEXT_AREA){      
      Config.form.textarea.borderColor = data.color;         
    }    
    if(this.current_annotation == ANNOTATION.ADD_RADIO){      
      Config.form.radio.borderColor = data.color;  
    }   
    if(this.current_annotation == ANNOTATION.ADD_CHECKBOX){      
      Config.form.checkbox.borderColor = data.color;    
    }   
    if(this.current_annotation == ANNOTATION.ADD_DROPDOWN){      
      Config.form.dropdown.borderColor = data.color;   
    }
    if(this.current_annotation == ANNOTATION.ADD_XMARK){      
      Config.form.xmark.color = data.color;   
      toolBarHelper.updateMenuButton(this.current_annotation);
    }
    if(this.current_annotation == ANNOTATION.ADD_CHECKMARK){      
      Config.form.checkmark.color = data.color;   
      toolBarHelper.updateMenuButton(this.current_annotation);
    }
    if(this.current_annotation == ANNOTATION.ADD_DOT){      
      Config.form.dot.color = data.color;   
      toolBarHelper.updateMenuButton(this.current_annotation);
    }
    configHelper.saveConfig();
  }

  slider_item(data){
    var element = data.element;
    var parent = element.closest('.container_slider');
    var text = parent.querySelector('.text-slider');
    var value =  $(element).val()
    text.value = parseInt(value);
    var object = this.activeObject;
    if(object.annotation == ANNOTATION.ADD_STAMP){      
     change_size_stamp(object,value,this);
    }  
    if(object.name == "date" && object.annotation == ANNOTATION.ADD_TEXT){
      change_size_date(object,value,this);        
    } 
  }
  text_slider(data){
    var element = data.element;
    var value =  $(element).val();
    var parent = element.closest('.container_slider');
    var slider = parent.querySelector('.slider_item');
    slider.value = value;
    var object = this.activeObject;
    if(object.annotation == ANNOTATION.ADD_STAMP){      
      change_size_stamp(object,value,this);
     }  
     if(object.name == "date" && object.annotation == ANNOTATION.ADD_TEXT){
       change_size_date(object,value,this);        
     } 
  }
  check_box_item(data){
    var checked = $(data.element).is(":checked")
    var object = this.activeObject;
    if(object){
      if(object.type == "image" && object.annotation == ANNOTATION.ADD_STAMP){
        change_has_date(object,checked,this);        
      }      
       
    }
  }

  selectElement(data){
    this.activeElement = data.element;
  }

  date_format(data){
    var element = data.element;
    var object = this.activeObject;
    if(object.name == "date" && object.annotation == ANNOTATION.ADD_TEXT){
      change_format_date(object,element,this);        
    } 
  }
  date_out_put(data){
    var element = data.element;
    var object = this.activeObject;
    if(object.name == "date" && object.annotation == ANNOTATION.ADD_TEXT){
      change_date_output(object,element,this);        
    } 
  }
 
  // end properties

  // element
  updateBackgroundColorDiv(data){
    this.activeElement.style.backgroundColor = data.color;
  }
  delete_element(){   
    if(!this.activeElement) return;
    this.deleteElementInHTML(this.activeElement);    
    PDFSaveAnnotation.delete_element(this,this.activeElement);
  }
  deleteElementInHTML(htmlObject){
    deleteElementInHTML(htmlObject);
    this.undoRedoHandler.removeItemStack(htmlObject);
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack); 
    this.updateStatusUndoRedo();
    toolBarHelper.hideToolProperties();  
  }
  
  keyboardDeleteElement(){    
    if(!this.activeElement) return;
    if(validateBeforeDelete(this.activeElement)){
      this.deleteElementInHTML(this.activeElement);    
    }
  }

  showtoolElement(data){
    this.activeElement = data.source;
    toolBarHelper.showtoolElement(data,this );
  }

  updateElementColor(data){
    this.activeElement.style.color = data.color;
  }
  updateBorderColor(data){
    this.activeElement.style.borderColor = data.color;;
  }

  updateBoderWidth(data){
    this.activeElement.style.borderWidth = data.width + "px";
  }
  updateCenterColorElem(data){
    if(this.activeElement){
      var kind = this.activeElement.getAttribute('kind')
      if(kind == 'radio'){
        updateRadioCenterColor(data.color,this.activeElement);  
      }
      if(kind == 'checkbox'){
        updateCheckboxCenterColor(data.color,this.activeElement);
      }        
    }    
  }

  changeFieldName(data){
    this.activeElement.setAttribute("name",data.fieldName);
  }
  updateOptionSelect(data){
    this.activeElement.replaceChildren()
    var options = data.strOption.split("\n")

    if(options){
      for (let index = 0; index < options.length; index++) {
        const element = options[index];
        if(element && element.trim() != ""){
          this.activeElement.append(new Option(element.trim()));
        }
        
      }
    }  
  }
  delete_annotation(data){
    if(data.typeObject == 'html'){
      var object = document.querySelector(`[id-object=${data.objectId}]`);  
      this.deleteElementInHTML(object);
    }
    else{
      var canvas =  canvas_helper.getFabricItem(this.fabricpageAnnotations,data.pageNum-1);
      if(canvas){
        canvas.getObjects().forEach((obj) => {
          if(obj.id == data.objectId)
            this.delete_object_in_canvas_by_id(canvas,obj);
            return;
        });
      }    
    }
  }
  undo_delete(pdfHistory){    
    if(pdfHistory.typeObject == TYPE_OBJECT.OBJECT_HTML){
      deleteElementInHTML(pdfHistory.htmlObject);
    }
    else{
      var canvas = canvas_helper.getFabricItem(this.fabricpageAnnotations,pdfHistory.pageNumber-1);
      if(canvas){   
        factory.delete(canvas,pdfHistory.canvasObject);
      }    
    }
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);   
  }
  redo_add(addHistory){
    this.set_annotation(addHistory.annotation);
    if(addHistory.typeObject == TYPE_OBJECT.OBJECT_HTML){
      var parent;     
      var currentPageDiv = document.getElementById("page-"+ addHistory.pageNumber);
      if(addHistory.htmlObject.className.includes('ff-editable-inner')){   
        parent = currentPageDiv.querySelector(".formLayer");     
        parent.style.pointerEvents = "auto";
        $('.annotation-buttons .annotation-popup a').removeClass("active");    
      }
      else{
        parent = currentPageDiv.querySelector(".annotationLayer"); 
      }
      parent.appendChild(addHistory.htmlObject);
    }
    else{
      var canvas = canvas_helper.getFabricItem(this.fabricpageAnnotations,addHistory.pageNumber-1);
      if(canvas){
        canvas.add(addHistory.canvasObject);
        if(addHistory.canvasObject.name == "arrow"){
          canvas.add(addHistory.canvasObject.circle1);
          canvas.add(addHistory.canvasObject.circle2);
          canvas.add(addHistory.canvasObject.line1);
          canvas.add(addHistory.canvasObject.line2);
          if(addHistory.canvasObject.number_of_head == 2){
            canvas.add(addHistory.canvasObject.line3);
            canvas.add(addHistory.canvasObject.line4);
          }
        }
        canvas.setActiveObject(addHistory.canvasObject);
        canvas.renderAll();  
      }
      
    }
    toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);
  }
  change_status_form(){
    this.set_annotation(ANNOTATION.NONE);
  // set state form
  if(this.currentStackIndex>= 0){  
    var object = this.histoyStack[this.arrayState[this.currentStackIndex]];
    this.set_annotation(object.annotation);
    if(object.typeObject == TYPE_OBJECT.OBJECT_HTML){
      var parent;     
      var currentPageDiv = document.getElementById("page-"+ object.pageNumber);
      var item = currentPageDiv.querySelector(`[id='${object.htmlObject.id}']`);
      if(object.htmlObject.className.includes('ff-editable-inner')){   
        parent = currentPageDiv.querySelector(".formLayer");     
        parent.style.pointerEvents = "auto";
        $('.annotation-buttons .annotation-popup a').removeClass("active");    
        item.querySelector('.f-input').focus();
      }   
      else{
        item.focus();
      }
    }  
    else{
      setFabricItemDeselectAll(this.fabricpageAnnotations);
      if(object.canvasObject && object.canvasObject.canvas){
        object.canvasObject.canvas.setActiveObject(object.canvasObject);
        object.canvasObject.canvas.renderAll();
      }
      
    }
    this.currentPageNumber = parseInt(object.pageNumber);
    
  }
  }
  addHistory(pdfHistory){   
    // this.undoRedoHandler.insert(pdfHistory);
    // toolBarHelper.updateItemStack(this.undoRedoHandler._undoStack);
    // this.updateStatusUndoRedo();
  }
}


function updateRadioCenterColor(color,elem){
  addRule(`input[name="${elem.getAttribute('name')}"]:before`,`background-color: ${color} !important`);  
}
function updateCheckboxCenterColor(color,elem){
  addRule(`input[name="${elem.getAttribute('name')}"]:before`,`background-color: ${color} !important`); 
  addRule(`input[name="${elem.getAttribute('name')}"]:after`,`background-color: ${color} !important`);  
}




function setFabricItemDeselectAll(fabricpageAnnotations){
  for (let index = 0; index < fabricpageAnnotations.length; index++) {
      const element = fabricpageAnnotations[index];
      if(element){
        element.discardActiveObject().renderAll();  
      }
          
  } 
}



function deleteElementInHTML(object){   
  if(object){
    if(object.className.includes('f-input')){
      object.parentElement.remove();
    }
    else{
      object.remove();  

    }
  }
  
}
function validateBeforeDelete(obj){
  var candelete = true;
  var annotation = obj.getAttribute("annotation");
  if(annotation == ANNOTATION.ADD_TEXTBOX || annotation == ANNOTATION.ADD_TEXT_AREA ){
    candelete = false;
  }
  return candelete;
}

function save_status_button(tool_type, status){
  var objectDataSubmit = {tool_type: tool_type,status:status};
  AjaxSubmit("/pdf/save_status_button",'GET',objectDataSubmit);  
}

function delete_objects_selected(canvas) {
  for (let index = 0; index < canvas.getActiveObjects().length; index++) {
    const element = canvas.getActiveObjects()[index];
    factory.delete(canvas,element);  
  }
  canvas.discardActiveObject().renderAll()

}
function clearSelection() {
  if (window.getSelection) {
      window.getSelection().removeAllRanges();
  } else if (document.selection) {
      document.selection.empty();
  }
}

function change_size_stamp(object,value,viewer){  
  var scale = parseFloat(value)/object.height;   
  object.set('scaleX', scale);
  object.set('scaleY', scale);
  object.setCoords();
  object.canvas.renderAll();
  stamp_helper.saveStamp(object,viewer);  
}
function change_size_date(object,value,viewer){  
  object.set("fontSize", value*viewer.currentScaleValue);  
  object.originFontSize = value;
  object.canvas.renderAll();
  var stamp = stamp_helper.get_stamp_by_date(object); 
  stamp.fontSize = value;
  stamp_helper.saveStamp(stamp,viewer);  
}
function change_format_date(object,element,viewer){  
  var value = element.options[element.selectedIndex].value; 
  var text = element.options[element.selectedIndex].text;
  var tool = element.closest('.dropdown-toolbar');
  tool.querySelector('#date_out_put').value = text;
  object.set("text", text);  
  object.canvas.renderAll();

  var stamp = stamp_helper.get_stamp_by_date(object); 
  stamp.formatDate = value;  
  stamp_helper.saveStamp(stamp,viewer);  
}
function change_date_output(object,element,viewer){  
  var value = element.value;  
  object.set("text", value);  
  object.canvas.renderAll();

  var stamp = stamp_helper.get_stamp_by_date(object);  
  stamp_helper.saveStamp(stamp,viewer);  
}
function change_has_date(stamp,has_date,viewer) {
  toolBarHelper.hideToolProperties();
  if(stamp.type == "image" && stamp.annotation == ANNOTATION.ADD_STAMP){
    stamp.hasDate = has_date;

    if(has_date){
      create_date(stamp,viewer);
    }
    else{
      delete_date_by_stamp(stamp,viewer);      
    }
    stamp_helper.saveStamp(stamp,viewer);
  }
}



function create_date(stamp,viewer) {
  factory.create.add_date(stamp,viewer);     
  
} 
function delete_date_by_stamp(stamp,viewer) {
  var date = null;
  var result = false;
  var objects = stamp.canvas.getObjects();
  for (let index = 0; index < objects.length; index++) {
    const element = objects[index];
    if(element.parent_id === stamp.id) {
      stamp.canvas.remove(element);
      stamp.canvas.renderAll();
      PDFSaveAnnotation.delete_element(viewer,element);
   }
  }
  if(date){
    result = true;
   
  }
  return result;
}
