import { TYPE_STAMP } from '../../../packs/common';
import { StampConfig } from '../../../packs/pdf/stamp_config';
import { PDFSaveAnnotation } from '../edit/save_annotation';
export const stamp_helper = {
  init(){
    init();
  },
  load_settings_tool(){
    var settings = document.getElementById('json_stamp_setting').value;
    const jsonSettings = JSON.parse(settings);
    setAllConfig(jsonSettings)
    
  },
    get_config(type_stamp){
        var config = null;
            switch(parseInt(type_stamp)){
              case TYPE_STAMP.PERSONAL:   
                config = StampConfig.personal;
                break;
              case TYPE_STAMP.MANAGER_NODATE:      
                config = StampConfig.manager_no_date; 
                break;      
              case TYPE_STAMP.MANAGER_HAS_DATE:    
                config = StampConfig.manager_has_date; 
                break;
              case TYPE_STAMP.COMPANY:        
                config = StampConfig.company; 
                break;  
              case TYPE_STAMP.SIGNAL:    
                config = StampConfig.signal;
                break;  
              case TYPE_STAMP.AUTOCARD:    
                config = StampConfig.autocard;   
                break;        
            }
            return config;
    },
    saveDate(stamp,viewer) {
      if(stamp.hasDate){
        var dateObject = this.get_date_by_stamp(stamp,stamp.canvas);
        PDFSaveAnnotation.saveElement(viewer,dateObject);
      }
     
    },
    save_config_stamp(stamp,viewer){
      if(stamp){
        var config = this.update_config(stamp);
        this.saveConfig(config);
      }
    },
    saveStamp(stamp,viewer){
      if(stamp){
        var config = this.update_config(stamp);
        PDFSaveAnnotation.saveElement(viewer,stamp);
        this.saveConfig(config);
        this.saveDate(stamp,viewer);
      }
      
    },
    update_config(stamp) {
      if(stamp){
        var config = stamp_helper.get_config(stamp.typeStamp);
        config.size = stamp.height*stamp.scaleY/stamp.scale;
        config.has_date = stamp.hasDate;
        config.date.color = stamp.color;
        config.date.fontSize = stamp.fontSize;
        config.date.format = stamp.formatDate;
        config.date.x_relative = stamp.x_relative;
        config.date.y_relative = stamp.y_relative;
        return config;
      }
      return null;
     },

    saveConfig(config){
      if(!check_is_stamp_on_mobile()){ //web
        var objectDataSubmit = {type_stamp: config.type,setting: config};
        AjaxSubmit("/pdf/save_config_stamp",'GET',objectDataSubmit);
      }
      else{  // mobile 
        var user_id = el('user_id').value
        var objectDataSubmit = {type_stamp: config.type,setting: config,user_id: user_id};
        AjaxSubmit("/mobile/pdf/save_config_stamp",'GET',objectDataSubmit);
      }
    },

    get_stamp_by_date(date) {
      if(!date.canvas) return null;
      var objects = date.canvas.getObjects();
      var stamp = null;
      for (let index = 0; index < objects.length; index++) {
        const element = objects[index];
        if(element.id == date.parent_id){
          stamp = element;
          break;
        }
      }
      return stamp;
    },
    get_date_by_stamp(stamp){
      var date = null;    
      if(stamp && stamp.canvas){
        var objects = stamp.canvas.getObjects();
        for (let index = 0; index < objects.length; index++) {
          const element = objects[index];
          if(element.parent_id == stamp.id){
            date = element;
            break;
          }
        }
      }
      
      return date;
    },
    get_object_in_canvas_by_id(id, canvas) {
      var object = null;
      var objects = canvas.getObjects();
      for (let index = 0; index < objects.length; index++) {
        const element = objects[index];
        if(element.id === id) {
          object = element;
          break;
       }
      }
     return object;
    }
}

 

function setAllConfig(jsonSettings){
  for (let index = 0; index < jsonSettings.length; index++) {
    const element = jsonSettings[index];         
    switch(element.type_stamp)
    {
      case TYPE_STAMP.PERSONAL:
        if(element.size){
          StampConfig.personal.size = parseFloat(element.size);
        }
        if(element.setting) {
          StampConfig.personal = element.setting;
        }           
        break;
      case TYPE_STAMP.MANAGER_HAS_DATE:
          if(element.size){
            StampConfig.manager_has_date.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.manager_has_date = element.setting;
          }    
          break;
      case TYPE_STAMP.MANAGER_NODATE:
          if(element.size){
            StampConfig.manager_no_date.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.manager_no_date = element.setting;
          }    
          break;
      case TYPE_STAMP.COMPANY:
        if(element.size){
          StampConfig.company.size = parseFloat(element.size);
        }
        if(element.setting) {
          StampConfig.company = element.setting;
        }    
        break;
      case TYPE_STAMP.SIGNAL:
        if(element.size){
          StampConfig.signal.size = parseFloat(element.size);
        }
        if(element.setting) {
          StampConfig.signal = element.setting;
        }  
        break;
      case TYPE_STAMP.AUTOCARD:
        if(element.size){
          StampConfig.autocard.size = parseFloat(element.size);
        }
        if(element.setting) {
          StampConfig.autocard = element.setting;
        } 
        break;
    }
  } 
}

function init(){
  loadStamps();
  loadSignal();
  events();
}
function events(){
  $("#id_select_date").on('change', function() {
    
    var date = this.getAttribute('value');
    Redraw_stamp_manager_by_date(date);
  });
  window.$('#modal_stamp_id').on('shown.bs.modal', function (e) {    
    datepicker("#id_select_date_type1");
      $("#id_select_date_type1").datepicker().on('changeDate', Draw_autocad_stamp_type_1);
      datepicker("#id_select_date_type2");	
      $("#id_select_date_type2").datepicker().on('changeDate', Draw_autocad_stamp_type_2);
      
  
      Draw_autocad_stamp_type_1();
      Draw_autocad_stamp_type_2();
      $('#ckDR').click(function () {
        if ($('#ckDR').is(':checked') == true) {
          $('#ckConfirmDesign').prop('checked', false); 
        }
        clear_canvas_cad1_if_uncheck_all();
        Draw_autocad_stamp_type_1();
  
      });
      $('#ckConfirmDesign').click(function () {
        if ($('#ckConfirmDesign').is(':checked') == true) {
          $('#ckDR').prop('checked', false);
        }
        clear_canvas_cad1_if_uncheck_all();
        Draw_autocad_stamp_type_1();
      });
  
      $('#ckCustomer').click(function () {
        if ($('#ckCustomer').is(':checked') == true) {
          $('#ckOther').prop('checked', false); 
        }
        clear_canvas_cad2_if_uncheck_all();
        Draw_autocad_stamp_type_2();
  
      });
      $('#ckOther').click(function () {
        if ($('#ckOther').is(':checked') == true) {
          $('#ckCustomer').prop('checked', false);
        }
        clear_canvas_cad2_if_uncheck_all();
        Draw_autocad_stamp_type_2();
      });
      $("#btn_stamp_autocad_cancel").on('click', function() {
        $("[class^=img_autocad]").css("border-color", "transparent");
        $("[class^=c_stamp_personal]").css("border-color", "transparent"); 
        $("[class^=c_stamp_company]").css("border-color", "transparent"); 
        $("[class^=c_stamp_manager]").css("border-color", "transparent"); 
      });
      $("#id_select_text").on('change', function() {
        Draw_autocad_stamp_type_2();
      });

   
  });
  
}

function clear_canvas_cad1_if_uncheck_all()
  {
    if ($('#ckDR').is(':checked') == false && $('#ckConfirmDesign').is(':checked') == false) {
      var canvas = document.getElementById("img_autocard_1");
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      // $('#btn_preview_autocad_type1').prop('disabled', true);
    }
    // else{
    //   $('#btn_preview_autocad_type1').prop('disabled', false);
    // }
  }
  function clear_canvas_cad2_if_uncheck_all()
  {
    if ($('#ckCustomer').is(':checked') == false && $('#ckOther').is(':checked') == false) {
      var canvas = document.getElementById("img_autocard_2");
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      // $('#btn_preview_autocad_type2').prop('disabled', true);
    }
    // else{
      // $('#btn_preview_autocad_type2').prop('disabled', false);
    // }
  }
  async function Draw_autocad_stamp_type_1(){
    
    var ckDR = $('#ckDR').is(':checked');
    var ckConfirmDesign = $('#ckConfirmDesign').is(':checked');
    if(!ckDR && !ckConfirmDesign) return;
    
    var value_date = $("#id_select_date_type1").datepicker('getDate');
    var text_date = moment(value_date).format('YYYY 年　MM 月　DD 日')    
   
    await DrawCadType1(text_date);

  }
  function Draw_autocad_stamp_type_2(){
    var ckCustomer = $('#ckCustomer').is(':checked');
    var ckOther = $('#ckOther').is(':checked');
    if(!ckCustomer && !ckOther) return;
    
    var value_date = $("#id_select_date_type2").datepicker('getDate');
    var text_date = moment(value_date).format('YYYY 年　MM 月　DD 日')    
    var text_input = document.getElementById('id_select_text').value;
    DrawCadType2(text_date,text_input);
  }
  async function DrawCadType1(text_date){
    var canvas = document.getElementById("img_autocard_1");
    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    // fill image to canvas
    drawimagetocavas_cad1();
    canvas = document.getElementById("img_autocard_1");
    context = await defineContext(canvas);
    context.beginPath();  
    context.fillText(text_date,canvas.width/2 + 37,38);
    context.stroke();
  }
  async function DrawCadType2( text_date,text_input){
    var canvas = document.getElementById("img_autocard_2");
    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    // fill image to canvas
    drawimagetocavas_cad2();
    canvas = document.getElementById("img_autocard_2");
    context = await defineContext(canvas);
    context.beginPath();  
    context.fillText(text_date,canvas.width/2 + 37,66);
    context.font = '9pt My MSGothic';
    context.fillText(text_input, canvas.width/2,47);
    context.stroke();
  }

  function Redraw_stamp_manager_by_date(value_date)
  {
    var borderWidth = 6,color = 'red';
    // var value_date= document.getElementById('id_select_date').value;
    // console.log(value_date)
    var sizeP = 32;
    var scale = 2.231;
    var size = sizeP*1.5*scale;
    var potentials = document.querySelectorAll("[class*=c_stamp_manager]");
    for (var i = 0; i < potentials.length; i++) {
      var id = potentials[i].id;
      var has_date=id.replace('img-stamp-manager','has_date');
      var value_has_date=document.getElementById(has_date).value;
      if(value_has_date == 'false')
      {
        size = potentials[i].width;
        check_is_stamp_on_mobile
        var c = document.createElement("canvas");
        c.id=id;   
        c.className=potentials[i].className;
        c.setAttribute("type_stamp",TYPE_STAMP.MANAGER_HAS_DATE);
        c.width = c.height =size;
        c.addEventListener('click',function(ev) {    
          $("[class^=c_stamp_manager]").not(this).css("border-color", "transparent");
          $("[class^=c_stamp_personal]").css("border-color", "transparent"); 
          $("[class^=c_stamp_company]").css("border-color", "transparent");     

          imageSelected= ev.target;
          var targ = ev.target;
          if (targ.nodeName != "CANVAS") return;
          targ.style.outline = "none;";
          targ.style.border= borderSelected;
        }); 
  
        var context = c.getContext('2d');
        context.lineWidth = borderWidth;
         context.strokeStyle = color;
        //context.font = '25pt HGSeikaishotaiPRO';
        context.font = '75pt HGSeikaishotaiPRO';
        potentials[i].appendChild(c);
    
        var centerX = c.width / 2;
        var centerY = c.width / 2;
        var r = c.width / 2 - context.lineWidth - 5;
        
        context.clearRect(centerX - r - context.lineWidth, 
          centerY - r - context.lineWidth, 
          r * 2 + (context.lineWidth*2), 
          r * 2 + (context.lineWidth*2));
          
        var txtName = document.getElementById(id.replace('img-stamp-manager','name_staff_manager')).value;   
        var txtPosition = document.getElementById(id.replace('img-stamp-manager','position')).value;
        var square = document.getElementById(id.replace('img-stamp-manager','square')).value;  

        var fontSizemanagerName = document.getElementById(id.replace('img-stamp-manager','size_of_name_for_manager_stamp')).value;  
        var fontSizemanagerPosition = document.getElementById(id.replace('img-stamp-manager','size_of_posision_for_manager_stamp')).value;  
        fontSizemanagerPosition = fontSizemanagerPosition*3;
        fontSizemanagerName = fontSizemanagerName*3;
        if(square == 'true'){
          DrawTextDrawManagementStamp(size, txtPosition, txtName, context,fontSizemanagerName,fontSizemanagerPosition);
        }
        else{
          DrawTextDrawTitleStamp(size,txtPosition, txtName,context,fontSizemanagerName,fontSizemanagerPosition);
        }
        value_date=new Date(value_date);
        var month = String(value_date.getMonth()+1) ;
        if(value_date.getMonth()+1<10) month="0"+month;
      
        var day = String(value_date.getDate()) ;
        if(value_date.getDate()<10) day="0"+day;
      
        var date_string = "'" + value_date.getFullYear()%2000 + '.' + month + '.' +  day ;
        DrawDateForManagerStamp(size,context,date_string);  

        // replace
        potentials[i].replaceWith(c);

      }

    }
  }
  
 

  function drawimagetocavas_cad1() {
    var img ;
    if ($('#ckDR').is(':checked') == true) {
     img = document.getElementById("img_cad1_1");
    }
    else if($('#ckConfirmDesign').is(':checked') == true)
    {
      img = document.getElementById("img_cad1_2");
    }
    else{
      return;
    }
    var canvas = document.getElementById("img_autocard_1");
		canvas.getContext("2d").drawImage(img, 0, 0,canvas.width,canvas.height);			
	}
  
  function drawimagetocavas_cad2() {
    var img ;
    if ($('#ckCustomer').is(':checked') == true) {
     img = document.getElementById("img_cad2_1");
    }
    else if($('#ckOther').is(':checked') == true)
    {
      img = document.getElementById("img_cad2_2");
    }
    else{
      return;
    }
    var canvas = document.getElementById("img_autocard_2");
		canvas.getContext("2d").drawImage(img, 0, 0,canvas.width,canvas.height);			      
	}
  
 async function defineContext(canvas) {
  var context = canvas.getContext('2d');
  context.lineWidth = 2;
  context.fillStyle = 'red';
  var url_font = window.location.origin + `/fonts/MS-Gothic.woff2`
  const font = new FontFace('My MSGothic', `url(${url_font})`);
  await font.load();
  document.fonts.add(font);
  context.font = '14pt My MSGothic';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  return context;
 }