$(document).on('turbo:load', function() {
	// upload over maximum file size
	$("body").delegate(".js_input_attachment_stamp", "change", function () {
		const input = this;
		const maxSize = parseInt($(input).data("max-size"), 10);
		const file = input.files[0];
		const errorDiv = $("#error_file_size");
	
		if (file) {
			if (file.size > maxSize*1000*1000) {
				errorDiv.text(I18n.t('dropzone.error_message', {size: maxSize}));
				input.value = "";
				$(input).closest(".nested-fields").remove();
				return;
			}

			errorDiv.text("");
		}
	});

	$("body").delegate(".js_input_attachment", "change", function () {
		const input = this;
		const maxSize = parseInt($(input).data("max-size"), 10);
		const file = input.files[0];
		const errorDiv = $("#error_file_size");
	
		if (file) {
			if (file.size > maxSize*1000*1000) {
				errorDiv.text(I18n.t('dropzone.error_message', {size: maxSize}));
				input.value = "";
				$(input).closest(".nested-fields").remove();
				return;
			}

			errorDiv.text("");
		}
	});
});
