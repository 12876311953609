$(document).on('turbo:load', function() {
	$("body").delegate('#date_holiday', 'click', function() {
		$("#date_holiday").datepicker({		
			language: get_locate(),      
			sideBySide: true,
			"setDate": new Date(),
			"autoclose": true		
		}).focus();
	});

	$("#btnEditHoliday").click(function() {
		var arr_holiday = [];
		$.each($('.tbl_holiday tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_holiday.push($(row).data('item-id'));
			} 
		});
		if (arr_holiday.length == 1) {
			$.ajax ({
				type: 'GET',
				url: "holidays/" + arr_holiday[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_holiday[0]
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});


	$("#btnDeleteHoliday").click(function() {
		var arr_holiday = [];
		$.each($('.tbl_holiday tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_holiday.push($(row).data('item-id'));
			} 
		});

		if (arr_holiday.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_holiday",
				dataType: 'script',
				data: {
					id: arr_holiday
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("#btn_import_holidays").click(function(e) {
     
    window.$("#loadMe").modal({
      backdrop: "static", //remove ability to close modal with click
      keyboard: false, //remove option to close with keyboard
      show: true //Display loader!
    });
    setTimeout(function() {
      $("#close_loading").click();
    }, 100000);

	if ($("#file_holiday").val() == "") {
		$(".file_holiday_errors").html(I18n.t("export_bulletins.validate.file_blank"));
		e.preventDefault();
	} else {
		$(".file_holiday_errors").html("");
	}

    // var elem = document.getElementById('form_holiday') // or $('#myform')[0] with jQuery
    // Rails.fire(elem, 'submit');
   });
});