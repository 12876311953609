import { canvas_helper } from "../canvas/canvas_helper";
import { ultil_mdethods } from "../common/ultil_methods";
import { element_helper } from "../edit/element_helper";

export class RealTime{
    constructor(viewer){
        this.viewer = viewer
    }
    process(data){
        switch (data.action) {
            case 'create':
                create_object(this.viewer,data.annotation);
              break;
            case 'update':
                update_object(this.viewer,data.annotation);
                break;
            case 'delete':
                delete_object(this.viewer,data.annotation);
              break;          
        }
    }
}

function create_object(viewer,annotation) {
    if(parseInt(annotation.user_id) != parseInt(viewer.user_id))
    {        
        var page_index = parseInt(annotation.page)-1
        var object_annotation = get_json_from_string(annotation.annotation)
        canvas_helper.add_annotation_to_list(viewer.file.pages[page_index].annotation,object_annotation); 
        viewer.pdfThumbnail.add_annotation(object_annotation,page_index);
        viewer.pdfContent.add_annotation(viewer.fabricpageAnnotations[page_index],object_annotation,page_index)
    }
}

function update_object(viewer,annotation) {  
    var page_index = parseInt(annotation.page)-1
    var object_annotation = get_json_from_string(annotation.annotation)

    for (var i = viewer.file.pages[page_index].annotation.length - 1; i >= 0; --i) {
        if (viewer.file.pages[page_index].annotation[i].id == object_annotation.id) {
            viewer.file.pages[page_index].annotation[i] = object_annotation;
          break;
        }
    }

    var object = canvas_helper.find_object_by_id(viewer.fabricpageAnnotations[page_index],object_annotation.id)
    var canvas = viewer.fabricpageAnnotations[page_index]
    var parent = 
    {
        width : parseFloat(canvas.wrapperEl.style.width),
        height :  parseFloat(canvas.wrapperEl.style.height)
    };  

    var page = viewer.file.pages[page_index];
    var rotate = (page.rotate + page.viewRotate) % 360;

    const app_components = {
        parent: parent,
        rotate: rotate,
        canvas: canvas,
        view: viewer        
    }
    update_common_properties(app_components,object,object_annotation)
}

function delete_object(viewer,annotation){
    var page_index = parseInt(annotation.page)-1
    var object_annotation = get_json_from_string(annotation.annotation)
    var canvas = viewer.fabricpageAnnotations[page_index]
    for (var i = viewer.file.pages[page_index].annotation.length - 1; i >= 0; --i) {
        if (viewer.file.pages[page_index].annotation[i].id == object_annotation.id) {
            viewer.file.pages[page_index].annotation.splice(i,1);
            break;
        }
    }
    
    viewer.pdfThumbnail.remove_annotation(object_annotation.id,page_index)
    viewer.pdfContent.remove_annotation(canvas,object_annotation);
}
function update_common_properties(app_components,element,object_annotation) {
    Object.keys(object_annotation).forEach(key => {
        if (key !== "id" && key !== "path" && key !== "head1" && key !== "head2" && element[key] !== undefined) {
          element.set(key, object_annotation[key]); // Cập nhật tất cả thuộc tính có thể áp dụng lên đối tượng
        }
    });      
    update_view_properties(app_components,element,object_annotation)
    if(object_annotation.type == "line"){
        console.log('update line')       
        element.setMovedLine();
        
        // update_line(app_components,element,object_annotation)
        element.canvas.calcOffset();
    }
    // element.setHeads()
    
    element.setCoords();
    element.canvas.renderAll();
    return true;
}

function update_view_properties(app_components,element,object_annotation) {
        var view = app_components.view
        var scale = view.currentScaleValue/object_annotation.scale
        var p = {x: object_annotation.left*scale,y: object_annotation.top*scale};   
        element.scale = view.currentScaleValue;
        element.left = p.x;
        element.top = p.y;            
        element.scaleX = object_annotation.scaleX*scale;
        element.scaleY = object_annotation.scaleY*scale;
        element.angle = object_annotation.angle;
        element.rotate = object_annotation.rotate;
        element.position_x = object_annotation.position_x;
        element.position_y = object_annotation.position_y;  
        element.aCoords = object_annotation.aCoords;      
        if(object_annotation.type == "line" && object_annotation.origin_strokeWidth)
        {
            if(object_annotation.origin_strokeDashArray){
                var stroke = ultil_mdethods.get_stroke_by_scale(element.origin_strokeDashArray,element.origin_strokeWidth,element.scale);     
                element.set("strokeWidth", stroke.strokeWidth); 
                element.set("strokeDashArray", stroke.strokeDashArray); 
            }
            else
            {
                element.set("strokeWidth",  element.origin_strokeWidth*element.scale);
            }
        }    
        //  if(object_annotation.origin_strokeWidth)
        // {
        //     if(object_annotation.origin_strokeDashArray){
        //         var stroke = ultil_mdethods.get_stroke_by_scale(object_annotation.origin_strokeDashArray,object_annotation.origin_strokeWidth,element.scale);     
        //         element_helper.set_thick(element,stroke.strokeWidth);
        //         element_helper.set_dash(element,stroke.strokeDashArray);
        //     }
        //     else
        //     {
        //         element_helper.set_thick(element,object_annotation.origin_strokeWidth*element.scale);
        //     }
        // }  
        element.dirty = true;
        element.setCoords();
            
        rotate_object(app_components.parent,element,app_components.rotate);
}
function update_line(appObjects,element,object_annotation) {
    // var scale = appObjects.view.currentScaleValue/object_annotation.scale
    // element.head1.x =   object_annotation.head1.x*scale ;
    // element.head1.y =   object_annotation.head1.y*scale ;
    // element.head2.x =   object_annotation.head2.x*scale ;
    // element.head2.y =   object_annotation.head2.y*scale ;
    // element.scale = appObjects.view.currentScaleValue;
    // if(element.origin_strokeWidth)
    // {
    //     if(element.origin_strokeDashArray){
    //         var stroke = ultil_mdethods.get_stroke_by_scale(element.origin_strokeDashArray,element.origin_strokeWidth,element.scale);     
    //         element_helper.set_thick(element,stroke.strokeWidth);
    //         element_helper.set_dash(element,stroke.strokeDashArray);
    //     }
    //     else
    //     {
    //         element_helper.set_thick(element,element.origin_strokeWidth*element.scale);
    //     }
    // }  
    // element.scale = appObjects.view.currentScaleValue
    // var scale = appObjects.view.currentScaleValue/element.scale;   
    
    // var p1 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas
    //     ,{left: object_annotation.head1.x*scale,top: object_annotation.head1.y*scale}, 
    //      appObjects.rotate-object_annotation.rotate);       
    // var p2 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas,
    //     {left: object_annotation.head2.x*scale,top: object_annotation.head2.y*scale},  appObjects.rotate-object_annotation.rotate);
    // // var aCoords = [p1.x, p1.y, p2.x, p2.y];
    // // element.aCoords = aCoords;
    // var p3 = ultil_mdethods.rotate_object(appObjects.canvas
    //     ,object_annotation, appObjects.rotate-object_annotation.rotate,scale);   
    // element.set('left',p3.x);
    // element.set('top',p3.y);
   
    element.setMovedLine();
    // element.head_moved();
    // var p = {x: object_annotation.x2*scale,y: object_annotation.y2*scale}
    // element.set_end(p);
}
function rotate_object(page,obj,app_rotate){
    var p = ultil_mdethods.calculate_point_after_rotate(page,obj,  app_rotate-obj.rotate);        
    obj.top = p.y ;
    obj.left = p.x ;
    obj.angle += (app_rotate-obj.rotate)
    obj.setCoords()
}
function renderLine(appObjects,element,object_annotation){    
    var scale = appObjects.view.currentScaleValue/element.scale;    
    var p1 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas
        ,{left: element.head1.x*scale,top: element.head1.y*scale}, 
         appObjects.rotate-element.rotate);       
    var p2 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas,{left: element.head2.x*scale,top: element.head2.y*scale},  appObjects.rotate-element.rotate);
    var aCoords = [p1.x, p1.y, p2.x, p2.y];

    var properties = {        
      fill: element.fill,
      stroke: element.stroke,
      strokeWidth: element.strokeWidth*appObjects.view.currentScaleValue/element.scale,     
      annotation: element.annotation,
      id: element.id,
      user_id: element.user_id,
      scale: appObjects.view.currentScaleValue,
      origin_strokeWidth: element.origin_strokeWidth,
      origin_strokeDashArray: element.origin_strokeDashArray,
      name: 'line'  
    }
    properties.strokeDashArray = element.strokeDashArray.map((p) => p*properties.strokeWidth/element.strokeWidth )
    
    properties = annotation_helper.config.line.fix_config(properties);
    map_common_custom_properties(properties,element);
    var line = factory.create.line(appObjects.canvas,aCoords,properties)

    var p3 = ultil_mdethods.rotate_object(appObjects.canvas
        ,element, 
         appObjects.rotate-element.rotate,scale);   
    line.set('left',p3.x);
    line.set('top',p3.y);
    line.setMovedLine();

}