import { selected_page } from '../common/selected_page';

var currentPathFile;

$(document).on('turbo:load', function () {
  /***
   * only move when not exist first item 
   */
  $("body").delegate('#btnMoveLeft', 'click', function () {
    // $('#lstPages .page_selected').each(function (i, selected) {
    //   var thumbnail_frame = this.closest('.thumbnail-frame-page');
    //   if (thumbnail_frame == null) return false;
    //   $(thumbnail_frame).insertBefore(thumbnail_frame.previousElementSibling);
    // });
    let listSelected = document.querySelectorAll('#lstPages .page_selected');

    for (let index = 0; index < listSelected.length ; index++) {
      const element = listSelected[index];
      var thumbnail_frame = element.closest('.thumbnail-frame-page');
      if (thumbnail_frame.previousElementSibling == null || !thumbnail_frame.previousElementSibling.classList.contains('thumbnail-frame-page')) return false;
      $(thumbnail_frame).insertBefore(thumbnail_frame.previousElementSibling);      
    }
  });
  /***
   * only move when not exist  last item
   */
  $("body").delegate('#btnMoveRight', 'click', function () {
    // $('#lstPages .page_selected').each(function (i, selected) {
    //   var thumbnail_frame = this.closest('.thumbnail-frame-page');
    //   if (thumbnail_frame.nextElementSibling == null) return false;
    //   $(thumbnail_frame).insertAfter(thumbnail_frame.nextElementSibling);
    // });
    let listSelected = document.querySelectorAll('#lstPages .page_selected');
    for (let index = listSelected.length -1; index >=0 ; index--) {
      const element = listSelected[index];
      var thumbnail_frame = element.closest('.thumbnail-frame-page');
      if (thumbnail_frame.nextElementSibling == null || !thumbnail_frame.nextElementSibling.classList.contains('thumbnail-frame-page')) return false;
      $(thumbnail_frame).insertAfter(thumbnail_frame.nextElementSibling);      
    }
  });

  $("body").delegate('#btnAddPage, #add_page', 'click', addArrangePages);
  $("body").delegate('#btnDeletePage', 'click', deleteArrangePages);
  $("body").delegate('#btnRotatePage', 'click', function () {
    $('#lstPages .page_selected').each(function (i, selected) {
      var thumbnail_frame = this.closest('.thumbnail-frame');
      var rotate = thumbnail_frame.style.transform.match(/rotate\((.+)\)/);
      var valueRotate = 90;
      if (rotate) {
        valueRotate = parseInt(rotate[1].replace('deg')) + 90;
      }
      var transfrom = `rotate(${valueRotate}deg)`;
      thumbnail_frame.style.transform = transfrom;
      this.setAttribute('rotate', valueRotate);
    });
  });

  $("body").delegate('.btn-move-left', 'click', function () {
    var previousElementSibling = this.parentElement.parentElement.previousElementSibling;
    if (!previousElementSibling || !previousElementSibling.classList.contains('thumbnail-frame-page')) return false;
    $(this.parentElement.parentElement).insertBefore(previousElementSibling);
  });

  $("body").delegate('.btn-move-right', 'click', function () {
    var nextElementSibling = this.parentElement.parentElement.nextElementSibling;
    if (!nextElementSibling || !nextElementSibling.classList.contains('thumbnail-frame-page')) return false;
    $(this.parentElement.parentElement).insertAfter(nextElementSibling);
  });

  $("body").delegate('.btn-delete-page', 'click', function () {
    this.parentElement.parentElement.remove();
  });
  $("body").delegate('.btn-rotate-page', 'click', function () {
    var thumbnail_frame = this.closest('.thumbnail-frame-page').querySelector('.thumbnail-frame');
    var rotate = thumbnail_frame.style.transform.match(/rotate\((.+)\)/);
    var valueRotate = 90;
    if (rotate) {
      valueRotate = parseInt(rotate[1].replace('deg')) + 90;
    }
    var transfrom = `rotate(${valueRotate}deg)`;
    thumbnail_frame.style.transform = transfrom;
    thumbnail_frame.querySelector('canvas').setAttribute('rotate', valueRotate);
  });
})

function makeThumnailImageArrangePageBlank(numPage) {
  var li = document.createElement("li");
  var div_page = document.createElement("div");
  var div_canvas = document.createElement("div");
  var canvas = document.createElement("canvas");
  var toolDiv = document.getElementsByClassName('btn-each-page')[0];
  var clonedToolDiv = toolDiv.cloneNode(true);

  canvas.width = 120;
  canvas.height = 170;
  canvas.style.width = canvas.width + 'px';
  canvas.style.height = canvas.height + 'px';

  div_canvas.id = "pdf_thum" + numPage;
  div_canvas.style.width = canvas.width + 'px';
  div_canvas.style.height = canvas.height + 'px';
  div_canvas.setAttribute("selected", false);
  div_canvas.setAttribute("num", numPage);
  div_canvas.addEventListener('contextmenu', event => event.preventDefault());
  div_canvas.className = "pdf-thump box";
  div_canvas.style.background = 'white';
  div_canvas.setAttribute("data-path", currentPathFile);
  div_canvas.setAttribute("blank", true);
  div_canvas.addEventListener('click', selected_page, false);

  div_page.id = "thumbnail-frame-" + numPage;
  div_page.style.width = canvas.width + 4 + 'px';
  div_page.style.height = canvas.height + 4 + 'px';
  div_page.className = "thumbnail-frame droptarget p-0 d-flex justify-content-center align-items-center";

  li.setAttribute("id", "thumbnail-frame-page" + numPage);
  li.className = "m-2 p-0 position-relative thumbnail-frame-page";
  li.style.width = canvas.width + "px";
  li.style.height = canvas.height + "px";
  li.setAttribute('draggable', true);
  li.addEventListener('dragstart', drag);
  li.addEventListener('dragover', function (event) {
    if (event.target.parentElement.className.includes("droptarget")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.style.border = "2px dotted #369FF9";
    }
    if (event.target.tagName == 'CANVAS' && event.target.parentElement.className.includes("pdf-thump")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.parentElement.style.border = "2px dotted #369FF9";
    }
  });
  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (numPage + 1);

  div_canvas.appendChild(canvas);
  div_page.appendChild(div_canvas);
  div_page.appendChild(span);
  li.appendChild(div_page);
  li.appendChild(clonedToolDiv);
  return li;
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function deleteArrangePages() {
  $('#lstPages .page_selected').each(function (i, selected) {
    var thumbnail_frame = this.closest('.thumbnail-frame-page');
    if (thumbnail_frame) {
      thumbnail_frame.remove();
    }
  })
}

function addArrangePages() {
  var lst = document.getElementById('lstPages').querySelector('.lstPage');
  var num = lst.querySelectorAll('.thumbnail-frame').length;
  var dv = makeThumnailImageArrangePageBlank(num);
  var addPageButton = document.getElementById('add_page');
  lst.insertBefore(dv, addPageButton);
}
