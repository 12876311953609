$(document).on('turbo:load', function () {

	$("body").delegate('#btnMoveUpEquipment', 'click', function () {
		$('#tblEquipmentList select :selected').each(function (i, selected) {
			if (!$(this).prev().length) return false;
			$(this).insertBefore($(this).prev());
		});
		$('#tblEquipmentList select').focus();
	});
	$("body").delegate('#btnMoveDownEquipment', 'click', function () {
		$($('#tblEquipmentList select :selected').get().reverse()).each(function (i, selected) {
			if (!$(this).next().length) return false;
			$(this).insertAfter($(this).next());
		});
		$('#tblEquipmentList select').focus();
	});
	$("body").delegate('#btnMoveTopEquipment', 'click', function () {
		var count_equipment = 0;
		$.each($('#tblEquipmentList select :selected'), function (i, row) {
			count_equipment = count_equipment + 1
		});
		if (count_equipment > 0){
			var first_elem = $("#tblEquipmentList option:first");
			if (first_elem.is(':selected')) return true;
			while (!first_elem.prev().length) {
				$('#tblEquipmentList select :selected').each(function (i, selected) {
					if (!$(this).prev().length) return false;
					$(this).insertBefore($(this).prev());
				});
				$('#tblEquipmentList select').focus();
			}
		}
	});
	$("body").delegate('#btnMoveBottomEquipment', 'click', function () {
		var count_equipment = 0;
		$.each($('#tblEquipmentList select :selected'), function (i, row) {
			count_equipment = count_equipment + 1
		});
		if (count_equipment > 0){
			var last_elem = $("#tblEquipmentList option:last");
			if (last_elem.is(':selected')) return true;
			while (!last_elem.next().length) {
				$($('#tblEquipmentList select :selected').get().reverse()).each(function (i, selected) {
					if (!$(this).next().length) return false;
					$(this).insertAfter($(this).next());
				});
				$('#tblEquipmentList select').focus();
			}
		}
	});
	$("body").delegate('#btnRelocateItems', 'click', function () {
		var lst = [];
		$("#lstEquipment option").each(function () {
			lst.push($(this).val());
		});
		category_id = $("#category_id").val();
		$.ajax({
			type: 'PATCH',
			url: "relocated_equipment",
			dataType: 'script',
			data: {
				lst_equipment: lst,
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	$("#btnRelocateEquipment").click(function () {
		var category_id = $("#list_category option:selected").val();
		$.ajax({
			method: 'GET',
			url: "relocate_items_equipment",
			dataType: 'script',
			data: {
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	$("#btnAddEquipment").click(function () {
		var category_id = $("#list_category option:selected").val();
		$.ajax({
			type: 'GET',
			url: "equipments/new",
			dataType: 'script',
			data: {
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});


	$("body").delegate('#list_category', 'change', function () {
		var category_id = $("#list_category option:selected").val();
		// if (category_id != 0) {
		//     window.location.href = window.location.origin + `/equipments?category_id=${category_id}`;
		// } else {
		//     window.location.href = window.location.origin + `/equipments`;
		// }
		$.ajax({
			url: "check_category",
			method: "GET",
			dataType: "script",
			data: { category_id: category_id },
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	$("#btnDeleteEquipment").click(function () {
		var arr_equipment = [];
		var category_id = $("#list_category option:selected").val();
		$.each($('.tbl-equipment-list tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_equipment.push($(row).data('item-id'));
			}
		});

		if (arr_equipment.length > 0) {
			$.ajax({
				type: 'GET',
				url: "confirm_destroy_selected_items_equipment",
				dataType: 'script',
				data: {
					id: arr_equipment,
					category_id: category_id
				},
				success: function (data, textStatus, jqXHR) {
					// 
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error");
				}
			});
		}

	});

  $("#btn_import_equipments").click(function(e) {
		window.$("#loadMe").modal({
			backdrop: "static", //remove ability to close modal with click
			keyboard: false, //remove option to close with keyboard
			show: true //Display loader!
		});
		setTimeout(function() {
			$("#close_loading").click();
		}, 100000);

		if ($("#file_equipment").val() == "") {
			$(".file_equipment_errors").html(I18n.t("export_bulletins.validate.file_blank"));
			e.preventDefault();
		} else {
			$(".file_equipment_errors").html("");
		}
  });
});
