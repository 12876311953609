$(document).on('turbo:load', function() { 
	if (window.location.pathname.includes(`/pdf/upload_document`)) {
	settingDropZone('pdfDropZone');
	}
	$("#btnDeleteMultilDocument").click(function() {
		var checkboxes = $("table tbody tr td .col_checkbox");
		var lstPdf = [];
		checkboxes.filter(':checked').map(function() {
			lstPdf.push($(this).closest('tr')[0].getAttribute("data-item"));
		})
		if(lstPdf.length == 0) return;
		$.ajax({
			type: 'GET',
			url: "confirm_delete_multi_document",
			dataType: 'script',
			data: {
				list_id_pdf: lstPdf
			},
			success: function(data, textStatus, jqXHR){
			},
			error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error: #{textStatus}")
			}
		});
	});
})

function settingDropZone(dropZoneId){ 
	$(function() {		
		var dopzone= document.getElementById(dropZoneId);
		if(dopzone){
			var max_file_sizeKb = parseInt(document.getElementById("file_maximum_capacity").value)*1000*1000/(1024*1024);;
		  $(`#${dropZoneId}`).dropzone({  
			url: "create_pdf_tem",
            maxFilesize: max_file_sizeKb,
            maxFiles: 100,
            previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,
            acceptedFiles: getListAcceptFileType("pdf"),        
            clickable: true,
			accept: function(file, done) {
			  switch (file.type) {
					case 'application/pdf':
						file.previewElement.querySelector("img").src= '/images/pdf.png';
						break;	
					default:
						var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
						progressElement.querySelector(".progress-text").textContent = "error";
						break;	
					}
			  done();
			},
			init: function() {	
				setMessageErrorFile(this.options);	
			  this.on("error", function(file, message, xhr) { 
					messageErrorFile(file, message);
					defineIcon(file);		
				});
	
			  this.on("addedfile", function(file) {
					document.getElementById('borrow_id').style.display="block";
					dopzone.style.display="block";
					dopzone.style.minHeight="min-content";					          
			   });
				
				this.on("success", function(file, responseText) {   
					fileSelectd=responseText.file;
					checkEncryption(file,fileSelectd);
					file.previewElement.addEventListener("click", function() 
					{
						window.location.href = window.location.origin + `/pdf/edit/${fileSelectd.slug}`;              
					})
				});

				this.on("removedfile", function(file) {
		          });

				this.on("uploadprogress", function(file, progress, bytesSent) {
				  if (file.previewElement) {
					  var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
					  progressElement.style.width = progress + "%";
					  progressElement.querySelector(".progress-text").textContent = parseInt(progress) + "%";
				  }
				});			 
			}
			});	
		}
	});
}
function checkEncryption(file,fileSelectd) {
	const reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = function () {

	var files = new Blob([reader.result], {type: 'application/pdf'});
	
	files.text().then(x=> {		
		var isEncrypted =  x.includes("Encrypt");
		if(isEncrypted){
			file.previewTemplate.querySelector('.file-locked').classList.remove("d-none");
			$.ajax({
				type: 'POST',
				url: "set_encryption",
				dataType: 'json',
				data: {
				  pdf_id: fileSelectd.id,
				  isEncrypted: isEncrypted
				},
				success: function(data, textStatus, jqXHR){
				 
				},
				error:function(jqXHR, textStatus, errorThrown){
				  console.log("AJAX Error: #{textStatus}")
				}
			  });
		}
		
	});

}
}