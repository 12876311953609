import { ANNOTATION } from "../edit/annotation";
import { SavePageItem } from "./save_page_item";
import {degrees} from 'pdf-lib';
export class SavePage{
    constructor(){}
    saveRotation(page,rotate){saveRotation(page,rotate)}
    async saveAnnotations(page,arr_annotations)
    {
       await saveAnnotations(page,arr_annotations);
    }
}

function saveRotation(page,rotate){
    if(page.angle != rotate){
        page.setRotation(degrees(rotate))
    }
}
async function saveAnnotations(page,arr_annotations){
    var savePageItem = new SavePageItem(page);  
    for await(const annotation of order_annotations(arr_annotations)) {        
        await saveItem(annotation,savePageItem);         
    }
}
function order_annotations(arr_annotations) {
    var ordered = []
    var text_annotations = []
    var other_annotations = []
    for (let index = 0; index < arr_annotations.length; index++) {
        const element = arr_annotations[index];
        if(element.annotation == ANNOTATION.TEXT_HIGHLIGHT || element.annotation == ANNOTATION.TEXT_STROKEOUT || element.annotation == ANNOTATION.TEXT_UNDERLINE){
            text_annotations.push(element);
        }
        else{
            other_annotations.push(element);
        }        
    }
    ordered = text_annotations.concat(other_annotations);
    return ordered;
}

async function saveItem(item,savePageItem) {
   
    switch (parseInt(item.annotation)) {
        case ANNOTATION.DRAW_LINE:
            savePageItem.save_line(item)
            break;
        case ANNOTATION.DRAW_ARROW:
            savePageItem.save_line(item)
            break;
        case ANNOTATION.ADD_TEXT:
            await savePageItem.save_text(item)
            break;
        case ANNOTATION.PENCIL:
        case ANNOTATION.FREE_DRAW:
            savePageItem.save_path(item)
            break;
        case ANNOTATION.ADD_LINK:
            savePageItem.save_link(item)            
            break;
        case ANNOTATION.DRAW_RECTANGLE:
            savePageItem.save_rectangle(item)
            break;
        case ANNOTATION.DRAW_ELLIPSE:
            savePageItem.save_ellipse(item)
            break;
        case ANNOTATION.ADD_STAMP: 
        case ANNOTATION.ADD_IMAGE: 
        case ANNOTATION.ADD_CHECKMARK:   
        case ANNOTATION.ADD_DOT: 
        case ANNOTATION.ADD_XMARK: 
            await savePageItem.save_image(item)
            break;
        case ANNOTATION.TEXT_HIGHLIGHT:
        case ANNOTATION.TEXT_STROKEOUT:
        case ANNOTATION.TEXT_UNDERLINE:
            savePageItem.save_highlight(item)
            break;
        default:
            break;
    }   
}


