import 'quill-mention' ;
var arr_list_email, table_personal_mail;
$(document).on('turbo:load', function() {
	// datatablePersonalMail();
	settingPersonalMailQuil();
	// Autocomplete email receiver
		format_input_email("#lst_receiver_mail", "#list_receiver");
		
	// Autocomplete email cc
		format_input_email("#lst_receiver_cc", "#list_receiver_cc");

	// Autocomplete email bcc
		format_input_email("#lst_receiver_bcc", "#list_receiver_bcc")
	
	$("#btnSaveMail").click(function() {
		var id_folder_sent = document.getElementById("fid_sent").value;
		document.getElementById("personal_mail_is_draft").value = false; 
		document.getElementById("fid").value = id_folder_sent;
		forward_mail_with_file_attach();
		validate_address_receive();
	});

	$("#btnSaveDraftMail").click(function() {
		var id_folder_draft = document.getElementById("fid_draft").value;
		document.getElementById("personal_mail_is_draft").value = true;
		document.getElementById("fid").value = id_folder_draft;
		forward_mail_with_file_attach();
		document.getElementById('btnSave_mail').click();
	});

	// Initialize Dropzone
	Dropzone.autoDiscover = false;
	$('.dropzone').each(function () {
		let dropzoneControl = $(this)[0].dropzone;
		if (dropzoneControl) {
			dropzoneControl.destroy();
		}
	});

	$(function() {
		//Dropzone class screen normal schedule
		var check_locale = $("body").attr("data-locale");
		var dropzoneMail = $('#myDropzoneMail');
		if(dropzoneMail.length > 0){
			var max_file_sizeKb = parseInt(document.getElementById("file_maximum_capacity").value)*1000*1000/(1024*1024);;
			$('#myDropzoneMail').dropzone({
				url: "/attach_document_mail",
				autoProcessQueue: false,
				paramName: "file",
				maxFilesize: max_file_sizeKb,
				parallelUploads: 100,
				// addRemoveLinks: true,
				previewTemplate: document.querySelector('#uploadPreviewTemplateMail').innerHTML,
				maxFiles: 100,
				acceptedFiles: getListAcceptFileType("default"),     
				clickable: true,
				accept: function(file, done) {
					defineIcon(file);
					done();
				},
				init: function() {
					setMessageErrorFile(this.options);
					var isSent = false;
					var myDropzone = this;
					document.getElementById("btnSaveDocumentMail").addEventListener("click", function(e) {
						// Make sure that the form isn't actually being sent.
						e.preventDefault();
						e.stopPropagation();
						myDropzone.processQueue();
					});

					this.on("error", function(file, message, xhr) { 
						messageErrorFile(file, message);
						defineIcon(file);
						$(file.previewElement).find(".id_file_mail").removeClass("id_file_mail");
						var count_file_upload = document.getElementsByClassName("id_file_mail").length;
						if (count_file_upload == 1) {
							document.getElementById('is_file_upload_document_mail').value = 0;
						}
					});

					this.on("addedfile", function(file) {
						// if (file.size > this.options.maxFilesize * 1024 * 1024) {
						// 	$("#content_warning").text(file.name + "  is too big (" + parseFloat(file.size/1024/1024).toFixed(2) + "). Max filesize: 15MiB.");
						// 	window.$("#warning-alert-modal").modal('show');
						// 	this.removeFile(file);
						// } 
						// else {
						document.getElementById('borrow_id_mail').style.display="block";
						document.getElementById('is_file_upload_document_mail').value = 1;
						
					});
					
					this.on("uploadprogress", function(file, progress, bytesSent) {
						if (file.previewElement) {
							var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
							progressElement.style.width = progress + "%";
							progressElement.querySelector(".progress-text").textContent = progress + "%";
						}
					});

					this.on("success", function(file, progress, bytesSent) {
						isSent = true;
					});
					
					this.on("removedfile", function (file) {
						var count_file_upload = document.getElementsByClassName("id_file_mail").length;
						if (count_file_upload == 1) {
							document.getElementById('is_file_upload_document_mail').value = 0;
						}
						// console.log("remove");
					});

					this.on("complete", function (file) {
						if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
							// window.location.href = '/personal_mails?fid=' + document.getElementById('fid').value;
							var fid = document.getElementById("fid").value;
							window.close();
							// window.opener.location.href = '/personal_mails?fid=' + document.getElementById('fid').value;
						}
					});
				}
			});
		}
	});

	$("body").delegate('#tbl_mail thead tr input[type="checkbox"]', 'change', function (e) {
			select_all_checkbox_mail("#tbl_mail tbody tr", this.checked);
	});

	$("body").delegate('#tbl_mail tbody tr td', 'click', function () {
		if ( this.classList == "subject-personal-mail" ) {
			select_row_table_mail(this); 
		}
		else if (this.classList == "mark-important") {
			change_mark_important_mail(this);
		}
	});

	
	// $("body").delegate('.btnChangeMarkImportantMail', 'click', function (e) {
	// 	change_mark_important_mail(this.parentElement);
	// });

	$("body").delegate('#tbl_mail tbody tr td input[type="checkbox"]', 'change', function (e) {
			select_checkbox(this.parentElement);
	});

	// $('#tbl_mail tbody tr').on('click', 'input[type="checkbox"]', function () {
	//   console.log("checkbox click");
	//   var id_mail_selected = this.parentElement.parentNode.dataset.itemId
	//   var fid = document.getElementById("fid").value;
	//   var arr_id_mail_checked = [];
	//   var arr_row_checked = [];

	//   this.parentElement.parentNode.classList.toggle("selected");
	//   this.parentElement.parentNode.classList.toggle("checked");

	//   $.each($('.tbl_mail tbody tr'), function (i, row) {
	//     if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
	//       arr_id_mail_checked.push($(row).data('item-id'));
	//       arr_row_checked.push($(row));
	//     } 
	//   });

	//   if (arr_id_mail_checked.length == 0) {
	//     $.ajax ({
	//       type: 'GET',
	//       url: "/personal_mails/" + id_mail_selected,
	//       dataType: 'script',
	//       data: {
	//         fid: fid,
	//         count_row_selected: arr_id_mail_checked.length
	//       },
	//       success: function(data, textStatus, jqXHR){
	//         // 
	//       },
	//       error:function(jqXHR, textStatus, errorThrown){
	//         console.log("AJAX Error");
	//       }
	//     });
	//   }
	//   else {
	//     $("#group_button_edit_mail").html("");
	//     $('#content_mail').html("");
	//     $("#group_button_list_mail").removeClass("d-none");
	//   }
			
	// });
	$("body").delegate('#btnMoveMailToTrash', 'click', function () {
	// $("#btnMoveMailToTrash").click(function() {
		var arr_mail = [];
		$.each($('.tbl_mail tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_mail.push($(row).data('item-id'));
			} 
		});

		if (arr_mail.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "move_multi_mail_to_trash",
				dataType: 'script',
				data: {
					id: arr_mail,
					fid: document.getElementById("fid").value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	// $("#btnMarkMailImportant").click(function() {
	// 	mark_mail_important();
	// });

	// $("#btnMarkMailImportant2").click(function() {
	//   mark_mail_important();
	// });

	$("body").delegate('#btnMarkOpenMail', 'click', function () {
		// $("#btnMarkOpenMail").click(function() {
		var arr_mail = [];
		$.each($('.tbl_mail tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_mail.push($(row).data('item-id'));
			} 
		});

		if (arr_mail.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "mark_multi_read_mail",
				dataType: 'script',
				data: {
					id: arr_mail,
					fid: document.getElementById("fid").value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$(".delete_file_mail").click(function() {
		var checkbox_delete = this.classList[2].replace('file_mail','is_delete_document_mail');
		if (document.getElementById(checkbox_delete).checked) {
			document.getElementById(checkbox_delete).checked = false;
		} 
		else {
			document.getElementById(checkbox_delete).checked = true;
		}
	});

	// $("#btnMarkMailUnimportant").click(function() {
	//   var arr_mail = [];
	//   $.each($('.tbl_mail tbody tr'), function (i, row) {
	//     if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
	//       arr_mail.push($(row).data('item-id'));
	//     } 
	//   });

	//   if (arr_mail.length > 0) {
	//     $.ajax ({
	//       type: 'GET',
	//       url: "mark_multi_mail_unimportant",
	//       dataType: 'script',
	//       data: {
	//         id: arr_mail,
	//         fid: window.location.href.split('fid=')[1]
	//       },
	//       success: function(data, textStatus, jqXHR){
	//         // 
	//       },
	//       error:function(jqXHR, textStatus, errorThrown){
	//         console.log("AJAX Error");
	//       }
	//     });
	//   }
	// });

	initTreeMail();
	
	$(".page-aside-left-mail").resizable({
		handles: "e",
	});

	$(".page-part-center-mail").resizable({
		handles: "e"
	});

	$('a[data-popup]#btnNewPersonalMail').click(function(e) { 
	// $("body").delegate('a[data-popup]', 'click', function () {
		open_new_window_popup(this, e);
	}); 

	if (document.getElementById('tablePersonalMail')) {
		var tablePersonalMail = document.getElementById('tablePersonalMail');
		resizableGrid(tablePersonalMail);
	}

	$("body").delegate('.move_multi_mail_to_folder', 'click', function () {
	// $(".move_multi_mail_to_folder").click(function() {
		var id_folder_mail = this.getAttribute("data-value");
		var arr_mail = [];
		$.each($('.tbl_mail tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_mail.push($(row).data('item-id'));
			} 
		});

		if (arr_mail.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "move_multi_mail_to_folder",
				dataType: 'script',
				data: {
					id: arr_mail,
					fid: id_folder_mail
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	// button click next mail in content mail
	$("body").delegate('#btnPrevMail', 'click', function() {
		var personal_mail_id = this.getAttribute('value');
		var prev_row = $("#tbl_mail tbody tr#" + personal_mail_id).prev();
		if (prev_row.length > 0) {
			var prev_mail_id = prev_row[0].id
			$('#tbl_mail tbody tr#' + prev_mail_id + ' td.subject-personal-mail').click();
		}
	});

	// button click previous mail in content mail
	$("body").delegate('#btnNextMail', 'click', function() {
		var personal_mail_id = this.getAttribute('value');
		var next_row = $("#tbl_mail tbody tr#" + personal_mail_id).next();
		if (next_row.length > 0) {
			var next_mail_id = next_row[0].id;
			$('#tbl_mail tbody tr#' + next_mail_id + ' td.subject-personal-mail').click();
		}
	});

	// button choose user receiver screen create mail
	$("body").delegate('.btnChooseReceiver', 'click', function() {
		var list_email_selected_to = $("#lst_receiver_mail").tokenfield('getTokens');
		var list_email_selected_cc = $("#lst_receiver_cc").tokenfield('getTokens');
		var list_email_selected_bcc = $("#lst_receiver_bcc").tokenfield('getTokens');
		$.ajax({
			type: 'GET',
			url: "/choose_email",
			dataType: 'script',
			data: {
				list_email_selected_to: list_email_selected_to,
				list_email_selected_cc: list_email_selected_cc,
				list_email_selected_bcc: list_email_selected_bcc
			},
			success: function(data, textStatus, jqXHR){
				// 
				format_input_email("#lst_mail_to", "#list_mail_to");
				format_input_email("#lst_mail_cc", "#list_mail_cc");
				format_input_email("#lst_mail_bcc", "#list_mail_bcc");
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("Error")
			}
		});
	});

	// filter email by department
	$("body").delegate('#listDepartmentMail', 'change', function () {
		var list_email_selected = document.getElementById("list_receiver").value;
		var department_id = $("#listDepartmentMail option:selected").val().split("_")[0];
    var data_type = $("#listDepartmentMail option:selected").attr('data-type');
		$.ajax({
			type: 'GET',
			url: "/filter_email_personal",
			dataType: 'script',
			data: {
				department_id: department_id,
				data_type: data_type,
				list_email_selected: list_email_selected
			},
			success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});

	$("body").delegate('#btnSaveChooseEmail', 'click', function() {
		var list_email_selected_to = $("#lst_mail_to").tokenfield('getTokens');
		var list_email_selected_cc = $("#lst_mail_cc").tokenfield('getTokens');
		var list_email_selected_bcc = $("#lst_mail_bcc").tokenfield('getTokens');

		updateEmailSelected(list_email_selected_to,"#lst_receiver_mail", "#list_receiver_mail");
		updateEmailSelected(list_email_selected_cc,"#lst_receiver_cc", "#list_receiver_cc");
		updateEmailSelected(list_email_selected_bcc,"#lst_receiver_bcc", "#list_receiver_bcc")

		// if (list_email_selected_to.length > 0) {
		// 	var list_email_format_to = [];
		// 	$.each(list_email_selected_to, function(index, token) {
		// 		// list_email_format_to
		// 		var email = token["label"].replace("<","").replace(">","");
		// 		list_email_format_to.push(email);
		// 	});
		// 	$('#lst_receiver_mail').tokenfield('setTokens', list_email_format_to.join(", "));
		// }
		// else {
		// 	$('#list_receiver').val('');
		// }
		window.$("#modal_choose_user_mail").modal('hide');
	});

	window.$('#modal_choose_user_mail').on('hidden.bs.modal', function () {
		$('#modal_choose_user_mail').find(".modal-content").html("");
	});

	// $("body").delegate('#tbl_user_mail tbody tr', 'click', function() {
	// 	$(this).toggleClass('selected');
	// });

	$("body").delegate('.btnPushEmailSelected', 'click', function() {
		if (this.value == '1') {
			getListEmailSelected('#list_mail_to', '#lst_mail_to')
		}
		else if (this.value == '2') {
			getListEmailSelected('#list_mail_cc', '#lst_mail_cc')
		}
		else if (this.value == '3') {
			getListEmailSelected('#list_mail_bcc', '#lst_mail_bcc')
		}
		else {}
	});


	$("#btnSave_mail").click(function() {
		document.getElementById("confirm_close_page").value = 1;
	});

	

	// $('.find-person').click(function(){
 //      var row = table_personal_mail.row(function ( idx, data, node ) {
 //      return data[0] === '73';
 //    });
 //    if (row.length > 0) {
 //      row.show().draw(false);
 //    }
	// });

	$("#btnContinueSendMail").click(function() {
		document.getElementById('btnSave_mail').click();
	});

});

function updateEmailSelected(list_email_selected, id_field_email, id_hidden_field_email) {
	var list_email_format = [];
	if (list_email_selected.length > 0) {
		$.each(list_email_selected, function(index, token) {
			var email = token["label"].replace("<","").replace(">","");
			list_email_format.push(email);
		});
		list_email_format = list_email_format.join(", ")
	}
	else {
		$(id_hidden_field_email).val('');
	}
	$(id_field_email).tokenfield('setTokens', list_email_format);
}

function getListEmailSelected(id_field_hidden, id_field_email) {
	var list_email_select = tbl_user_mail.rows('.selected').data();
	var list_email_selected = $(id_field_hidden).val().split(",");
	var list_email_format = [];
	if (list_email_select.length > 0) {
		for (var i = 0; i < list_email_select.length; i++) {
			if ( list_email_selected.indexOf(list_email_select[i][1]) != 0 ) {
				var email_format = list_email_select[i][0] + " " + list_email_select[i][1];
				$(id_field_email).tokenfield('createToken', email_format);
			}
			
		}
	}
}
// // Autocomplete mail receiver
// function load_mail_receiver(email) {
// 			$.ajax({
// 				type: 'GET',
// 				url: "find_email",
// 				dataType: 'script',
// 				data: {
// 					email: email
// 				},
// 				success: function(data, textStatus, jqXHR){
// 					
// 				},
// 				error:function(jqXHR, textStatus, errorThrown){
// 					console.log("AJAX Error: #{textStatus}")
// 				}
// 			});
		
// }
window.showMailSelected = function () {
	var personal_mail_selected = document.getElementById("personal_mail_selected");
	if (personal_mail_selected && personal_mail_selected.value != "") {
		// $('#tbl_mail tbody tr#' + personal_mail_selected.value + ' td.subject-personal-mail').click();
		var fid = document.getElementById("fid").value;
		// document.getElementById(personal_mail_selected.value).children[2].click();
		// $('#tbl_mail tbody tr#' + personal_mail_selected.value + ' td.subject-personal-mail').click();
		$.ajax ({
			type: 'GET',
			url: "/show_mail_selected",
			dataType: 'script',
			data: {
				id: personal_mail_selected.value
			},
			success: function(data, textStatus, jqXHR){
				$('#tbl_mail tbody tr#' + personal_mail_selected.value + ' td.subject-personal-mail').click();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
			}
		});
	}
}
function split( val ) {
	return val.split( /,\s*/ );
}
function extractLast( term ) {
	return split( term ).pop();
}
function forward_mail_with_file_attach() {
	if (document.getElementById("list_file_attach")) {
		var arr_id_file = []
		var file_attach = document.getElementsByName("ckb_delete_document");
		for(var i = 0; i < file_attach.length; i++) {
			arr_id_file.push(file_attach[i].value)
		}
		document.getElementById("list_file_attach").value = arr_id_file;
	}
}
function validate_address_receive() {
	var list_receiver = document.getElementById("list_receiver").value;
	var list_receiver_cc = document.getElementById("list_receiver_cc").value;
	var list_receiver_bcc = document.getElementById("list_receiver_bcc").value;
	var subject = document.getElementById("personal_mail_subject").value;
	if (list_receiver == "" && list_receiver_cc == "" && list_receiver_bcc == "") {
		$("#content_warning").text("「宛先」または「CC」または「BCC」を入力してください。");
		window.$("#warning-alert-modal").modal('show');
		$("#btnContinueSendMail").addClass("d-none");
	}
	else if (subject.trim().length === 0 ) {
		$("#content_warning").text("「標題」が入力されていませんが、よろしいですか？");
		$("#btnContinueSendMail").removeClass("d-none");
		window.$("#warning-alert-modal").modal('show');
	}
	else {
		document.getElementById('btnSave_mail').click();
	}
}

// function mark_mail_important() {
//   var arr_mail = [];
//     $.each($('.tbl_mail tbody tr'), function (i, row) {
//       if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
//         arr_mail.push($(row).data('item-id'));
//       } 
//     });

//     if (arr_mail.length > 0) {
//       $.ajax ({
//         type: 'GET',
//         url: "mark_multi_mail_important",
//         dataType: 'script',
//         data: {
//           id: arr_mail,
//           fid: window.location.href.split('fid=')[1]
//         },
//         success: function(data, textStatus, jqXHR){
//           // 
//         },
//         error:function(jqXHR, textStatus, errorThrown){
//           console.log("AJAX Error");
//         }
//       });
//     }
// }
window.data_table_mail = function() {
	var table_personal_mail = window.$('#tbl_mail').DataTable({
		'destroy': true,
		"bStateSave": true,
		"fnStateSave": function (oSettings, oData) {
				localStorage.setItem('offersDataTables', JSON.stringify(oData));
		},
		"fnStateLoad": function (oSettings) {
				return JSON.parse(localStorage.getItem('offersDataTables'));
		},
		'columnDefs': [
			{
				'targets': 0,
				'width': "2%",
				'checkboxes': {
					'selectRow': true,
					'selectAllPages': false
				}
			},
			{ "width": "2%", "orderable": false, "targets": 1 },
			{ "width": "58%", "orderable": false, "targets": 2},
			{ "width": "10%", "targets": 3 },
			// { "width": "18%", "targets": 4 },
			// { "width": "12%", "targets": 5 },

		],
		'select': {
			'style': 'multi'
		},
		'order': [3, 'desc'],
		drawCallback: function() {
			$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
		},
		language:{paginate:{
			previous:"<i class='mdi mdi-chevron-left'>",
			next:"<i class='mdi mdi-chevron-right'>"}},
	});
	// table_personal_mail.draw(false);
}

window.select_row_table_mail = function(row_select) {
	// var table_personal_mail = document.getElementById('tbl_mail');

	var id_mail_selected = row_select.parentElement.dataset.itemId;
	var fid = document.getElementById("fid").value;
	var arr_row_checked = [];
	var nextURL = window.location.origin + "/personal_mails?fid=" + fid + "&personal_mail_id=" + id_mail_selected;
	const nextTitle = '';
	const nextState = '';
	window.history.pushState(nextState, nextTitle, nextURL);
	window.history.replaceState(nextState, nextTitle, nextURL);
	document.getElementById("btnNewPersonalMail").href = "personal_mails/new?fid=" + fid + "&personal_mail_id=" + id_mail_selected;
	document.getElementById("personal_mail_selected").value = id_mail_selected;

	row_select.parentElement.classList.remove("unread");
	table_personal_mail.$('tr.selected').removeClass('selected');
	$.each($('.tbl_mail tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
			arr_row_checked.push($(row));
		} 
	});


	if (arr_row_checked.length > 0) {
		if ($("#tbl_mail thead tr").find('input[type="checkbox"]')[0].checked) {
			$("#tbl_mail thead tr").find('input[type="checkbox"]')[0].click();
		}
		else {
			for (var i = 0; i < arr_row_checked.length; i++) {
				$(arr_row_checked[i]).removeClass('selected')
				$(arr_row_checked[i]).find("input[class='dt-checkboxes']:checked")[0].checked = false;
			}
		}
		arr_row_checked = [];
	}
	select_single_row("#tbl_mail tbody tr.selected", row_select);

	$.ajax ({
		type: 'GET',
		url: "/personal_mails/" + id_mail_selected + "?personal_mail_id=" + id_mail_selected,
		dataType: 'script',
		data: {
			fid: fid,
			count_row_selected: arr_row_checked.length
		},
		success: function(data, textStatus, jqXHR){
			// 
		},
		error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error");
		}
	});
}

window.select_checkbox = function(row_select) {
	var id_mail_selected = row_select.parentElement.dataset.itemId;
	var fid = document.getElementById("fid").value;
	var value_checkbox = row_select.firstChild.checked;

	var arr_row_checked = [];

	$.each($('.tbl_mail tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
			arr_row_checked.push($(row));
		} 
	});

	if (arr_row_checked.length == 1) {
		if (value_checkbox) {
			$("#tbl_mail tbody tr.selected").removeClass('selected');
			row_select.parentElement.classList.add("selected");
		} 
		else {
			row_select.parentElement.classList.remove("selected");
		}
		
	} 
	else if (arr_row_checked.length > 1) {
		if (value_checkbox) {
			row_select.parentElement.classList.add("selected");
		} 
		else {

			row_select.parentElement.classList.remove("selected");
		}
	}
	$.ajax ({
		type: 'GET',
		url: "/personal_mails/" + id_mail_selected,
		dataType: 'script',
		data: {
			fid: fid,
			count_row_selected: arr_row_checked.length
		},
		success: function(data, textStatus, jqXHR){
			// 
		},
		error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error");
		}
	});

}

window.set_selected_row_table_mail = function(row_selected) {
	$.each($('.tbl_mail tbody tr'), function (i, row) {
		if ( $(row).data('item-id') == row_selected ) {
			$(row).addClass("selected");
		} 
	});
}

function select_single_row(table, row_select) {
	$(table).removeClass('selected');
	row_select.parentElement.classList.add("selected");
}

window.select_all_checkbox_mail = function(table, value_checked) {
	if (value_checked) {
		$(table).addClass('selected');
		document.getElementById("btnMarkOpenMail").disabled = false;
		document.getElementById("btnMoveMailToTrash").disabled = false;
		document.getElementById("btnMoveMailToFolder").disabled = false;
	} 
	else {
		$(table).removeClass('selected'); 
	}
	$("#group_button_edit_mail").html("");
	$("#content_mail").html("");
}

window.open_new_window_popup = function(element, e) {
	var strWindowFeatures = "location=yes,height=800,width=1200,scrollbars=yes,status=yes";
	window.open($(element).attr('href'),'_blank', strWindowFeatures); 
	e.preventDefault();
}

function resizableGrid(table) {
	var row = table.getElementsByTagName('tr')[0],
	cols = row ? row.children : undefined;
	if (!cols) return;

	table.style.overflow = 'hidden';

	var tableHeight = table.offsetHeight;

	for (var i=0;i<cols.length;i++){
		// var div = createDiv(tableHeight);
		// cols[i].appendChild(div);
		// cols[i].style.position = 'relative';
		if (i == 0 || i == 1) {
			var div = createDiv(tableHeight);
			cols[i].appendChild(div);
			cols[i].style.position = 'relative';
			if (i == 0) cols[i].style.width = '210px';
			if (i == 1) cols[i].style.width = '550px';
			setListeners(div);
		}
	}

	function setListeners(div){
		var pageX,curCol,nxtCol,curColWidth,nxtColWidth;

		div.addEventListener('mousedown', function (e) {
		 curCol = e.target.parentElement;
		 nxtCol = curCol.nextElementSibling;
		 pageX = e.pageX; 
	 
		 var padding = paddingDiff(curCol);
	 
		 curColWidth = curCol.offsetWidth - padding;
		 if (nxtCol)
			nxtColWidth = nxtCol.offsetWidth - padding;
		});

		div.addEventListener('mouseover', function (e) {
		 e.target.style.borderRight = '5px solid #bfbfbf94';
			e.target.style.backgroundImage = "url(https://onlinedemo.cybozu.info/cb1080/image/trigger_area.png)";
			e.target.style.backgroundRepeat = "no-repeat";
			e.target.style.backgroundPosition = "center center";
			e.target.style.height = $(this).parent().height() + "px";
		})

		div.addEventListener('mouseout', function (e) {
		 e.target.style.borderRight = '';
		 e.target.style.backgroundImage = "";
		 e.target.style.backgroundRepeat = "";
		 e.target.style.backgroundPosition = "";
		})

		document.addEventListener('mousemove', function (e) {
		 if (curCol) {
			var diffX = e.pageX - pageX;
	 
			if (nxtCol)
			 nxtCol.style.width = (nxtColWidth - (diffX))+'px';

			curCol.style.width = (curColWidth + diffX)+'px';
		 }
		});

		document.addEventListener('mouseup', function (e) { 
		 curCol = undefined;
		 nxtCol = undefined;
		 pageX = undefined;
		 nxtColWidth = undefined;
		 curColWidth = undefined
		});
	}
 
	function createDiv(height){
		var div = document.createElement('div');
		div.style.top = 0;
		div.style.right = 0;
		div.style.width = '5px';
		div.style.position = 'absolute';
		div.style.cursor = 'col-resize';
		div.style.userSelect = 'none';
		div.style.height = height + 'px';
		return div;
	}
 
	function paddingDiff(col){
	 
		// if (getStyleVal(col,'box-sizing') == 'border-box'){
		//  return 0;
		// }
	 
		var padLeft = getStyleVal(col,'padding-left');
		var padRight = getStyleVal(col,'padding-right');
		return (parseInt(padLeft) + parseInt(padRight));

	}

	function getStyleVal(elm,css){
		return (window.getComputedStyle(elm, null).getPropertyValue(css))
	}
}

function splitStringAfterSecondSpace(string) {
	var parts = [], part_1, part_2;
	var lastIndex = string.lastIndexOf(" ");
	part_1 = string.substring(0, lastIndex);
	if (part_1) { parts.push(part_1); } 
	part_2 = string.substring(lastIndex);
	if (part_2) { parts.push(part_2); } 
	// parts = string.split(")");
	// parts == null ? parts = string.split() : parts = parts;
	return parts
}

window.format_input_email = function (id_textfield_email, id_hide_textfield_email) {
	if($(id_textfield_email).length == 0) return;
	$(id_textfield_email).tokenfield({
		autocomplete: {
			source: function ( request, response ) {
				// autocomplete_tokenfield(id_hide_textfield_email, request, response);
				var keyword = $(id_hide_textfield_email).val();
				keyword == "" ? keyword = "" : keyword = keyword + ","
				$.getJSON( "/filter_mail_receiver", {
						term:  keyword + request.term
				}, response );
			},
			minLength: 0,
			delay: 100,
			open: function (event, ui) {
				if( $("#modal_choose_user_mail").hasClass("show") ) {
					var z_index_dialog = $(this).closest('#modal_choose_user_mail').css('z-index');
					$('.ui-autocomplete.ui-front').css("z-index",z_index_dialog);
					$('.ui-autocomplete.ui-front').removeClass('fixed-height');
				}
				else {
					$('.ui-autocomplete.ui-front').addClass('fixed-height');
				}
				
			}
		},
		showAutocompleteOnFocus: true
	})
	// .on('change', function(e) {
	// 	var list_email = e;
	// 	$(id_hide_textfield_email).val($(this).tokenfield('getTokensList', ',',false));

	// })
	.on('tokenfield:createtoken', function (e) {
		var data = splitStringAfterSecondSpace(e.attrs.value);
		e.attrs.value = data[1].replace(" ","") || data[0];
		e.attrs.label = data[1] ? data[0].replace('(','').replace(')','') + ' <' + data[1].replace(" ","") + '>' : '<' + data[0] + '>';
		var existingTokens = $(this).tokenfield('getTokens');
		$.each(existingTokens, function(index, token) {
			if (token.value === e.attrs.value)
				e.preventDefault();
		});
		
	})
	.on('tokenfield:createdtoken', function (e) {
		// Über-simplistic e-mail validation
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var valid = re.test(e.attrs.value);
		if (!valid) {
			$(e.relatedTarget).addClass('email-invalid');
		}
		arr_list_email = $(this).tokenfield('getTokensList').split(", ");
		var mails = split_format_email(arr_list_email);
		$(id_hide_textfield_email).val(mails.join(","));
		// $(id_hide_textfield_email).val($(this).tokenfield('getTokensList',',',false));
	})
	.on('tokenfield:edittoken', function (e) {
		if (e.attrs.label !== e.attrs.value) {
			var label = splitStringAfterSecondSpace(e.attrs.label);
			e.attrs.value = label[0] ? '(' + label[0] + ') ' + label[1].replace('<','').replace('>','') : label[1].replace('<','').replace('>','');
		}
	})
	.on('tokenfield:removedtoken', function (e) {
		arr_list_email = $(this).tokenfield('getTokensList').split(", ");
		var mails = split_format_email(arr_list_email);
		$(id_hide_textfield_email).val(mails.join(","));
		// $(id_hide_textfield_email).val($(this).tokenfield('getTokensList',',',false));
	});
}

function split_format_email(arr_list_email) {
	var mails = [];
	$.each(arr_list_email, function(index, token) {
		if (token.includes(" ")) {
			var email = splitStringAfterSecondSpace(token)[1];
			mails.push(email.replace(" <","").replace(">",""));
		} 
		else {
			mails.push(token.replace("<","").replace(">",""));
		}
	});
	return mails;
}

window.change_mark_important_mail = function(row_select) {
	var id_mail = row_select.parentElement.dataset.itemId;
	var row_selected = 0;
	var personal_mail_id = document.getElementById("personal_mail_selected").value;
	if ($("#tbl_mail tbody tr.selected").length > 0) {
		row_selected = $("#tbl_mail tbody tr.selected")[0].dataset.itemId;
	} 
	$.ajax ({
		type: 'GET',
		url: "/change_mark_mail_important/" + id_mail,
		dataType: 'script',
		data: {
			personal_mail_id: personal_mail_id,
			// row_selected: row_selected,
			fid: document.getElementById("fid").value
		},
		success: function(data, textStatus, jqXHR){
			// 
		},
		error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error");
		}
	});
}

// window.datatablePersonalMail = function() {
// 	table_personal_mail = window.$('#tbl_mail').DataTable({
// 		"initComplete": function(settings, json) {
// 				var personal_mail_selected = document.getElementById("personal_mail_selected");
// 				var api = this.api();
// 				var row = api.row(function ( idx, data, node ) {
// 					return data.DT_RowId === personal_mail_selected.value;
// 				});
// 				if (row.length > 0) {
// 					row.show().draw(false);
// 				}
// 			},
// 		'destroy': true,
// 		'columnDefs': [
// 			{
// 				'targets': 0,
// 				'width': "2%",
// 				'checkboxes': {
// 					'selectRow': true,
// 					'selectAllPages': false
// 				}
// 			},
// 			{ "width": "2%", "orderable": false, "targets": 1 },
// 			{ "width": "58%", "orderable": false, "targets": 2 },
// 			{ "width": "10%", "targets": 3 },
// 			// { "width": "20%", "targets": 4 },
// 			// { "width": "12%", "targets": 5 },

// 		],
// 		'select': {
// 			'style': 'multi',
// 			info: false
// 		},
// 		'order': [3, 'desc'],
// 		drawCallback: function() {
// 			$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
// 		},
// 		language:{paginate:{
// 			previous:"<i class='mdi mdi-chevron-left'>",
// 			next:"<i class='mdi mdi-chevron-right'>"}},
// 	});
// }


function settingPersonalMailQuil(){
	if ($("#new_personal_mail").length > 0) {
        var form = document.querySelector('#new_personal_mail');
    }
	if ($(".edit_personal_mail").length > 0) {
        var form = document.querySelector('.edit_personal_mail');
    }
	if ( $("#mail-content-editor").length > 0) {
		var quill = new Quill('#mail-content-editor', {
			theme: 'snow',
			modules: {
				'toolbar': [
					[{ 'font': [] }, { 'size': [] }], 
					['bold', 'italic', 'underline', 'strike'], 
					[{ 'color': [] }, { 'background': [] }], 
					[{ 'script': 'super' }, { 'script': 'sub' }], 
					[{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'], 
					[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], 
					['direction', { 'align': [] }], ['clean']
				]
			},
		});

		form.onsubmit = function() {
			var postContentInput = document.querySelector('#personal_mail_content');
			postContentInput.value = quill.root.innerHTML;
		};
	}
	
}

// jstree folder mail
	window.initTreeMail = function (){
		var elemtree = document.getElementById('mail_datajs');
		if(!elemtree) return;
		
		var datajs = elemtree.value;
		
		datajs = datajs.replace(/'/g, '"');
		datajs = JSON.parse(datajs);

		$('#tree_view_mail').jstree({    
			core: {
				themes: { dots: false },
				// "check_callback": true,
				'data' : datajs
			}
		})
		.on('loaded.jstree', function(e,data) {
			var childrens = $("#tree_view_mail").jstree("get_children_dom","#");
			var count_ib_unread = document.getElementById("count_inbox_unread").value;
			var count_mail_draft = document.getElementById("count_mail_draft").value;
			var fid = document.getElementById("fid").value;
			var current_user_id = document.getElementById("user_id").value;
			if(childrens.length>0) 
			{
				if (parseInt(count_ib_unread) > 0) {
					$("#tree_view_mail > ul li.inbox").append('<turbo-frame id="number_ib_unread_' + current_user_id +'"><span class="badge badge-info-lighten float-right ms-2" id="count_ib_unread">'+count_ib_unread+'</span></turbo-frame>')
				}
				else {
					$("#tree_view_mail > ul li.inbox").append('<turbo-frame id="number_ib_unread_' + current_user_id +'"></turbo-frame>')
				}
				if (parseInt(count_mail_draft) > 0) {
					$("#tree_view_mail > ul li.draft").append('<span class="badge badge-info-lighten float-right ms-2" id="text_count_mail_draft">'+count_mail_draft+'</span>')
				}
				// $('#tree_view_mail').jstree('select_node', fid);
				$("#tree_view_mail").jstree().deselect_all(true);
				$('#tree_view_mail').jstree('select_node',fid);
			}
		})
		.on('activate_node.jstree', function (e, data) {
			document.getElementById("fid").value = data.node.id;

			var nextURL = window.location.origin + "/personal_mails?fid=" + data.node.id;
			const nextTitle = '';
			const nextState = '';
			ajaxChangeUrlPersonalMail(nextURL,nextTitle,nextState);
		})
		.on("select_node.jstree", function (e, data) { 
			var node_id = data.node.id;
			$("#tree_view_mail > ul li").removeClass('node_selected')
			$("#tree_view_mail > ul li#" + node_id).addClass('node_selected')
			// document.getElementById("fid").value = data.node.id;

			// var nextURL = window.location.origin + "/personal_mails?fid=" + data.node.id;
			// const nextTitle = '';
			// const nextState = '';
			// ajaxChangeUrlPersonalMail(nextURL,nextTitle,nextState);
			// console.log("node_id: " + data.node.id);
				
		});
	}
