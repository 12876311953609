import {PDFDocument} from 'pdf-lib';
import { download_helper } from '../../../packs/supports/download_helper';
import { SavePage } from './save_page';
var JSZip = require("jszip");

export class PDFDownloadHelper{   
    constructor(viewer){
        this.savePage = new SavePage();
        this.viewer = viewer;
    }
    async download(bytes,fieldName,app_type){ 
        try {
            const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
            const pages = pdfDoc.getPages();
            await save_file(this.viewer,pages,this.savePage);         
            const pdfBytes = await pdfDoc.save(); 
            if(app_type == 2){
                var base64 = Buffer.from(pdfBytes).toString('base64');
                download_file_pdf_secure(base64,this.viewer.file.id,fieldName);
            }
            else{
                download_helper.downloadPDF_by_base64(pdfBytes,fieldName)
            }
        } catch (error) {
            download_helper.downloadPDF_by_base64(bytes,fieldName)
        } 
          
    }   
    async saveComplete(){  
        // const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
        // const pages = pdfDoc.getPages();
        // await save_file(this.viewer,pages,this.savePage);         
        // const pdfBytes = await pdfDoc.save(); 
        // var base64 = Buffer.from(pdfBytes).toString('base64');
        saveComplete(this.viewer);
    }  
    
    async auto_download(id,bytes,filename,annotations,rotations){ 
        try {
            const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
            const pages = pdfDoc.getPages();
            await save_file_auto(pages,annotations,rotations,this.savePage);         
            const pdfBytes = await pdfDoc.save(); 
            var base64 = Buffer.from(pdfBytes).toString('base64');
            download_file_pdf_secure(base64,id,filename);
        } catch (error) {
            //  var base64 = Buffer.from(pdfBytes).toString('base64');
            //  download_file_pdf_secure(base64,id,filename);
            download_helper.downloadPDF_by_base64(bytes,filename)
        } 
       

        // var base64 = Buffer.from(pdfBytes).toString('base64');
        // download_file_pdf_secure(base64,id,filename);
    }   
    async get_data_base64_file_complete(bytes,annotations,rotations){  
        const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
        const pages = pdfDoc.getPages();
        await save_file_auto(pages,annotations,rotations,this.savePage);         
        const pdfBytes = await pdfDoc.save();         
        var base64 = Buffer.from(pdfBytes).toString('base64');
        return base64;
    }   
    async combine_data_file_to_blob(bytes,annotations,rotations){  
        const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
        const pages = pdfDoc.getPages();
        await save_file_auto(pages,annotations,rotations,this.savePage);         
        const pdfBytes = await pdfDoc.save(); 
        var blob = new Blob([pdfBytes], {type: "application/pdf"});
        return blob;       
    }
    async downloadSplitFile(bytes){
        const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
        const pages = pdfDoc.getPages();
        await save_file(this.viewer,pages,this.savePage);
        var listSpan = getListCutted();
        var lstPDFs = await splitPDFByPositions(pdfDoc, listSpan);
        downloadSplittedPDFs(lstPDFs);
        window.$("#modal-split-file").modal('hide');
        // uploadPDF();

    }
}
// const uploadPDF = async () => {
//     const response = await fetch('https://wa4nadbkql.execute-api.ap-northeast-1.amazonaws.com/production/split', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         bucket_name: 'test-sanze',
//         pdf_key: '+-()!@#$%^&~ああああTestDũng (11).pdf'
//       })
//     });
//     const data = await response.json();
//     console.log('Download ZIP:', data.zip_url);
//   };
function download_file_pdf_secure(base64,id,filename){
    $.ajax({
        type: "POST",
        dataType: 'json',
        url: window.location.origin + "/pdf/download_file_pdf_secure",
        data: {
            id: id,
            filename: filename,
            base64: base64
        },   
        success: function (data, text) {
            var link = document.createElement("a");
            link.href = data.url;
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
            hideFormLoading(); 
        },
        error: function (request, status, error) {
            alert(request.responseText);           
        }
        
    });
}
function saveComplete(viewer){
    $.ajax({
        type: "POST",
        url: !check_is_stamp_on_mobile() ? window.location.origin + "/pdf/finish_pdf" : window.location.origin + "/mobile/pdf/finish_pdf",
        data: {
            id: viewer.file.id,
            // field_name: fieldName,
            finish_comment: document.getElementById('finish_comment').value,
            // base64: base64
        },   
        success: function (data, text) {
            // viewer.reloadData(viewer.file.url);
        },
        error: function (request, status, error) {
            alert(request.responseText);           
        }
        
    });   
}
async function save_file(viewer,pages,savePage) {
    var pages_on_viewer = viewer.file.pages;
    var index = 0;
    for await(const page of pages) {        
        // save annotations
        await saveAnnotations(page,index,savePage,pages_on_viewer[index].annotation);
         // save rotate
        saveRotate(page,pages_on_viewer[index].viewRotate,savePage);
        index++;
    }
}
async function save_file_auto(pages,annotations,rotations,savePage) {
    var index = 0, listAnnotations = [],annotation,annotation_objects = [], record_rotation = null,page_rotation = 0;
    for await(const page of pages) {    
        if(annotations){
            // get annotation by index page
            listAnnotations = annotations[index + 1]
            // save annotations
            if(listAnnotations && listAnnotations.length > 0){
                annotation_objects = []
                for (let j = 0; j < listAnnotations.length; j++) {    
                    if(listAnnotations[j].json){
                        annotation = listAnnotations[j].json
                    }
                    else{
                        annotation = listAnnotations[j].annotation
                    }
                    const element = get_json_from_string( annotation);
                    if(listAnnotations[j].path.url){
                        element.src = listAnnotations[j].path.url;
                    }
                    if(element.src){
                        var oldUrl = element.src;
                        try {
                            var url = new URL(oldUrl);
                            url.hostname = `${document.body.getAttribute('data-s3-bucket')}.s3.ap-northeast-1.amazonaws.com`;
                            element.src = url.href;
                        } catch (error) {
                            element.src = `https://${document.body.getAttribute('data-s3-bucket')}.s3.ap-northeast-1.amazonaws.com` + oldUrl;
                        }
                       
                    }   
                    annotation_objects.push(element);
                    
                }
                await saveAnnotations(page,index,savePage,annotation_objects);
            }            
        }  
        if(rotations.length > 0)  {
            // get rotations by index page
            page_rotation = 0;
            record_rotation = rotations.find((e) => e.page_index-1 == index)
            if(record_rotation){
                page_rotation = parseInt(record_rotation.rotate);
            }
            // save rotate
            saveRotate(page,page_rotation,savePage);
        }          
        index++;
    }
}

function saveRotate(page,viewRotate,savePage){
    var total_rotate = (viewRotate + page.getRotation().angle)%360;
    savePage.saveRotation(page,total_rotate) 
 }
async function saveAnnotations(page,index,savePage,array_annotations){
   if(array_annotations && array_annotations.length >0){
        // console.log('---- Start save page :' + (index + 1));
        // console.log('Number of item :' + array_annotations.length);
        await savePage.saveAnnotations(page,array_annotations);
        // console.log('---- End save page :' + (index + 1));
   }

}


// start = 1
function get_list_annotation_by_index_page(page_index){
    var list_annos = annotation_helper.get_annotations_by_index(page_index);
    var array_json_annotations = []
    if(list_annos && list_annos.length > 0){
        console.log("list_annos.length " + list_annos.length)
        for (let index = 0; index < list_annos.length; index++) {
            array_json_annotations.push(JSON.parse(list_annos[index].value));
        }
    }  
    return array_json_annotations;
}

function getListCutted(){
    var listIndexCutted = []
    var listChild = document.getElementById('contentSplit').querySelectorAll('.bar-split [selected=true]');
    for (let index = 0; index < listChild.length; index++) {
        listIndexCutted.push(parseInt(listChild[index].parentElement.getAttribute('num')));        
    }
   return listIndexCutted;
}

async function splitPDFByPositions(pdf, positions) {
    var splittedPDFs = [];

    // Check if positions array is empty
    if (positions.length === 0) {
        var originalDocBytes = await pdf.save();
        splittedPDFs.push(originalDocBytes);
        return splittedPDFs;
    }

    // first pdf
    var firstPosition = positions[0] + 1;
    var firstDoc = await PDFDocument.create();
    for (var pageIndex = 0; pageIndex < firstPosition; pageIndex++) {
        var [copiedPage] = await firstDoc.copyPages(pdf, [pageIndex]);
        firstDoc.addPage(copiedPage);
    }
    var firstDocBytes = await firstDoc.save();
    splittedPDFs.push(firstDocBytes);
    
    // center pdfs
    if (positions.length > 1) {
        for (var i = 0; i < positions.length - 1; i++) {
            var startPosition = positions[i] + 1;
            var endPosition = positions[i + 1] + 1 ;
            var splittedDoc = await PDFDocument.create();
            
            for (var pageIndex = startPosition; pageIndex < endPosition; pageIndex++) {
                var [copiedPage] = await splittedDoc.copyPages(pdf, [pageIndex]);
                splittedDoc.addPage(copiedPage);
            }
            
            var splittedDocBytes = await splittedDoc.save();
            splittedPDFs.push(splittedDocBytes);
        }
    }

    // last pdf
    var lastPosition = positions[positions.length - 1] + 1;
    var lastDoc = await PDFDocument.create();
    for (var pageIndex = lastPosition; pageIndex < pdf.getPageCount(); pageIndex++) {
        var [copiedPage] = await lastDoc.copyPages(pdf, [pageIndex]);
        lastDoc.addPage(copiedPage);
    }
    var lastDocBytes = await lastDoc.save();
    splittedPDFs.push(lastDocBytes);
    // for (let index = 0; index < positions.length; index++) {
    //     const element = positions[index];
    //     var start = 0;
    //     var end = element
    //     if(index != 0){
    //         start = positions[index-1]+1;
    //     }
    //     var fileNew = await create_byte_file_new(bytes,start,end);
    //     splittedPDFs.push(fileNew);

    //     if(index == (positions.length-1)){
    //         start = positions[index]+1;
            
    //         var fileNewEnd = await create_byte_file_new(bytes,start,null);
    //         splittedPDFs.push(fileNewEnd);
    //     }
        
    // }
    
    return splittedPDFs;
}

async function create_byte_file_new(bytes, startPage, endPage) {
    const pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true })
     // Loại bỏ các trang ngoài khoảng startPage đến endPage
     const pageCount = pdfDoc.getPageCount();

     if(endPage == null){
       endPage = pageCount -1;
     }
    // Kiểm tra rằng startPage và endPage là hợp lệ
    if (startPage < 0 || endPage >= pdfDoc.getPageCount() || startPage > endPage) {
        console.error('Vị trí bắt đầu và kết thúc không hợp lệ');
        return;
      }
  
     
      // Xóa các trang không nằm trong khoảng
      for (let i = pageCount - 1; i >= 0; i--) {
        if (i < startPage || i > endPage) {
          pdfDoc.removePage(i);
        }
      }
  
      // Lưu file PDF mới
      return await pdfDoc.save();
  
}
async function downloadSplittedPDFs(splittedPDFs) {
    var zip = new JSZip();
    var pdfBytes, blob, file;
  
    for (var i = 0; i < splittedPDFs.length; i++) {
        pdfBytes = splittedPDFs[i].buffer;
        blob = new Blob([pdfBytes], {type: "application/pdf"});
        file = {
            name: `splittedPDF_${i + 1}.pdf`,
			content: blob,
        }
        addFileToZip(file, zip);
    }
  
    await zip.generateAsync({ type: "blob" }).then(function (content) {
		var link = document.createElement("a");
		link.href = URL.createObjectURL(content);
		link.download = "SplitPDF.zip";
		link.click();
		URL.revokeObjectURL(link.href);
		hideFormLoading(); 
	});
}

function addFileToZip(file, zip) {
	var filename = file.name;
	var content = file.content;
    const currDate = new Date();
    const dateWithOffset = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
    JSZip.defaults.date = dateWithOffset;
	zip.file(filename, content, {
		binary: true
	});
}
