
import { EventBus } from '../event/EventBus';
import { EditToolbar } from './edittoolbar';
import {PDFViewer} from './PDFViewer';
import { PropertiesToolbar } from './properties_toolbar';
import { SettingToolbar } from './settingtoolbar';
import { ToolBar } from './toolbar';
import {toolBarHelper} from '../edit/toolbar_helper';
import {Config} from '../define/AppConfig';
import { fabric_custom } from './fabric_custom';

import { url_helper } from '../../../packs/supports/url_helper';
import { ToolbarSecond } from './toolbarSecond';

const mode = {
  VIEW: 0,
  EDIT:1
}
const APP_TYPE = {
  VIEW: 0,
  EDIT:1,
  STAMP: 2
}
export const PDFViewerApplication = {
  appConfig: null,
  viewComponents: null,    
  pdfDocument: null,
  pdfLoadingTask: null,
  pdfViewer: null,
  /** @type {PDFThumbnailViewer} */
  pdfThumbnailViewer: null,
  /** @type {PDFRenderingQueue} */
  pdfRenderingQueue: null,
  url: "",
  toolbar: null,
  edittoolbar: null,
  current_mode: mode.VIEW,
  _boundEvents: null,
  _wheelUnusedTicks: 0,
  async initialize(appConfig,viewComponents) {
    this.viewComponents = viewComponents;
    this.appConfig = appConfig;     
    await this._initializeViewerComponents();
    this.bindEvents();    
  },  
  set_annotation(annotation){
    set_annotation(annotation);
  },
  set_mode(mode){
    this.current_mode = mode
  },
  set_app_type(mode){
    this.app_type = mode
    //   VIEW: 0,
    // EDIT:1,
    // STAMP: 2
  },
  async open(url){    
    this.url = url;
    await this.pdfViewer.openURL(this.url);   
   
  },
  bindEvents(){
    const {
      eventBus,
      _boundEvents,
      viewComponents
    } = this;
  
    viewComponents.viewContainer.addEventListener('mousedown',webViewerMouseDown);    
    viewComponents.viewContainer.addEventListener('mouseup',webViewerMouseUp);
    document.body.addEventListener('keyup', keyPress, false)
    viewComponents.viewContainer.addEventListener('keydown', function(options) {
      if (options.repeat) {
        return;
      }
      var key = options.which || options.keyCode; // key detection
      if (key === 46) { // handle Down key
        keyboardDeleteElement();               
      } 
     })
  
    if(this.viewComponents.scrollContent){
      this.viewComponents.scrollContent.addEventListener('scroll', webViewerScroll); 
    }    
 
    
    window.addEventListener("wheel", webViewerWheel, { passive: false });
    eventBus._on("formLayerClick", formLayerClick);
    eventBus._on("firstpage", webViewerFirstPage);
    eventBus._on("endpage", webViewerEndPage);
    eventBus._on("nextpage", webViewerNextPage);
    eventBus._on("previouspage", webViewerPreviousPage);
    eventBus._on("zoomin", webViewerZoomIn);
    eventBus._on("zoomout", webViewerZoomOut);
    eventBus._on("pagechanging", webViewerPageChanging);
    eventBus._on("rotateleft", webViewerRotateLeft);
    eventBus._on("rotateright", webViewerRotateRight);
    eventBus._on("changescale", webViewerChangeScale);

    eventBus._on("select_text", webViewerSelectText);
    eventBus._on("select_annotation", webViewerSelectannotation);
    eventBus._on("select_hand", webViewerSelectHand);  
    eventBus._on("download", download);
    eventBus._on("add_text", add_text);
      eventBus._on("highlight", highlight);
      eventBus._on("strikeout", strikeout);
      eventBus._on("underline", underline);
      eventBus._on("draw_line", draw_line);
      eventBus._on("draw_arrow", draw_arrow);
      eventBus._on("draw_ellipse", draw_ellipse);
      eventBus._on("draw_rectangle", draw_rectangle);
      eventBus._on("mark_x", mark_x);
      eventBus._on("mark_v", mark_v);
      eventBus._on("mark_o", mark_o);
      eventBus._on("form_textbox", form_textbox);
      eventBus._on("form_textarea", form_textarea);
      eventBus._on("form_radio", form_radio);
      eventBus._on("form_checkbox", form_checkbox);
      eventBus._on("form_dropdown", form_dropdown);
      eventBus._on("add_image", add_image);
      eventBus._on("pen", highlightbrush);
      eventBus._on("free_draw", brush);
      eventBus._on("erase", erase);
      eventBus._on("link", link);
      eventBus._on("customize_tool", customize_tool);
      eventBus._on("close_tool", close_tool);      
      eventBus._on("undo", undo);
      eventBus._on("redo", redo);
      eventBus._on("reload", this.reload);      
     
      eventBus._on("saveArrageFile", this.saveArrageFile);      
      eventBus._on("save", this.save);
      eventBus._on("saveMergeFile", this.saveMergeFile); 
      eventBus._on("downloadSplitFile", this.downloadSplitFile); 
      eventBus._on("saveComplete", saveComplete);
      // properties
      eventBus._on("increaseSizeText", increaseSizeText);
      eventBus._on("decreaseSizeText", decreaseSizeText);
      eventBus._on("boldtext", boldtext);
      eventBus._on("italictext", italictext);
      eventBus._on("fontTextChange", fontTextChange);
      eventBus._on("textColor", textColor);
      eventBus._on("commonDelete", commonDeleteObject);
      eventBus._on("commonClone", commonClone);
      eventBus._on("keyDelete", keyDelete);
      eventBus._on("selectStamp", selectStamp);      
      
      eventBus._on("updateLineWidth", updateLineWidth);
      eventBus._on("updateLineColor", updateLineColor);
      eventBus._on("updateLineOpacity", updateLineOpacity);
      eventBus._on("close_dialog", close_dialog);
      eventBus._on("updateBackgroundColor", updateBackgroundColor);
      eventBus._on("savelink", savelink);
      
      // fabric
      eventBus._on("object_added", object_added);
      eventBus._on("object_removed", object_removed);
      eventBus._on("object_modified", object_modified);
      eventBus._on("mousedblclick", mousedblclick);
      
      eventBus._on("path_created", path_created);
      eventBus._on("object_moving", object_moving);
      eventBus._on("before_render", before_render);
      eventBus._on("mouse_down_before", mouse_down_before);
      eventBus._on("fabric_mouse_down", mouseDown);
      eventBus._on("fabric_mouse_over", fabric_mouse_over);
      eventBus._on("canvas_after_render", canvas_after_render);
      eventBus._on("mouse_move", mouse_move);
      eventBus._on("mouse_up", mouse_up);

      eventBus._on("object_selected", object_selected);
      eventBus._on("selection_cleared", selection_cleared);
      eventBus._on("image_item", image_item);
      eventBus._on("toolButton", toolButton);
      
      eventBus._on("brushChangeStrokeWidth", brushChangeStrokeWidth);
      eventBus._on("brushChangeStrokeColor", brushChangeStrokeColor);
      eventBus._on("changeformcontrolwidth", changeformcontrolwidth);
      eventBus._on("changeformcontrolborderwidth", changeformcontrolborderwidth);      
      eventBus._on("changeformcontrolHeight", changeformcontrolHeight);
      eventBus._on("changeformcontrolColor", changeformcontrolColor);
      eventBus._on("changeformcontrolBorderColor", changeformcontrolBorderColor);
       //element
      eventBus._on("selectElement", selectElement);
      eventBus._on("showtoolElement", showtoolElement);
      eventBus._on("updateElementColor", updateElementColor);
      eventBus._on("updateBorderColor", updateBorderColor);
      eventBus._on("updateBoderWidth", updateBoderWidth);
      eventBus._on("updateCenterColorElem", updateCenterColorElem);
      eventBus._on("changeFieldName", changeFieldName);
      eventBus._on("updateOptionSelect", updateOptionSelect);
     
      eventBus._on("updateBackgroundColorDiv", updateBackgroundColorDiv);
      eventBus._on("delete_element", delete_element);
      //annotation
      eventBus._on("delete_annotation", delete_annotation);      
      eventBus._on("itemannotation_click", itemannotation_click);
      eventBus._on("screenshot", screenshot);
      // common events
      eventBus._on("thick_item", thick_item);
      eventBus._on("dash_item", dash_item);
      eventBus._on("arrow_item", arrow_item);
      
      eventBus._on("color_item", color_item);
      eventBus._on("color_pick", color_pick);
      eventBus._on("opacity_item", opacity_item);
      eventBus._on("width_item", width_item);
      eventBus._on("height_item", height_item);
      eventBus._on("font_family", font_family);
      eventBus._on("font_size", font_size);
      eventBus._on("bold_text", bold_text);
      eventBus._on("italic_text", italic_text);

      eventBus._on("date_format", date_format);
      eventBus._on("date_out_put", date_out_put);
      

      eventBus._on("slider_item", slider_item);
      eventBus._on("text_slider", text_slider);
      
      eventBus._on("check_box_item", check_box_item);
      eventBus._on("show_modal_share", show_modal_share);
      eventBus._on("show_modal_reject", show_modal_reject);
      eventBus._on("show_modal_final", show_modal_final);
      
      // event user define
      eventBus._on("update_thumnail", update_thumnail);
    if(this.app_type == APP_TYPE.EDIT){
      load_settings_tool();   
      set_setting_to_controls(); 
      set_setting_to_toolbarSecondary();     
      this.pdfViewer.setFormStatus(mode.EDIT);
         
      
    }
  },
  async _initializeViewerComponents() {
    const eventBus = new EventBus();
    this.eventBus = eventBus;
    this.toolbar = new ToolBar(this.viewComponents.toolbar,this.eventBus);
    this.pdfViewer = new PDFViewer();
    this.pdfViewer.initial(this.viewComponents,eventBus,this.current_mode,this.app_type);    
    // if(this.current_mode == mode.EDIT){
      this.edittoolbar = new EditToolbar(this.viewComponents.edittoolbar,this.eventBus);
      if(this.app_type == APP_TYPE.EDIT){        
        this.toolbarSecond = new ToolbarSecond(this.viewComponents.toolbarSecond,this.eventBus);
      }
      this.settingToolbar = new SettingToolbar(null,this.eventBus);
      this.propertiestoolbar = new PropertiesToolbar(this.viewComponents.propertiesToolbar,this.eventBus);
      pdfjsLib.GlobalWorkerOptions.workerSrc = window.origin + "/libs/pdfjs/build/pdf.worker.min.js";
      fabric.Group.prototype._controlsVisibility = {
        tl: false,
        tr: false,
        br: false,
        bl: false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false
    };
      // fabric.Object.prototype.transparentCorners = false;
      // fabric.Object.prototype.cornerColor = "white";
      // fabric.Object.prototype.__corner = 1;
      // fabric.Object.prototype.cornerStrokeColor = '#0282e5';
      // // fabric.Object.prototype.cornerStyle = 'circle';
      // fabric.Object.prototype.borderDashArray = [8,4];
      // fabric.Object.prototype.borderColor = "#0282e5";
      // fabric.Object.prototype.borderScaleFactor = 1;
      // fabric.Object.prototype.cornerSize = 6;


      // const originalSet = fabric.Object.prototype.set;
      // fabric.Object.prototype.set = function(key, value) {
      //   if (key === 'fill') { 
      //     this.fire('fill:change', { old: this.fill, new: value  }); 
      //   }
      //   originalSet.call(this, key, value);
        
      // }
      fabric_custom(this.pdfViewer);
    // }
  },
  async save(){
    await PDFViewerApplication.pdfViewer.save(); 
    
  },
  async saveArrageFile(){
    await PDFViewerApplication.pdfViewer.saveArrageFile(); 
    
  },
  async saveMergeFile(){
    await PDFViewerApplication.pdfViewer.saveMergeFile(); 
  },

  async downloadSplitFile(){
    await PDFViewerApplication.pdfViewer.downloadSplitFile(); 
  },
  
  async reload(){    
    await PDFViewerApplication.pdfViewer.reloadData(PDFViewerApplication.url);
    
  },
  get_form_status(){
    return PDFViewerApplication.pdfViewer.form_status;
  },
  accumulateWheelTicks(ticks){
    if (this._wheelUnusedTicks > 0 && ticks < 0 || this._wheelUnusedTicks < 0 && ticks > 0) {
      this._wheelUnusedTicks = 0;
    }
  
    this._wheelUnusedTicks += ticks;
    const wholeTicks = Math.sign(this._wheelUnusedTicks) * Math.floor(Math.abs(this._wheelUnusedTicks));
    this._wheelUnusedTicks -= wholeTicks;
    return wholeTicks;
  }
}

// edit
function webViewerSelectText(){
  PDFViewerApplication.pdfViewer.select_text(); 
}
function webViewerSelectannotation(){
  PDFViewerApplication.pdfViewer.select_annotation(); 
}
function webViewerSelectHand(){
  PDFViewerApplication.pdfViewer.select_hand(); 
}

// start action annotation
function add_text(){
  PDFViewerApplication.pdfViewer.add_text(); 
}
function highlight(){
  PDFViewerApplication.pdfViewer.highlight(); 
}
function strikeout(){
  PDFViewerApplication.pdfViewer.strikeout(); 
}
function underline(){
  PDFViewerApplication.pdfViewer.underline(); 
}
function draw_line(){
  PDFViewerApplication.pdfViewer.draw_line(); 
}
function draw_arrow(){
  PDFViewerApplication.pdfViewer.draw_arrow(); 
}
function draw_ellipse(){
  PDFViewerApplication.pdfViewer.draw_ellipse(); 
}
function draw_rectangle(){
  PDFViewerApplication.pdfViewer.draw_rectangle(); 
}
function mark_x(data){
  PDFViewerApplication.pdfViewer.mark_x(data); 
}
function mark_v(data){
  PDFViewerApplication.pdfViewer.mark_v(data); 
}
function mark_o(data){
  PDFViewerApplication.pdfViewer.mark_o(data); 
}
function form_textbox(){
  PDFViewerApplication.pdfViewer.form_textbox(); 
}
function form_textarea(){
  PDFViewerApplication.pdfViewer.form_textarea(); 
}
function form_radio(){
  PDFViewerApplication.pdfViewer.form_radio(); 
}
function form_checkbox(){
  PDFViewerApplication.pdfViewer.form_checkbox(); 
}
function form_dropdown(){
  PDFViewerApplication.pdfViewer.form_dropdown(); 
}
function add_image(){
  PDFViewerApplication.pdfViewer.add_image(); 
}
function brush(){
  PDFViewerApplication.pdfViewer.pen();
}
function highlightbrush(){
  PDFViewerApplication.pdfViewer.free_draw(); 
}
function erase(){
  PDFViewerApplication.pdfViewer.erase(); 
}
function link(){
  PDFViewerApplication.pdfViewer.link(); 
}
function customize_tool(){
  PDFViewerApplication.pdfViewer.customize_tool(); 
}
function close_tool(){
  PDFViewerApplication.pdfViewer.close_tool(); 
}

function undo(){
  PDFViewerApplication.pdfViewer.undo(); 
}
function redo(){
PDFViewerApplication.pdfViewer.redo(); 
}
function download(data){
  PDFViewerApplication.pdfViewer.download(data); 
}
function saveComplete(data){
  PDFViewerApplication.pdfViewer.saveComplete(data); 
}

// end action annotation

// start properties
function increaseSizeText(){
  PDFViewerApplication.pdfViewer.increaseSizeText(); 
}
function decreaseSizeText(){
  PDFViewerApplication.pdfViewer.decreaseSizeText(); 
}
function boldtext(){
  PDFViewerApplication.pdfViewer.boldtext(); 
}
function italictext(){
  PDFViewerApplication.pdfViewer.italictext(); 
}

function fontTextChange(data){
  PDFViewerApplication.pdfViewer.fontTextChange(data); 
}

function textColor(data){
  PDFViewerApplication.pdfViewer.textColor(data); 
}
function commonDeleteObject(data){
  PDFViewerApplication.pdfViewer.commonDeleteObject(data); 
}
function commonClone(data){
  PDFViewerApplication.pdfViewer.commonClone(data); 
}

function keyDelete(data){
  PDFViewerApplication.pdfViewer.keyDelete(data); 
}
function selectStamp(data){
  PDFViewerApplication.pdfViewer.selectStamp(data); 
}


function updateLineWidth(data){
  PDFViewerApplication.pdfViewer.updateLineWidth(data); 
}

function updateLineOpacity(data){
  PDFViewerApplication.pdfViewer.updateLineOpacity(data); 
}

function updateLineColor(data){
  PDFViewerApplication.pdfViewer.updateLineColor(data); 
}
function close_dialog(data){
  PDFViewerApplication.pdfViewer.close_dialog(data); 
}
function updateBackgroundColor(data){
  PDFViewerApplication.pdfViewer.updateBackgroundColor(data); 
}
function savelink(data){
  PDFViewerApplication.pdfViewer.savelink(data); 
}

// end properties
function canvas_after_render(data){
  PDFViewerApplication.pdfViewer.canvas_after_render(data);
}
function mouseDown(data){
  PDFViewerApplication.pdfViewer.mouseDownEvent(data);
}

function fabric_mouse_over(data){
  PDFViewerApplication.pdfViewer.fabric_mouse_over(data);
}
function object_added(data){
  PDFViewerApplication.pdfViewer.object_added(data);
}
function object_removed(data){
  PDFViewerApplication.pdfViewer.object_removed(data);
}
function object_modified(data){
  PDFViewerApplication.pdfViewer.object_modified(data); 
  PDFViewerApplication.pdfViewer.saveElementAnnotation(data); 
}
function mousedblclick(data){
  PDFViewerApplication.pdfViewer.mousedblclick(data);    
}

function path_created(data){
  PDFViewerApplication.pdfViewer.path_created(data);
}
function object_moving(data){
  PDFViewerApplication.pdfViewer.object_moving(data);
}
function before_render(data){
  PDFViewerApplication.pdfViewer.before_render(data);
}
function mouse_move(data){
  PDFViewerApplication.pdfViewer.mouse_move(data);
}
function mouse_down_before(data){
  PDFViewerApplication.pdfViewer.mouse_down_before(data);
}

function mouse_up(data){
  PDFViewerApplication.pdfViewer.mouse_up(data);
}
function object_selected(data){
  PDFViewerApplication.pdfViewer.object_selected(data);
}
function selection_cleared(data){
  PDFViewerApplication.pdfViewer.object_selected(data);
}

function image_item(data){
  PDFViewerApplication.pdfViewer.image_item(data);
}

function toolButton(data){
  PDFViewerApplication.pdfViewer.toolButton(data);
}
function brushChangeStrokeWidth(data){
  PDFViewerApplication.pdfViewer.brushChangeStrokeWidth(data);
}
function brushChangeStrokeColor(data){
  PDFViewerApplication.pdfViewer.brushChangeStrokeColor(data);
}
function changeformcontrolwidth(data){
  PDFViewerApplication.pdfViewer.changeformcontrolwidth(data);
}

function changeformcontrolborderwidth(data){
  PDFViewerApplication.pdfViewer.changeformcontrolborderwidth(data);
}

function changeformcontrolHeight(data){
  PDFViewerApplication.pdfViewer.changeformcontrolHeight(data);
}

function changeformcontrolColor(data){
  PDFViewerApplication.pdfViewer.changeformcontrolColor(data);
}
function changeformcontrolBorderColor(data){
  PDFViewerApplication.pdfViewer.changeformcontrolBorderColor(data);
}

function selectElement(data){
  PDFViewerApplication.pdfViewer.selectElement(data);
}
function showtoolElement(data){
  PDFViewerApplication.pdfViewer.showtoolElement(data);
}

function updateElementColor(data){
  PDFViewerApplication.pdfViewer.updateElementColor(data);
}
function updateBorderColor(data){
  PDFViewerApplication.pdfViewer.updateBorderColor(data);
}
function updateBoderWidth(data){
  PDFViewerApplication.pdfViewer.updateBoderWidth(data);
}
function updateCenterColorElem(data){
  PDFViewerApplication.pdfViewer.updateCenterColorElem(data);
}

function changeFieldName(data){
  PDFViewerApplication.pdfViewer.changeFieldName(data);
}
function updateOptionSelect(data){
  PDFViewerApplication.pdfViewer.updateOptionSelect(data);
}


function updateBackgroundColorDiv(data){
  PDFViewerApplication.pdfViewer.updateBackgroundColorDiv(data);
}
function delete_element(){
  PDFViewerApplication.pdfViewer.delete_element();
}

function delete_annotation(data){
  PDFViewerApplication.pdfViewer.delete_annotation(data);
}
function itemannotation_click(data){
  PDFViewerApplication.pdfViewer.itemannotation_click(data);
}
function screenshot(data){
  PDFViewerApplication.pdfViewer.screenshot(data);
}

function thick_item(data){
  PDFViewerApplication.pdfViewer.thick_item(data);
}
function dash_item(data){
  PDFViewerApplication.pdfViewer.dash_item(data);
}
function arrow_item(data){
  PDFViewerApplication.pdfViewer.arrow_item(data);
}

function color_item(data){
  PDFViewerApplication.pdfViewer.color_item(data);
}
function color_pick(data){
  PDFViewerApplication.pdfViewer.color_pick(data);
}
function opacity_item(data){
  PDFViewerApplication.pdfViewer.opacity_item(data);
}
function width_item(data){
  PDFViewerApplication.pdfViewer.width_item(data);
}
function height_item(data){
  PDFViewerApplication.pdfViewer.height_item(data);
}
function font_family(data){
  PDFViewerApplication.pdfViewer.font_family(data);
}
function font_size(data){
  PDFViewerApplication.pdfViewer.font_size(data);
}
function bold_text(data){
  PDFViewerApplication.pdfViewer.bold_text(data);
}
function italic_text(data){
  PDFViewerApplication.pdfViewer.italic_text(data);
}
function date_format(data){
  PDFViewerApplication.pdfViewer.date_format(data);
}
function date_out_put(data){
  PDFViewerApplication.pdfViewer.date_out_put(data);
}

function update_thumnail(data){
  PDFViewerApplication.pdfViewer.update_thumnail(data);
}
function slider_item(data){
  PDFViewerApplication.pdfViewer.slider_item(data);
}
function text_slider(data){
  PDFViewerApplication.pdfViewer.text_slider(data);
}

function check_box_item(data){
  PDFViewerApplication.pdfViewer.check_box_item(data);
}


function show_modal_share(data){
  PDFViewerApplication.pdfViewer.show_modal_share(data);
}
function show_modal_reject(data){
  PDFViewerApplication.pdfViewer.show_modal_reject(data.id);
}
function show_modal_final(data){
  PDFViewerApplication.pdfViewer.show_modal_final(data.id);
}


//
function webViewerChangeScale(scale){
  PDFViewerApplication.pdfViewer.changescale(scale.scale_value);
 
}
function webViewerRotateLeft(){
  PDFViewerApplication.pdfViewer.rotateleft();
 
}
function webViewerRotateRight(){
  PDFViewerApplication.pdfViewer.rotateright(); 
}

function webViewerPageChanging({pageNumber}) {  
  PDFViewerApplication.viewComponents.viewContainer.querySelector(`#page-${pageNumber}`) .scrollIntoViewIfNeeded();
  PDFViewerApplication.pdfViewer.updatetoolbar(pageNumber);
  toolBarHelper.hideToolProperties();
  PDFViewerApplication.pdfViewer.page_change(pageNumber);
  if(PDFViewerApplication.current_mode == 1){
    url_helper.add_params_to_url('page',pageNumber);
  }  

  return true;
}
function set_annotation(annotation){
  PDFViewerApplication.pdfViewer.set_annotation(annotation);
}
function webViewerScroll(){
  PDFViewerApplication.pdfViewer.scroll();
}
function webViewerMouseDown(event){
  PDFViewerApplication.pdfViewer.mousedown(event);
}
function webViewerMouseUp(event){
  PDFViewerApplication.pdfViewer.mouseup(event);
}
function keyPress(event){
  PDFViewerApplication.pdfViewer.keyPress(event);
}
function formLayerClick(ev){
  PDFViewerApplication.pdfViewer.formLayerClick(ev);
}

function keyboardDeleteElement(ev){
  PDFViewerApplication.pdfViewer.keyboardDeleteElement(ev);
}
function webViewerWheel(evt) {
  if (evt.ctrlKey){
    evt.preventDefault();
    const deltaMode = evt.deltaMode;
    const delta = (0, normalizeWheelEventDirection)(evt);
    // const previousScale = pdfViewer.currentScale;
    let ticks = 0;
  
    if (deltaMode === WheelEvent.DOM_DELTA_LINE || deltaMode === WheelEvent.DOM_DELTA_PAGE) {
      if (Math.abs(delta) >= 1) {
        ticks = Math.sign(delta);
      } else {
        ticks = PDFViewerApplication.accumulateWheelTicks(delta);
      }
    } else {
      const PIXELS_PER_LINE_SCALE = 30;
      ticks = PDFViewerApplication.accumulateWheelTicks(delta / PIXELS_PER_LINE_SCALE);
    }
  
    if (ticks < 0) {
      PDFViewerApplication.pdfViewer.zoomOut(-ticks);
    } else if (ticks > 0) {
      PDFViewerApplication.pdfViewer.zoomIn(ticks);
    }
  }
 
}

function normalizeWheelEventDirection(evt) {
  let delta = Math.hypot(evt.deltaX, evt.deltaY);
  const angle = Math.atan2(evt.deltaY, evt.deltaX);

  if (-0.25 * Math.PI < angle && angle < 0.75 * Math.PI) {
    delta = -delta;
  }

  return delta;
}
function webViewerFirstPage() {
  PDFViewerApplication.pdfViewer.firstpage();
}
function webViewerEndPage() {
  PDFViewerApplication.pdfViewer.endpage();
}
function webViewerNextPage() {
  PDFViewerApplication.pdfViewer.nextPage();
}

function webViewerPreviousPage() {
  PDFViewerApplication.pdfViewer.previousPage();
}

function webViewerZoomIn() {
  PDFViewerApplication.pdfViewer.zoomIn();
}

function webViewerZoomOut() {
  PDFViewerApplication.pdfViewer.zoomOut();
}

function load_settings_tool(){
  var element_setting = document.getElementById('json_setting');
  if(element_setting){
    var settings = document.getElementById('json_setting').value;
    const tools = JSON.parse(settings);
    setAppConfig(tools);
    element_setting.remove();
  }
  
}

function setAppConfig(tools){
  for (let index = 0; index < tools.length; index++) {
    const element = tools[index];    
    if(!element.settings) {
      continue;
    }
    switch(element.tool_type)
    {
      case 10:
        Config.shape.line = map2Objects(Config.shape.line,element.settings);
        break;
      case 11:
          Config.shape.arrow = map2Objects(Config.shape.arrow,element.settings);
          break;
      case 12:
          Config.shape.ellipse = map2Objects(Config.shape.ellipse,element.settings);
          break;
      case 13:
          Config.shape.rectangle = map2Objects(Config.shape.rectangle,element.settings);
          break;
      case 14:
         Config.form.xmark = map2Objects(Config.form.xmark,element.settings);
          break;
      case 15:
         Config.form.checkmark = map2Objects(Config.form.checkmark,element.settings);
          break;
      case 16:
         Config.form.dot = map2Objects(Config.form.dot,element.settings);
          break;
      case 17:
          // image not supported
          break;
      case 18:
         Config.stamp = map2Objects(Config.stamp,element.settings);
          break;
      case 19:
         Config.link = map2Objects(Config.link,element.settings);
          break;
      case 20:
         Config.text.addtext = map2Objects(Config.text.addtext,element.settings);
          break;
      case 21:
          Config.text.highlight = map2Objects(Config.text.highlight,element.settings);        
          break;
      case 22:
         Config.text.strokeout = map2Objects(Config.text.strokeout,element.settings);
          break;
      case 23:
         Config.text.underline = map2Objects(Config.text.underline,element.settings);
          break;
      case 30:
         Config.form.textfield = map2Objects(Config.form.textfield,element.settings);
          break;
      case 31:
         Config.form.textarea = map2Objects(Config.form.textarea,element.settings);
          break;
      case 32:
         Config.form.radio = map2Objects(Config.form.radio,element.settings);
          break;
      case 33:
         Config.form.checkbox = map2Objects(Config.form.checkbox,element.settings);
          break;
      case 34:
         Config.form.dropdown = map2Objects(Config.form.dropdown,element.settings);
          break;
      case 40:
         Config.freedraw = map2Objects(Config.freedraw,element.settings);
          break;
      case 41:
         Config.pencil = map2Objects(Config.pencil,element.settings);
          break;
      case 42:
          // not suppport erase
          break;
    }
  } 
}

function map2Objects(config,setting){
  var result = Object.keys(config).reduce((r,k) => {
    if(setting[k]){
      r[k] = setting[k];
    }
    else{
      r[k] = config[k];
    }
   
    return r;
  },{});
  return result;
} 
function setProperties(v,) {
  if(v_setting){
    v = v_setting
  }
}
function set_setting_to_controls(){  
  set_color_to_control(el('btnDrawArrow'),Config.shape.arrow.color);
  set_color_to_control(el('btnPen'),Config.freedraw.color);
  set_color_to_control(el('btnfree_draw'),Config.pencil.color);
  set_color_to_control(el('draw_line'),Config.shape.line.backGroundColor);
  set_color_to_control(el('draw_rectangle'),Config.shape.rectangle.stroke);
  set_color_to_control(el('btnAddText'),Config.text.addtext.fill);
  set_color_to_control(el('draw_ellipse'),Config.shape.ellipse.stroke);
  set_color_to_control(el('btnMarkX'),Config.form.xmark.color);
  set_color_to_control(el('btnMarkV'),Config.form.checkmark.color);
  set_color_to_control(el('btnMarkO'),Config.form.dot.color);
  set_color_to_control(el('btnHighLightText'),Config.text.highlight.color);
  set_color_to_control(el('btnStrikeOutText'),Config.text.strokeout.color);
  set_color_to_control(el('btnUnderLineText'),Config.text.underline.color);
  set_color_to_control(el('btnFormTextBox'),Config.form.textfield.color);
  set_color_to_control(el('btnFormTextArea'),Config.form.textarea.color);
  set_color_to_control(el('btnFormRadio'),Config.form.radio.color);
  set_color_to_control(el('btnFormCheckBox'),Config.form.checkbox.color);
  set_color_to_control(el('btnFormDropdown'),Config.form.dropdown.color);

}
function set_setting_to_toolbarSecondary(){  
  set_color_to_control(el('btnDrawArrow2'),Config.shape.arrow.color);
  set_color_to_control(el('btnPen2'),Config.freedraw.color);
  set_color_to_control(el('btnfree_draw2'),Config.pencil.color);
  set_color_to_control(el('draw_line2'),Config.shape.line.backGroundColor);
  set_color_to_control(el('draw_rectangle2'),Config.shape.rectangle.stroke);
  set_color_to_control(el('btnAddText2'),Config.text.addtext.fill);
  set_color_to_control(el('draw_ellipse2'),Config.shape.ellipse.stroke);
  set_color_to_control(el('btnMarkX2'),Config.form.xmark.color);
  set_color_to_control(el('btnMarkV2'),Config.form.checkmark.color);
  set_color_to_control(el('btnMarkO2'),Config.form.dot.color);
  set_color_to_control(el('btnHighLightText2'),Config.text.highlight.color);
  set_color_to_control(el('btnStrikeOutText2'),Config.text.strokeout.color);
  set_color_to_control(el('btnUnderLineText2'),Config.text.underline.color);
  set_color_to_control(el('btnFormTextBox2'),Config.form.textfield.color);
  set_color_to_control(el('btnFormTextArea2'),Config.form.textarea.color);
  set_color_to_control(el('btnFormRadio2'),Config.form.radio.color);
  set_color_to_control(el('btnFormCheckBox2'),Config.form.checkbox.color);
  set_color_to_control(el('btnFormDropdown2'),Config.form.dropdown.color);

}

function set_color_to_control(control, color){
  if(control){
      var i = control.querySelector('i');
      i.style.color = color;
  }
}