import {save_permission_type_2} from '../packs/permission';
$(document).on('turbo:load', function() {

  // event keyup Enter submit form
  $("body").delegate('#bulletin_category_background_color', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnSaveBulletinCategory").click();
    }
  });

	$("body").delegate('#bulletin_category_form', 'submit', function(event) {
    const submitButton = document.getElementById("btnSaveBulletinCategory");
    if (submitButton.disabled) {
      event.preventDefault();
      return;
    }
    submitButton.disabled = true;
    setTimeout(() => { submitButton.disabled = false; }, 1000);
  });

  $("body").delegate('#modal-bulletin-category-confirm', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateIndexBulletinCategories").click();
    }
  });

  $("body").delegate('#modal-permission-bulletin-category', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateBulletinPermissionCategory").click();
    }
  });

	$("#text_default_check_comment").click(function() {
		document.getElementById("bulletin_general_default_check_comment").click();
		$("#bulletin_general_default_check_comment").focus();
	});	
	$("body").delegate('#add_department', 'click', function() {
		var lst_department_select = document.getElementById('list_bulletin_department');
		var lst_department_selected = document.getElementById('bulletin_department_selected');
    
      moveDepartment(lst_department_select, lst_department_selected);
    
	});

	$("body").delegate('#remove_department', 'click', function() {
		var lst_department_select = document.getElementById('list_bulletin_department');
		var lst_department_selected = document.getElementById('bulletin_department_selected');
    moveDepartment(lst_department_selected, lst_department_select);
	});

	// handle click row table bulletin category
	// 	var lastCheckedBulletinCategory = null;
	// 	$("body").delegate('.tbl_bulletin_category tbody tr', 'click', function () {
	// 		if(!lastCheckedBulletinCategory) {
    //     lastCheckedBulletinCategory = this;
    //     // return;
    //   }

	//     if (window.event.ctrlKey) {
	//       this.classList.toggle("selected");
	//     }
	//     else if (window.event.shiftKey) {
	//       var start = $('.tbl_bulletin_category tbody tr').index(this);
	//       var end = $('.tbl_bulletin_category tbody tr').index(lastCheckedBulletinCategory);
	//       $('.tbl_bulletin_category tbody tr').slice(Math.min(start,end), Math.max(start,end)+ 1).addClass('selected');
	//       // console.log("a");
	//     }
	//     else {
	//       $(this).toggleClass("selected").siblings().removeClass('selected');
	//     }
	//     lastCheckedBulletinCategory = this;
	//   });

	// handle click button delete bulletin category
		$("#btnDeleteBulletinCategory").click(function() {
			var arr_bulletin_category = [],id;

			$.each($('.tbl_bulletin_category tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.getAttribute('data-item');
							arr_bulletin_category.push(id);
					}   
			});

			if (arr_bulletin_category.length > 0) {
				$.ajax ({
					type: 'GET',
					url: "confirm_destroy_bulletin_category",
					dataType: 'script',
					data: {
						id: arr_bulletin_category
					},
					success: function(data, textStatus, jqXHR){
						// 
					},
					error:function(jqXHR, textStatus, errorThrown){
						console.log("AJAX Error");
					}
				});
			}	
		});

	$("body").delegate('#btnMoveTopBulletinCategory', 'click', function () {
		var count_category = 0;
        $.each($('#tblBulletinCategoriesList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var first_elem = $("#tblBulletinCategoriesList option:first");
			if(first_elem.is(':selected')) return true;
			while(!first_elem.prev().length){
				$('#tblBulletinCategoriesList select :selected').each(function (i, selected) {
					if (!$(this).prev().length) return false;
					$(this).insertBefore($(this).prev());
				});
				$('#tblBulletinCategoriesList select').focus().blur();
			}	
		}
	});

	$("body").delegate('#btnMoveUpBulletinCategory', 'click', function () {
		$('#tblBulletinCategoriesList select :selected').each(function (i, selected) {
			if (!$(this).prev().length) return false;
			$(this).insertBefore($(this).prev());
		});
		$('#tblBulletinCategoriesList select').focus().blur();
	});

	$("body").delegate('#btnMoveDownBulletinCategory', 'click', function () {
		$($('#tblBulletinCategoriesList select :selected').get().reverse()).each(function (i, selected) {
			if (!$(this).next().length) return false;
			$(this).insertAfter($(this).next());
		});
		$('#tblBulletinCategoriesList select').focus().blur();
	});

	$("body").delegate('#btnMoveBottomBulletinCategory', 'click', function () {	
		var count_category = 0;
        $.each($('#tblBulletinCategoriesList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var last_elem = $("#tblBulletinCategoriesList option:last");
			if(last_elem.is(':selected')) return true;
			while(!last_elem.next().length){
				$($('#tblBulletinCategoriesList select :selected').get().reverse()).each(function (i, selected) {
					if (!$(this).next().length) return false;
					$(this).insertAfter($(this).next());
				});
				$('#tblBulletinCategoriesList select').focus().blur();
			}
		}
	});

	// Update index table bulletin_category
	$("body").delegate('#btnUpdateIndexBulletinCategories', 'click', function () {
		var lst = [];
		$("#lstBulletinCategories option").each(function () {
			lst.push($(this).val());
		});
		$.ajax({
			type: 'GET',
			url: "/update_index_bulletin_categories",
			dataType: 'script',
			data: {
					lst_bulletin_categories: lst,
			},
			success: function (data, textStatus, jqXHR) {
					// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
					console.log("Error!")
			}
		});

		const submitButton = document.getElementById("btnUpdateIndexBulletinCategories");
    if (submitButton.disabled) {
      event.preventDefault();
      return;
    }
    submitButton.disabled = true;
    setTimeout(() => { submitButton.disabled = false; }, 1000);
	});

	$("body").delegate('.text_permission_type', 'click', function () {
		var text_permission_id = this.id;
		var rdo_permission_id = this.id.replace("text_permission", "permission");
		document.getElementById(rdo_permission_id).checked = true;
	});

	// Change permission category
	$("body").delegate('#add_permission_department', 'click', function () {
    addBulletinPermissionDepartment();
  });

  $("body").delegate('#remove_permission_department', 'click', function () {
    removeBulletinPermissionDepartment();
  });

  // click row change color
  $("body").delegate('.td_bulletin_department_name', 'click', function () {
    $(this).toggleClass("bulletin_department_name");
    $(this).toggleClass("bulletin_row_selected");
  });

  // Update permission bulletin category department
  $("body").delegate('#btnUpdateBulletinPermissionCategory', 'click', function () {
	var id = document.getElementById("bulletin_category_id").innerHTML;
    save_permission_type_2("update_bulletin_permission_category",id,"bulletin_category");

	const submitButton = document.getElementById("btnUpdateBulletinPermissionCategory");
    if (submitButton.disabled) {
      event.preventDefault();
      return;
    }
    submitButton.disabled = true;
    setTimeout(() => { submitButton.disabled = false; }, 1000);
  });


});

function moveDepartment(selectDepartmentFrom, selectDepartmentTo) {
	$(selectDepartmentFrom).find('option:selected').detach().prop("selected", false).appendTo($(selectDepartmentTo));
}

function addBulletinPermissionDepartment() {
  var switch_department_item = $("#lst_bulletin_department_select").val();
  if (switch_department_item.length > 0) {
    var selected = document.getElementById('tbl_bulletin_department_selected');
    var current_department_item = []
    if (selected.getElementsByTagName("tr").length > 0) {
      for (let tr of selected.getElementsByTagName("tr")) {
        var row_id = parseInt(tr.getElementsByTagName("td")[0].textContent);
        var row_name = tr.getElementsByTagName("td")[1].textContent;
        var row_permission_type = parseInt(tr.querySelector('input[name="permission_type_' + row_id + '"]:checked').value);
       	current_department_item.push({id:row_id, name:row_name, permission_type:row_permission_type});
      }
    }
    // else {
    //   current_department_item.push(false);
    // }

    var bulletin_category_id = $("#bulletin_category_id")[0].textContent;
    // dung ajax gui id len controller, controller se lay object tơơng ung voi id
    $.ajax({
      type: 'GET',
      url: "/add_bulletin_permission_category",
      dataType: 'script',
      data: {
        bulletin_category_id: bulletin_category_id,
        current_department: current_department_item,
        switch_department: switch_department_item
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error!")
      }
    });
  }

  // tao js.erb 
}

function removeBulletinPermissionDepartment() {
	var check_action = $(".bulletin_row_selected").length
  if (check_action > 0) {
    var selected = $('.bulletin_department_name');
    var current_department_item = []
    if (selected.length > 0) {
      for (let td of selected) {
        var tr = td.parentNode
        var row_id = parseInt(tr.getElementsByTagName("td")[0].textContent);
        var row_name = tr.getElementsByTagName("td")[1].textContent;
        var row_permission_type = parseInt(tr.querySelector('input[name="permission_type_' + row_id + '"]:checked').value);
        current_department_item.push({id:row_id, name:row_name, permission_type:row_permission_type});
      }
    }
    // else {
    //   current_department_item.push(false);
    // }

    $.ajax({
      type: 'GET',
      url: "remove_bulletin_permission_category",
      dataType: 'script',
      data: {
        current_department: current_department_item
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }
}
