import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
// import { Sortable, AutoScroll } from "sortablejs";

$(document).on("turbo:load", function () {
  $("body").delegate('.js_mark_favorite_master_plan', 'click', function () {
    $(this).toggleClass('hightlighted');
    let master_plan_id = $(this).parents()[1].getAttribute("data-master_plan-id");
    let favorite = 0;
    if ($(this).hasClass('hightlighted')) {
      favorite = 1
    }

    $.ajax({
      url: "/project_categories/mark_favorite_master_plan",
      method: "GET",
      type: "script",
      data: {
        master_plan_id: master_plan_id,
        favorite: favorite
      },
      success: function (response) {},
      error: function (xhr, status, error) {
        console.log(error);
      },
    });
  });


  window.sorttabelProjectCategory = function (params) {
    $("#sortable").sortable();

    // $("#sort_modal").delegate('.project_category_item', 'click', function () {
    //   if(this.classList.contains("selected_item")){
    //     $(this).removeClass("selected_item")
    //   }
    //   else{
    //     $(this).addClass("selected_item")
    //   }
    // });

    $("#sort_modal").delegate('#btn_update_order', 'click', function () {
      var ids = [];
      var lis = document.querySelectorAll('#sortable li');
      for (let index = 0; index < lis.length; index++) {
        ids.push(parseInt(lis[index].getAttribute("id")));
      }
      $.ajax({
        type:"PATCH",
        url:"/project_categories/update_order",
        dataType:"script",
        data: {
          folders_ids: ids
        },
        success: function(data, textStatus, jqXHR){

        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR.responseText)
        }
      })
    })
  }

  // initReportFilter();
  // $("#collapse_all_project").click(function () {
  //   if ($(this).hasClass("collapsed")) {
  //     $(this).toggleClass("expanded");
  //   } else {
  //     $(this).toggleClass("expanded");
  //   }
  // });



  // $("#collapse_trash_project").click(function () {
  //   if ($(this).hasClass("collapsed")) {
  //     $(this).toggleClass("expanded");
  //   } else {
  //     $(this).toggleClass("expanded");
  //   }
  // });

  // sort folder
  // $("body").delegate("li.project_category-li", "click", function () {
  //   $("ul#order_project_category li.selected-project_category").removeClass(
  //     "selected-project_category"
  //   );
  //   $(this).addClass("selected-project_category");
  // });

  load_dashboard_project_category();
  // drag_drop_project_category_by_sortable();

  $("body").delegate("#add_object_to_project_category", "click", function () {
    let lst_object_select = document.getElementById(
      "list_object_for_project_category"
    );
    let lst_object_selected = document.getElementById(
      "list_object_in_project_category"
    );

    let list_department_select = [];

    $(lst_object_select)
      .find("option:selected")
      .each(function () {
        list_department_select.push([
          $(this).val(),
          $(this).attr("data-object-type"),
        ]);
      });

    $.each(list_department_select, function (key, value) {
      if (
        $(lst_object_selected).find(
          `option[value=${value[0]}][data-object-type="${value[1]}"]`
        ).length < 1
      ) {
        let option = $(lst_object_select)
          .find(`option[value=${value[0]}][data-object-type="${value[1]}"]`)
          .prop("selected", false)
          .clone();
        $(option).appendTo($(lst_object_selected));
      }
    });
  });

  $("body").delegate(
    "#remove_object_to_project_category",
    "click",
    function () {
      let lst_object_selected = $("#list_object_in_project_category").find(
        "option:selected"
      );
      $.each(lst_object_selected, function () {
        $(this).remove();
      });
    }
  );

  $("body").delegate(
    "#list_department_for_project_category",
    "change",
    function () {
      $.ajax({
        url: "/project_categories/load_member",
        method: "GET",
        data: {
          object_id: $(this).val(),
          data_type: $(this).find("option:selected").attr("data-object-type"),
        },
        success: function (response) {},
        error: function (xhr, status, error) {
          console.log(error);
        },
      });
    }
  );

  $("body").delegate(
    "#btn_save_member_for_project_category",
    "click",
    function () {
      let members = [];

      $.each($("#list_object_in_project_category").find("option"), function () {
        members.push({
          object_id: $(this).val(),
          permission_object: $(this).attr("data-object-type"),
        });
      });

      $.ajax({
        url: "/project_categories/" + $(this).data("item-id") + "/share_folder",
        method: "GET",
        data: {
          members: members,
        },
        success: function (response) {},
        error: function (xhr, status, error) {
          console.log(error);
        },
      });
    }
  );

  $("body").delegate("#filter-report-by-date", "click", function () {
    let start_date = $("#report_start_date").val();
    let end_date = $("#report_end_date").val();
    let project_category_id = $(".filter-date-report").data(
      "project-category-id"
    );
    let department_ids = $(".filter_users_in_department").val();
    FilterUsersReport(
      start_date,
      end_date,
      project_category_id,
      department_ids
    );
  });

  $("#nav_all_project_category").delegate(
    "#filter_project_category",
    "click",
    function () {
      $("#q_customer_id_eq").select2({
        dropdownParent: $("#master_plan_search"),
      });

      $("#q_status_id_eq").select2({
        dropdownParent: $("#master_plan_search"),
      });
    }
  );
});

function FilterUsersReport(
  start_date,
  end_date,
  project_category_id,
  department_ids
) {
  $.ajax({
    url: "/project_categories/filter_users_report",
    method: "GET",
    data: {
      filter_options: {
        start_date: start_date,
        end_date: end_date,
        project_category_id: project_category_id,
        department_ids: department_ids,
      },
    },
    success: function (response) {},
    error: function (xhr, status, error) {
      console.log(error);
    },
  });
}

window.initReportFilter = function() {
  if ($(".filter_users_in_department").length > 0) {
    $(this).removeClass("d-none");
  }

  $(".filter_users_in_department").each(function () {
    $(this)
      .select2({
        placeholder: I18n.t("project_categories.report.all_department"),
      })
      .on("change", function (e) {
        if (
          $(".filter-department-by-user").find("li.select2-selection__choice")
            .length == 0
        ) {
          $(".filter-department-by-user .select2-selection__rendered").attr(
            "style",
            "display: none !important"
          );
        } else {
          $(".filter-department-by-user .select2-selection__rendered").attr(
            "style",
            "display: inline !important"
          );
        }

        let start_date = $("#report_start_date").val();
        let end_date = $("#report_end_date").val();
        let project_category_id = $(".filter-date-report").data(
          "project-category-id"
        );
        let department_ids = $(".filter_users_in_department").val();
        FilterUsersReport(
          start_date,
          end_date,
          project_category_id,
          department_ids
        );
      });
  });

  $("#report_start_date").parent().on( "click", function() {
    $("#report_start_date").datepicker({
      format: formatDateByLanguage(get_locate()),
      autoclose: true,
    }).focus()
  });

  $("#report_end_date").parent().on( "click", function() {
    $("#report_end_date").datepicker(
      {
        format: formatDateByLanguage(get_locate()),
        autoclose: true,
      }
    ).focus()
  });
}

// window.drag_drop_project_category_by_sortable = function () {
//   $(".folder_project_active").each(function () {
//     Sortable.create($(this)[0], {
//       animation: 150,
//       scroll: true,
//       forceFallback: true,
//       scrollSensitivity: 200,
//       scrollSpeed: 20,
//       filter: ".more_project_category",
//       ghostClass: "sortable-hide",
//       onStart: function (evt) {
//         $(".folder_project_active .sortable-chosen .text-truncate img")
//           .last()
//           .addClass("d-none");
//       },
//       onEnd: function (evt) {
//         let folders = [];
//         $.each($(".folder_project_active").find(".nav-link"), function () {
//           folders.push($(this).data("id"));
//         });

//         $.ajax({
//           url: "/project_categories/set_order",
//           method: "GET",
//           data: {
//             current_id: $(".folder_project_active .active").data("id"),
//             folders_ids: folders,
//           },
//           success: function (response) {
//             console.log(response);
//           },
//           error: function (xhr, status, error) {
//             console.log(error);
//           },
//         });
//       },
//     });
//   });
// };

window.load_dashboard_project_category = function () {
  if ($("#gon_data_dashboard").length > 0) {
    let data_task = gon.data_task;
    let data_progress = gon.data_progress;
    let data_time = gon.data_time;
    let data_workload = gon.data_workload;
    var style = getComputedStyle(document.body);
    let fontColor = style.getPropertyValue("--ct-body-color");

    const configTimeChart = {
      type: "bar",
      data: {
        labels: truncateLabels(data_time[0], 10),
        datasets: [
          {
            maxBarThickness: 20,
            minBarLength: 2,
            data: data_time[1],
            backgroundColor: data_time[2],
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 15,
          },
        },
        scales: {
          x: {
            min: -100,
            max: 100,
            grid: {
              color: fontColor,
              lineWidth: 0.5,
            },
            ticks: {
              stepSize: 25,
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              color: fontColor,
            },
          },
        },
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            offset: (context) => {
              const value = context.dataset.data[context.dataIndex];
              if (value < 0) {
                return "0";
              } else if (value < 10) {
                return "-26";
              } else if (value < 100) {
                return "-33";
              } else {
                return "-40";
              }
            },
            anchor: (context) => {
              const value = context.dataset.data[context.dataIndex];
              return value > 0 ? "start" : "end";
            },
            align: "end",
            color: fontColor,
            formatter: (value) => {
              return value === null ? "0%" : `${value}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    };
    const configTimeChartScaleY = {
      type: "bar",
      data,
      options: {
        layout: {
          padding: 2,
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            afterFit: (context) => {
              context.height += 30;
            },
            beginAtZero: true,
            min: -100,
            max: 100,
            ticks: {
              stepSize: 25,
              color: fontColor,
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            afterFit: (context) => {
              context.width += timeChartProjectCategory.chartArea.left - 10;
            },
          },
        },
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };

    const configTimeChartLabel = {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          legend: {
            position: "bottom",
            maxHeight: 50,
            onClick: () => {},
            labels: {
              usePointStyle: true,
              boxWidth: 8,
              boxHeight: 8,
              padding: 26,
              generateLabels: (chart) => {
                let labels = [
                  I18n.t("project_manager.dashboards.ahead"),
                  I18n.t("project_manager.dashboards.on_time"),
                  I18n.t("project_manager.dashboards.behind"),
                ];
                let colors = data_time[3];
                return labels.map((label, index) => ({
                  text: labels[index],
                  fontColor: colors[index],
                  fillStyle: colors[index],
                  strokeStyle: colors[index],
                }));
              },
            },
          },
        },
      },
    };

    window.timeChartProjectCategory = new Chart(
      $("#chart_time_project_category"),
      configTimeChart
    );
    window.timeChartProjectCategoryModal = new Chart(
      $("#chart_time_project_category_modal"),
      configTimeChart
    );
    window.timeChartProjectCategoryScaleY = new Chart(
      $("#chart_time_project_category_scaleY"),
      configTimeChartScaleY
    );
    window.timeChartProjectCategoryScaleYModal = new Chart(
      $("#chart_time_project_category_scaleY_modal"),
      configTimeChartScaleY
    );
    window.timeChartProjectCategoryLabel = new Chart(
      $("#chart_time_project_category_label"),
      configTimeChartLabel
    );
    window.timeChartProjectCategoryLabelModal = new Chart(
      $("#chart_time_project_category_label_modal"),
      configTimeChartLabel
    );

    if (configTimeChart.data.labels.length > 7) {
      let newHeight = 320 + (configTimeChart.data.labels.length - 7) * 20;
      $(".height-chart-time")[0].style.height = `${newHeight}px`;

      let newHeightModal = 320 + (configTimeChart.data.labels.length - 7) * 30;
      $(".height-chart-time-modal")[0].style.height = `${newHeightModal}px`;
    }

    const data = {
      labels: [
        I18n.t("project_manager.dashboards.not_started"),
        I18n.t("project_manager.dashboards.complete"),
        I18n.t("project_manager.dashboards.in_progress"),
      ],
      datasets: [
        {
          data: data_task[0],
          backgroundColor: data_task[1],
          borderColor: data_task[1],
          borderWidth: 1,
          cutout: "75%",
        },
      ],
    };
    const customDatalabels = {
      id: "customDatalabels",
      afterDatasetsDraw(chart, args, pluginOptions) {
        const {
          ctx,
          data,
          chartArea: { top, bottom, left, right, width, height },
        } = chart;

        ctx.save();
        const haftWidth = width / 2 + left;
        const haftHeight = height / 2 + top;

        data.datasets[0].data.forEach((datapoint, index) => {
          if (datapoint > 0) {
            const { x, y } = chart
              .getDatasetMeta(0)
              .data[index].tooltipPosition();
            ctx.font = "bold 12px sans-serif";
            ctx.fillStyle = data.datasets[0].borderColor[index];
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            const xLine = x >= haftWidth ? x + 15 : x - 15;
            const yLine = y >= haftHeight ? y + 25 : y - 25;
            const extraLine = x >= haftWidth ? 10 : -10;
            const textWidth =
              x >= haftWidth
                ? ctx.measureText(datapoint).width
                : -ctx.measureText(datapoint).width;

            ctx.strokeStyle = data.datasets[0].borderColor[index];
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(xLine, yLine);
            ctx.lineTo(xLine + extraLine, yLine);
            ctx.stroke();
            ctx.fillText(datapoint, xLine + extraLine + textWidth, yLine);
          }
        });

        ctx.restore();
      },
    };
    // config
    const configTaskChart = {
      type: "doughnut",
      data,
      options: {
        layout: {
          padding: {
            top: 6,
          },
        },
        radius: "80%",
        aspectRatio: 1,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 8,
              boxHeight: 8,
              padding: 20,
              generateLabels: (chart) => {
                var labels = chart.data.labels;
                var colors = chart.data.datasets[0].backgroundColor;
                return chart.data.labels.map((label, index) => ({
                  text: labels[index],
                  fontColor: colors[index],
                  fillStyle: colors[index],
                  strokeStyle: colors[index],
                }));
              },
            },
          },
        },
      },
      plugins: [customDatalabels],
    };
    window.taskChartProjectCategory = new Chart(
      $("#chart_task_project_category"),
      configTaskChart
    );
    window.taskChartProjectCategoryModal = new Chart(
      $("#chart_task_project_category_modal"),
      configTaskChart
    );

    const configProgressChart = {
      type: "bar",
      data: {
        labels: truncateLabels(data_progress[0], 10),
        datasets: [
          {
            maxBarThickness: 18,
            data: data_progress[1],
            backgroundColor: data_progress[2],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 12,
            top: 26,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            offset: (context) => {
              const value = context.dataset.data[context.dataIndex];
              if (value < 0) {
                return "0";
              } else if (value < 10) {
                return "-26";
              } else if (value < 100) {
                return "-33";
              } else {
                return "-40";
              }
            },
            anchor: "start",
            align: "end",
            color: fontColor,
            formatter: (value) => {
              return value === null ? "0%" : `${value}%`;
            },
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              color: fontColor,
            },
          },
          x: {
            max: 100,
            min: -15,
            display: false,
          },
        },
        indexAxis: "y",
      },
      plugins: [ChartDataLabels],
    };
    window.progressChartProjectCategory = new Chart(
      $("#chart_progress_project_category"),
      configProgressChart
    );
    window.progressChartProjectCategoryModal = new Chart(
      $("#chart_progress_project_category_modal"),
      configProgressChart
    );

    if (configProgressChart.data.labels.length > 7) {
      let newHeight = 320 + (configProgressChart.data.labels.length - 7) * 20;
      $(".height-chart-progress")[0].style.height = `${newHeight}px`;

      let newHeightModal =
        320 + (configProgressChart.data.labels.length - 7) * 30;
      $(".height-chart-progress-modal")[0].style.height = `${newHeightModal}px`;
    }

    const configWorkloadChart = {
      type: "bar",
      data: {
        labels: truncateLabels(data_workload[0], 10),
        datasets: [
          {
            label: I18n.t("project_manager.dashboards.completed"),
            maxBarThickness: 18,
            data: data_workload[1][0],
            backgroundColor: data_workload[2][0],
          },
          {
            label: I18n.t("project_manager.dashboards.remaining"),
            maxBarThickness: 18,
            data: data_workload[1][1],
            backgroundColor: data_workload[2][1],
          },
          {
            label: I18n.t("project_manager.dashboards.overdue"),
            maxBarThickness: 18,
            data: data_workload[1][2],
            backgroundColor: data_workload[2][2],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 12,
          },
        },
        barPercentage: 0.4,
        categoryPercentage: 1,
        radius: 100,
        aspectRatio: 1,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              color: fontColor,
            },
          },
          x: {
            stacked: true,
            min: 0,
            grid: {
              color: fontColor,
              lineWidth: 0.5,
            },
            border: {
              color: fontColor,
              width: 0.5,
            },
            ticks: {
              display: false,
              stepSize: 4,
            },
          },
        },
        indexAxis: "y",
      },
      plugins: [ChartDataLabels],
    };
    const configWorkloadChartLabel = {
      type: "bar",
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 12,
          },
        },
        barPercentage: 0.4,
        categoryPercentage: 1,
        radius: 100,
        aspectRatio: 1,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            position: "bottom",
            maxHeight: 50,
            labels: {
              usePointStyle: true,
              boxWidth: 8,
              boxHeight: 8,
              padding: 26,
              generateLabels: (chart) => {
                let labels = [
                  I18n.t("project_manager.dashboards.completed"),
                  I18n.t("project_manager.dashboards.remaining"),
                  I18n.t("project_manager.dashboards.overdue"),
                ];
                let colors = data_workload[2];
                return labels.map((label, index) => ({
                  text: labels[index],
                  fontColor: colors[index],
                  fillStyle: colors[index],
                  strokeStyle: colors[index],
                }));
              },
            },
            title: {
              padding: 10,
            },
          },
        },
        indexAxis: "y",
      },
      plugins: [ChartDataLabels],
    };

    const configWorkloadChartScaleY = {
      type: "bar",
      data: {
        labels: data_workload[0].map(() => ""),
        datasets: [
          {
            label: "Completed",
            maxBarThickness: 18,
            data: data_workload[1][0],
            backgroundColor: data_workload[2][0],
          },
          {
            label: "Remaining",
            maxBarThickness: 18,
            data: data_workload[1][1],
            backgroundColor: data_workload[2][1],
          },
          {
            label: "Overdue",
            maxBarThickness: 18,
            data: data_workload[1][2],
            backgroundColor: data_workload[2][2],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 12,
          },
        },
        barPercentage: 0.4,
        categoryPercentage: 1,
        radius: 100,
        aspectRatio: 1,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              color: fontColor,
            },
            afterFit: (context) => {
              context.width += workloadChartProjectCategory.chartArea.left - 12;
            },
          },
          x: {
            stacked: true,
            min: 0,
            afterFit: (context) => {
              context.height += 30;
            },
            beginAtZero: true,
            border: {
              display: false,
            },
            ticks: {
              color: fontColor,
              stepSize: 4,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        indexAxis: "y",
      },
    };

    window.workloadChartProjectCategory = new Chart(
      $("#chart_workload_project_category"),
      configWorkloadChart
    );
    window.workloadChartProjectCategoryModal = new Chart(
      $("#chart_workload_project_category_modal"),
      configWorkloadChart
    );
    window.workloadChartProjectCategoryLabel = new Chart(
      $("#chart_workload_project_category_label"),
      configWorkloadChartLabel
    );
    window.workloadChartProjectCategoryLabelModal = new Chart(
      $("#chart_workload_project_category_label_modal"),
      configWorkloadChartLabel
    );
    window.workloadChartProjectCategoryScaleY = new Chart(
      $("#chart_workload_project_category_scaleY"),
      configWorkloadChartScaleY
    );
    window.workloadChartProjectCategoryScaleYModal = new Chart(
      $("#chart_workload_project_category_scaleY_modal"),
      configWorkloadChartScaleY
    );

    if (configWorkloadChart.data.labels.length > 7) {
      let newHeight = 320 + (configWorkloadChart.data.labels.length - 7) * 20;
      $(".height-chart-workload")[0].style.height = `${newHeight}px`;

      let newHeightModal =
        320 + (configWorkloadChart.data.labels.length - 7) * 30;
      $(".height-chart-workload-modal")[0].style.height = `${newHeightModal}px`;
    }
  }
};

function truncateLabels(labels, maxLength) {
  return labels.map((label) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    }
    return label;
  });
}
