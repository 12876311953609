// import 'quill-mention';
// import Quill from 'quill';
import { CustomEditorText } from "../libs/quill/custome_editor_text";

$(document).on('turbo:load', function() {
	// quill for comment schedule
	quillCommentSchedule("editor_comment_schedule","#comment_content");
	initButtonTitle();

  $("body").delegate('#btnSaveComment', 'click', function () {
    document.getElementById("btn_save_comment").click();
  });
});

window.quillCommentSchedule = function(element, input_element) {
	if ($("#" + element).length > 0) {
		var form = document.querySelector('.form-comment-schedule');

    var input = { 
      id: element,
      callbackMentionUrl: '/filter_mention_user',
      status: false
    }
    var text_Editor = new CustomEditorText(input)

    form.onsubmit = function() {
      var postContentInput = document.querySelector(input_element);
      postContentInput.value = text_Editor.value();

      var content_comment = $("#comment_content").val();
      var doc = new DOMParser().parseFromString(content_comment, "text/html");
      var list_mention_user = doc.getElementsByClassName("mention");
      if (list_mention_user.length > 0) {
        var arr_mention_user = [];
        $.each(list_mention_user, function(index, elem) {
          arr_mention_user.push($(elem).data("id"));
        });
        document.getElementById("comment_mention_user").value = arr_mention_user.toString();
      }
      text_Editor.reset();
    }

	}
}

// popover in toolbar quill js
window.initButtonTitle = function() {
  const titleConfig = [
    {
      Choice: ".ql-bold",
      title: I18n.t('quill_tooltip_toolbar.txt_bold') // "加粗"
    },
    {
      Choice: ".ql-italic",
      title: I18n.t('quill_tooltip_toolbar.txt_italic') // "斜体" 
    },
    {
      Choice: ".ql-underline", 
      title: I18n.t('quill_tooltip_toolbar.txt_underline') // "下划线"
    },
    {
      Choice: ".ql-header",
      title: I18n.t('quill_tooltip_toolbar.txt_header') //"段落格式" // 
    },
    {
      Choice: ".ql-strike",
      title: I18n.t('quill_tooltip_toolbar.txt_strike') //"删除线" // 
    },
    {
      Choice: ".ql-blockquote",
      title: I18n.t('quill_tooltip_toolbar.txt_blockquote') // "块引用" // 
    },
    {
      Choice: ".ql-code",
      title: I18n.t('quill_tooltip_toolbar.txt_code')  // "插入代码" //
    },
    {
      Choice: ".ql-code-block",
      title: I18n.t('quill_tooltip_toolbar.txt_code_block') // "插入代码段" //
    },
    {
      Choice: ".ql-font",
      title: I18n.t('quill_tooltip_toolbar.txt_font') // "字体" //
    },
    {
      Choice: ".ql-size",
      title: I18n.t('quill_tooltip_toolbar.txt_size') // "字体大小" //
    },
    {
      Choice: '.ql-list[value="ordered"]',
      title: I18n.t('quill_tooltip_toolbar.txt_list_value_order') // "编号列表" //
    },
    {
      Choice: '.ql-list[value="bullet"]',
      title: I18n.t('quill_tooltip_toolbar.txt_list_value_bullet') // "项目列表" //
    },
    {
      Choice: ".ql-direction",
      title: I18n.t('quill_tooltip_toolbar.txt_direction') // "文本方向" //
    },
    {
      Choice: '.ql-header[value="1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_header_value_1') // "h1" //
    },
    {
      Choice: '.ql-header[value="2"]',
      title: "h2" // I18n.t('quill_tooltip_toolbar.txt_header_value_2') //
    },
    {
      Choice: ".ql-align",
      title: I18n.t('quill_tooltip_toolbar.txt_align') // "对齐方式" //
    },
    {
      Choice: ".ql-color",
      title: I18n.t('quill_tooltip_toolbar.txt_color') // "字体颜色" //
    },
    {
      Choice: ".ql-background",
      title: I18n.t('quill_tooltip_toolbar.txt_background') // "背景颜色" //
    },
    {
      Choice: ".ql-image",
      title: I18n.t('quill_tooltip_toolbar.txt_image') // "图像" //
    },
    {
      Choice: ".ql-video",
      title: I18n.t('quill_tooltip_toolbar.txt_video') // "视频" //
    },
    {
      Choice: ".ql-link",
      title: I18n.t('quill_tooltip_toolbar.txt_link') // "添加链接" //
    },
    {
      Choice: ".ql-formula",
      title: I18n.t('quill_tooltip_toolbar.txt_formula') // "插入公式" //
    },
    {
      Choice: ".ql-clean",
      title: I18n.t('quill_tooltip_toolbar.txt_clean') // "清除字体格式" //
    },
    {
      Choice: '.ql-script[value="sub"]',
      title: I18n.t('quill_tooltip_toolbar.txt_script_sub') // "下标" //
    },
    {
      Choice: '.ql-script[value="super"]',
      title: I18n.t('quill_tooltip_toolbar.txt_script_super') // "上标" //
    },
    {
      Choice: '.ql-indent[value="-1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_indent_1') //"向左缩进" // 
    },
    {
      Choice: '.ql-indent[value="+1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_indent_sub_1') //"向右缩进" // 
    },
  ];
  for (let item of titleConfig) {
    if ($(item.Choice).length < 0) continue;
    $(item.Choice).attr("data-bs-toggle", "popover");
    $(item.Choice).attr("data-bs-placement", "top");
    $(item.Choice).attr("data-bs-trigger", "hover");
    $(item.Choice).attr("data-bs-content", item.title);
  }
  window.$('[data-bs-toggle="popover"]').popover();
}
