$(document).on('turbo:load', function() {
  $("#header_list_user").delegate('.user_filter_btn', 'click', function (event) {
    $("#filter_user_options").find(".user_filter_btn").removeClass("selected")
    $(this).addClass("selected")
    $("#filter_user_dropdown").addClass("active_filter")
    filter_user()
  });

  $("#header_list_user").delegate('.clear_filter_btn', 'click', function (event) {
    $("#filter_user_options").find(".user_filter_btn").removeClass("selected")
    $("#filter_user_dropdown").removeClass("active_filter")
    filter_user()
  });

  $("#header_list_user").delegate('#user_search_btn', 'click', function (event) {
    filter_user()
  });

  $("#header_list_user").delegate("#search_user_input", "keydown", function (event){
    if (event.keyCode == 13) {
      filter_user()
    }
  });

  function filter_user(params) {
    let text = $("#search_user_input").val(),
        status_val = ""

    if(text.trim().length > 0){
      $(".form_search_in_user").addClass("active_filter")
    }else {
      $(".form_search_in_user").removeClass("active_filter")
    }

    if ($("#filter_user_options").find(".user_filter_btn.selected").length > 0) {
      status_val = $("#filter_user_options").find(".user_filter_btn.selected").val()
    }

    $.ajax ({
      type: 'GET',
      url: "/users",
      dataType: 'script',
      data: {
        q: {
          fullname_or_ic_no_or_email_or_empl_code_cont: text,
          active_true: status_val
        }
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  }
})
