import { when } from "jquery";
import { Tree } from "../jstree/tree";

$(document).on("turbo:load", function () {
  $("#nav_all_project_category").on('click', '.nav-link-master_plan', function(e,data){
    let screen = this.getAttribute("data-screen")
    window.history.pushState("","",window.location.href.split("?")[0] + "?screen=" + screen);

    if (screen == "list"){
      $("#js_display_right_menu").removeClass("d-none")
    }else{
      $("#js_display_right_menu").addClass("d-none")
    }
  })

  if ($("#tree_view_category").length > 0) {
    initTreeDataFolder("tree_view_category", show_data);
    window.projectCategoryTree = $('#tree_view_category').jstree(true)
    $("#tree_view_category").on("loaded.jstree", function (e, data) {
      var selectedNode = el("tree_view_category").getAttribute("selected");
      $("#tree_view_category").jstree("select_node", selectedNode);
    });
  }

  $("#page_projects").delegate('li[dropdown_menu=true]', 'click', function (e) {
    var node_selected = $("#tree_view_category").jstree("get_selected");
    $("#tree_view_category").jstree("deselect_node", node_selected);
    $("#tree_view_category").jstree("select_node", e.target.id);

    if (e.offsetX > (this.offsetWidth - 20)) {
      node_selected = $("#tree_view_category").jstree("get_selected");
      $("#tree_view_category").jstree("show_contextmenu", node_selected);
    }
  });

  function initTreeDataFolder(tree_id, callbackSelectNode) {
    var elemtree = el(tree_id);
    if (elemtree) {
      var elemtreeData = document.getElementById("datajs");
      if (!elemtreeData) return;
      var datajs = elemtreeData.value;
      // datajs = datajs.replace(/'/g, '"');
      datajs = JSON.parse(datajs);
      var id = elemtree.getAttribute("selected");
      elemtreeData.remove();

      $("#tree_view_category").jstree({
        core: {
          check_callback: true,
          data: datajs,
        },
        plugins: ["contextmenu"],
        contextmenu: {
          items: function ($node) {
            var tree = $("#tree_view_category").jstree(true);
            return {
              create: {
                label: I18n.t("project_categories.list_project_category.new_folder"),
                title: I18n.t("project_categories.list_project_category.new_folder"),
                icon: "mdi mdi-plus",
                _disabled: function (e) {
                  return (e.reference.prevObject[0].getAttribute("id") != "all_project")
                },
                action: function (data) {
                  // $node = tree.create_node("all_project");
                  // tree.edit($node, null, function (the_node, rename_status) {
                  //   createNode(the_node);
                  // })
                },
              },

              rename: {
                label: I18n.t("project_categories.list_project_category.rename"),
                title: I18n.t("project_categories.list_project_category.rename"),
                _disabled: function (e) {
                  return (e.reference.prevObject[0].getAttribute("id") == "all_project")
                },
                icon: "mdi mdi-circle-edit-outline",
                action: function (data) {
                  var sel = tree.get_selected();
                  if(!sel.length) {
                    return false;
                  }
                  sel = sel[0];

                  $.ajax({
                    url: "/project_categories/" + sel + "/edit",
                    method: "GET",
                    dataType: "script",
                  })
                },

              },
              share: {
                label: I18n.t("project_categories.list_project_category.share"),
                title: I18n.t("project_categories.list_project_category.share"),
                icon: "mdi mdi-share-variant",
                _disabled: function (e) {
                  return (e.reference.prevObject[0].getAttribute("id") == "all_project")
                },
                action: function (data) {
                  var sel = tree.get_selected();
                  if(!sel.length) {
                    return false;
                  }
                  sel = sel[0];
                  $.ajax({
                    url: "/project_categories/"+ sel +"/set_share_folder",
                    method: "GET",
                    dataType: "script",
                    success: function (response) {

                    },
                    error: function (xhr, status, error) {
                      console.log(error);
                    },
                  });
                },
              },
              delete: {
                label: I18n.t("project_categories.list_project_category.delete"),
                title: I18n.t("project_categories.list_project_category.delete"),
                icon: "mdi mdi-delete-outline",
                _disabled: function (e) {
                  return (e.reference.prevObject[0].getAttribute("id") == "all_project")
                },
                action: function (data) {
                  var sel = tree.get_selected();
                  tree.open_node("trash")
                  if(!sel.length) {
                    return false;
                  }
                  sel = sel[0];
                  tree.move_node(sel, "trash", "last")
                  var li = $("#tree_view_category").find(`li#${sel}`)[0];
                  if (li) {
                    li.scrollIntoViewIfNeeded({ behavior: "smooth" });
                  }

                  $.ajax({
                    url: "/project_categories/delete",
                    method: "GET",
                    dataType: "script",
                    data: {id: sel},
                    success: function (response) {

                    },
                    error: function (xhr, status, error) {
                      console.log(error);
                    },
                  });

                },
              },
              redo: {
                label: I18n.t("project_categories.list_project_category.restore"),
                title: I18n.t("project_categories.list_project_category.restore"),
                icon: "mdi mdi-backup-restore",
                action: function (data) {
                  var sel = tree.get_selected();
                  tree.open_node("all_project")
                  if(!sel.length) {
                    return false;
                  }
                  sel = sel[0];
                  tree.move_node(sel, "all_project", "last")
                  var li = $("#tree_view_category").find(`li#${sel}`)[0];
                  if (li) {
                    li.scrollIntoViewIfNeeded({ behavior: "smooth" });
                  }

                  $.ajax({
                    url: "/project_categories/restore",
                    method: "GET",
                    dataType: "script",
                    data: {id: sel},
                    success: function (response) {

                    },
                    error: function (xhr, status, error) {
                      console.log(error);
                    },
                  });
                },
              },
              destroy: {
                label: I18n.t("project_categories.list_project_category.delete"),
                title: I18n.t("project_categories.list_project_category.delete"),
                icon: "mdi mdi-delete-outline",
                action: function (data) {
                  var sel = tree.get_selected();

                  if(!sel.length) {
                    return false;
                  }
                  sel = sel[0];
                  // tree.delete_node(sel)
                  $.ajax({
                    url: "/project_categories/confirm_destroy",
                    method: "GET",
                    dataType: "script",
                    data: {id: sel},
                    success: function (response) {

                    },
                    error: function (xhr, status, error) {
                      console.log(error);
                    },
                  });
                },
              },
            }
          },
        },
      })
      .on("loaded.jstree", function (e, data) {
        loaded(this, e, data, id);
      })
      .on("select_node.jstree", function (e, data) {
        if (callbackSelectNode) {
          callbackSelectNode(data.node.id);
        }
      })
      .on("show_contextmenu.jstree", function (e, reference, element) {
        if (reference.node.id == "favorite" || reference.node.id == "trash" || reference.node.id == "all_project") {
          $(".vakata-context").hide();
          return
        }

        switch (reference.node.parent) {
          case "trash":
            for (let i = 0; i < 4; i++) {
              el = $(".vakata-context").find('li')[i]
              $(el).hide()
            }
            break;
          case "all_project":
            $($(".vakata-context").find('li')[0]).hide()
            for (let i = 4; i < 6; i++) {
              el = $(".vakata-context").find('li')[i]
              $(el).hide()
            }
            break;
          default:
            break;
        }
      });
    }
  }

  function createNode(the_node){
    $.ajax({
      url: "/project_categories/",
      method: "POST",
      dataType: "json",
      data: {
        project_category: {
          name: the_node.text,
        },
        old_id: the_node.id,
      },
      success: function (response) {
        $("#"+response.old_id+ '.jstree-node' ).next(".js_erorros_message").remove();
        // jstree-rename-input
        if(!response.status){
          $("#tree_view_category").jstree(true).edit(response.old_id);
          $("#"+response.old_id + '.jstree-node' ).after("<span class='ms-5 text-danger js_erorros_message'>" + response.message + "</span>")

          $("#all_project").delegate('.js_erorros_message', 'click', function(event) {
            $(this).remove();
          })
        } else {
          $('#tree_view_category').jstree(true).settings.core.data = JSON.parse( response.new_data);
          $("#tree_view_category").jstree(true).refresh();
          $("#tree_view_category").on("refresh.jstree", function (e, data) {
            loaded(this, e, data, response.object.id);
          });
        }

      },
      error: function (xhr, status, error) {
        console.log(error);
      },
    });
  }


  window.loaded = function(tree, e, data, id) {
    set_select_node(tree, id);
  }

  function set_select_node(tree, id = null) {
    $(tree).jstree().deselect_all(true);
    if (id) {
      $(tree).jstree("select_node", parseInt(id));
      $(tree).jstree("open_node", $(`#${id}`));
    } else {
      var childrens = $(tree).jstree("get_children_dom", "#");
      if (childrens.length > 0) {
        $(tree).jstree("select_node", childrens[0].id);
        $(tree).jstree("open_node", $(`#${childrens[0].id}`));
      }
    }
    var node_selected = $(tree).jstree("get_selected");
    var li = tree.querySelector(`li [id='${node_selected[0]}']`);
    if (li) {
      li.scrollIntoViewIfNeeded({ behavior: "smooth" });
    }
  }

  function show_data(id) {
    if (id) {
      // $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
      // $(`#${id}`).addClass("folder-mark-up");
      let screen = $("#center_project_category_menu_tab")
        .find(".nav-link-master_plan.active")
        .attr("data-screen");
      // if (typeof(screen) == "undefined"){
      //   screen = "list"
      // }
      $.ajax({
        type: "GET",
        url: "/project_categories/",
        dataType: "script",
        data: { id: id, screen: screen },
        success: function (data, textStatus, jqXHR) {},
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText);
        },
      });
      var currentUrl =
        window.origin + "/project_categories/" + id + "/?screen=" + screen;
      var url = new URL(currentUrl);
      history.pushState({}, "", currentUrl);
    }
  }
});
