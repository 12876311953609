$(document).on('turbo:load', function() {
	$("#btnDeleteJobType").click(function() {
		var arr_job_type = [];
		$.each($('.tbl_job_type tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_job_type.push($(row).data('item-id'));
			} 
		});

		if (arr_job_type.length > 0) {
			let page_value = $(".job-type-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_job_type",
				dataType: 'script',
				data: {
					id: arr_job_type,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

  $("body").delegate('#job_type_form', 'submit', function(event) {
    const submitButton = document.getElementById("btn_save_job_type");
    if (submitButton.disabled) {
      event.preventDefault();
      return;
    }
    submitButton.disabled = true;
    setTimeout(() => { submitButton.disabled = false; }, 1000);
  });

});
