import { fabricUltil } from "./fabric_helper";

export const fabric_detail_event = {
    mouse_down_before: fabricUltil.mouse_down_before,    
    mouseDown: fabricUltil.mouseDown,
    mouseMove: fabricUltil.mouseMove,
    mouseUp: fabricUltil.mouseUp,
    pathCreated: fabricUltil.pathCreated,
    objectModified: fabricUltil.objectModified,
    objectMoving: fabricUltil.objectMoving,
    objectSelected: fabricUltil.objectSelected,
    selectionCleared: fabricUltil.selectionCleared,
    fabric_mouse_over: fabricUltil.fabric_mouse_over
}

