import {PDFViewerApplication } from '..//../libs/pdf-editor/define/PDFViewerApplication';

import { PDFDownloadHelper } from '../../libs/pdf-editor/helper/pdf_download_helper';
import { CustomEditorText } from '../../libs/quill/custome_editor_text';
var JSZip = require("jszip");
$(document).on('turbo:render', function() {
	window.$(".modal").on("shown.bs.modal", function () {
		if ($(".modal-backdrop").length > 1) {
			$(".modal-backdrop").not(':first').remove();
		}
	})
 });

$(document).on('turbo:load', function() { 
	$("body").delegate('.auto-dowload-pdf', 'click', function(ev) {
		auto_download_pdf(this) ;
	})
	$("body").delegate('#btnDownloadMultiFile', 'click', function(ev) {
		auto_download_multi_pdf(this) ;
	})
	defineDropZone();
	$("body").delegate('.open-left-pdf', 'click', function() {
		if($('#left_bar_pdf').css('display')=="none")
		{
			$('#left_bar_pdf').css('display','block');
			$('#center_pdf').css('display','none');
			$(".slidecontainer").css("display","none");
			$('#right_bar_pdf').css('display','none');
			$(".group-size").css("display","none");
			
		}
		else{
			$('#left_bar_pdf').css('display','none');
			$('#center_pdf').css('display','block');
			$(".slidecontainer").css("display","block");
			$('#right_bar_pdf').css('display','none');

			if($(".dragme").length>0 && $('.open-left-pdf').css('display')=="block"){
				$(".group-size").css("display","block");
			}
		}

		if($('#open_left_pdf').attr("data-item")=="0"){
			$('#open_left_pdf').attr("data-item","1");
			$('#open_right_pdf').attr("data-item","0");

			$('.open-left-pdf').css('background-color','rgb(71, 77, 86)');
			$('.open-left-pdf').removeClass('button-pdf-mobile');
			$('.open-right-pdf').css('background-color','rgb(255, 255, 255)');

			$('.open-left-pdf').css('color','rgb(255, 255, 255)');
			$('.open-right-pdf').css('color','rgb(71, 77, 86)');
			
		}
		else{
			$('#open_left_pdf').attr("data-item","0");
			$('.open-left-pdf').addClass('button-pdf-mobile');
			$('.open-left-pdf').css('background-color','rgb(255, 255, 255)');
			$('.open-right-pdf').css('background-color','rgb(255, 255, 255)');

			$('.open-left-pdf').css('color','rgb(71, 77, 86)');
			$('.open-right-pdf').css('color','rgb(71, 77, 86)');
		}
		// change icon	
		// var $el = $(this);	
		// $el.find('i').toggleClass('dripicons-arrow-thin-right  dripicons-arrow-thin-left');
	})
	$("body").delegate('.open-right-pdf', 'click', function() {
		if($('#right_bar_pdf').css('display')=="none"){

			$('#left_bar_pdf').css('display','none');
			$('#center_pdf').css('display','none');
			$(".slidecontainer").css("display","none");
			$(".group-size").css("display","none");
			$('#right_bar_pdf').css('display','block');			
		}
		else{
			$('#left_bar_pdf').css('display','none');
			$('#center_pdf').css('display','block');	
			$(".slidecontainer").css("display","block");		
			$('#right_bar_pdf').css('display','none');

			if($(".dragme").length>0 && $('.open-left-pdf').css('display')=="block"){
				$(".group-size").css("display","block");
			}
			
		}

		if($('#open_right_pdf').attr("data-item")=="0"){
			$('#open_right_pdf').attr("data-item","1");
			$('#open_left_pdf').attr("data-item","0");
			$('.open-left-pdf').css('background-color','rgb(255, 255, 255)');
			$('.open-right-pdf').css('background-color','rgb(71, 77, 86)');
			$('.open-right-pdf').removeClass('button-pdf-mobile');
			$('.open-left-pdf').css('color','rgb(71, 77, 86)');
			$('.open-right-pdf').css('color','rgb(255, 255, 255)');
		}
		else{
			$('#open_right_pdf').attr("data-item","0");
			$('.open-right-pdf').addClass('button-pdf-mobile');
			$('.open-left-pdf').css('background-color','rgb(255, 255, 255)');
			$('.open-right-pdf').css('background-color','rgb(255, 255, 255)');
			$('.open-left-pdf').css('color','rgb(71, 77, 86)');
			$('.open-right-pdf').css('color','rgb(71, 77, 86)');
		}
		
		// var $el = $(this);	
		// $el.find('i').toggleClass(' dripicons-chevron-left  dripicons-chevron-right');
	})
	
	$("body").delegate('.disable-scrool-pdf', 'click', function() {	
		// var $el = $(this);	
		// $el.find('i').toggleClass(' dripicons-media-pause   dripicons-media-play');

		if($('#cex_inner_container').css('touch-action')=="none"){
			$('#cex_inner_container').css('touch-action','auto');
		}
		else{
			$('#cex_inner_container').css('touch-action','none');
		}
		if($('#open_scroll_pdf').attr("data-item")=="0"){
			$('#open_scroll_pdf').attr("data-item","1");
			$('.disable-scrool-pdf').css('background-color','rgb(71, 77, 86)');
			$('#open_scroll_pdf').removeClass('button-pdf-mobile');
			$('.disable-scrool-pdf').css('color','rgb(255, 255, 255)');
		}
		else{
			$('#open_scroll_pdf').attr("data-item","0");
			$('#open_scroll_pdf').addClass('button-pdf-mobile');
			$('.disable-scrool-pdf').css('background-color','rgb(255, 255, 255)');
			$('.disable-scrool-pdf').css('color','rgb(71, 77, 86)');
		}
	})

	$("body").delegate('#pdf_user_reject_true', 'click', function() {
		
		if(this.checked)
		{
			$("#comment_id").css("display","block");
			$("#share_id").css("display","none");
			set_status_button_reject_share();
		}
	});
	$("body").delegate('#pdf_user_reject_false', 'click', function() {
		if(this.checked)
		{
			$("#comment_id").css("display","none");
			$("#share_id").css("display","block");
			set_status_button_reject_share();
		}
	});
		
		// $("body").delegate('#user_shared_reject', 'change', function () {
		// 	set_status_button_reject_share();
		// });
		// $("body").delegate('#lst_department_reject', 'change', function () {
		// 	$.ajax({
		// 	  type: 'GET',
		// 	  url: "/pdf/filter_users_reject",
		// 	  dataType: 'script',
		// 	  data: {
		// 		department_id: $("#lst_department_reject option:selected").val()
		// 	  },
		// 	  success: function success(data, textStatus, jqXHR) {
		// 		;
		// 		set_status_button_reject_share() ;
		// 	  },
		// 	  error: function error(jqXHR, textStatus, errorThrown) {
		// 		console.log("AJAX Error");
		// 	  }
		// 	});
		//   });
		

	window.$('#modal_reject_pdf').on('hidden.bs.modal', function () {
		$(this).find('form').trigger('reset');
	})
	window.$('#modal_preview_file_pdf').on('hidden.bs.modal', function () {
		$(this).find('form').trigger('reset');
	})
	window.$('#modal_preview_file_pdf').on('shown.bs.modal', function () {
		var url_file = $('#path_file').val();	
		Show_file_pdf(url_file);		
		$('#btnFullScreenPreview').on('click', function(ev) {
			var modal = document.getElementById('modal_preview_file_pdf');
			var isFullScreen = this.getAttribute('full-screen');
			var el = document.querySelector('#content_modal_file_preview_pdf'); 
			if(isFullScreen == "true"){
				this.setAttribute("full-screen",false);
				closeFullscreen();
				modal.querySelector('.left_bar_doc').style.height = "calc(100vh - 100px)"
				modal.querySelector('.right_bar_doc').style.height = "calc(100vh - 100px)"
			}
			else{
			   
				el.webkitRequestFullscreen();
				this.setAttribute("full-screen",true);
				modal.querySelector('.left_bar_doc').style.height = "calc(100vh - 43px)"
				modal.querySelector('.right_bar_doc').style.height = "calc(100vh - 43px)"
				
			}
		   
		})   
	})

	
	
	function change_status()
	{		
		try {		

			if(currentPageIndex == 0)
			{
				document.getElementById("btnPrevPdf").disabled = true;
			}
			else{
				document.getElementById("btnPrevPdf").disabled = false;
			}
			if(currentPageIndex == _TOTAL_PAGES-1)
			{
				document.getElementById("btnNextPdf").disabled = true;
			}
			else{
				document.getElementById("btnNextPdf").disabled = false;
			}
		}
		catch(error) {
			console.log(error.message);
		}
	}
	
	
	async function Show_file_pdf(url){
		await open(url);	
	}
	
	async function open(url){		
		var viewComponent = getViewComponents();
		// PDFViewerApplication.set_mode(1);
		PDFViewerApplication.set_app_type(0);
		await PDFViewerApplication.initialize(null,viewComponent);
		await PDFViewerApplication.open(url);
	}
	
	function getViewComponents(){

		var modal = document.getElementById('modal_preview_file_pdf');
		var viewContainer = modal.querySelector('#viewer');
		var thumnailContainer = modal.querySelector('#page-navi');
		// var scroll = modal.querySelector('.right_bar_doc'); 
		const simpleBar = new SimpleBar(modal.querySelector('#pdf-viewer'));		
		var scroll = simpleBar.getScrollElement(); 
		return {
			toolbar:{
				firstpage:modal.querySelector('#btnFirstPage'),
				endpage:modal.querySelector('#btnEndPage'),
				previous: modal.querySelector('#btnPrevPage'),				
				next: modal.querySelector('#btnNextPage'),
				rotateleft: modal.querySelector('#btnRotateLeft'),
				rotateright: modal.querySelector('#btnRotateRight'),
				zoomIn: modal.querySelector('#btnZoomIn'),
				zoomOut: modal.querySelector('#btnZoomOut'),
				current_page: modal.querySelector('#current_page_value'),
				total_page: modal.querySelector('#total_page_value'),
				select_scale: modal.querySelector('#scaleSelect')
			},
			edittoolbar:{           
			},
			propertiesToolbar:{  
			},
			viewContainer:viewContainer,
			scrollContent: scroll,
			pageScroll: modal.querySelector('#pdf-viewer') ,
			thumnailContainer:thumnailContainer
		}
	}
	
	
  

$("body").delegate('#btnMoveUpPDF', 'click', function () {
	$('#tblPDFList select :selected').each(function (i, selected) {
		if (!$(this).prev().length) return false;
		$(this).insertBefore($(this).prev());
	});
	$('#tblPDFList select').focus().blur();
});

$("body").delegate('#btnMoveDownPDF', 'click', function () {
	$($('#tblPDFList select :selected').get().reverse()).each(function (i, selected) {
		if (!$(this).next().length) return false;
		$(this).insertAfter($(this).next());
	});
	$('#tblPDFList select').focus().blur();
});

$("body").delegate('#btnMoveTopPDF', 'click', function () {
	var count_category = 0;
	$.each($('#tblPDFList select :selected'), function (i, row) {
		count_category = count_category + 1
	});
	if (count_category > 0){
		var first_elem = $("#tblPDFList option:first");
		if(first_elem.is(':selected')) return true;
		while(!first_elem.prev().length){
			$('#tblPDFList select :selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#tblPDFList select').focus().blur();
		}	
	}
});

$("body").delegate('#btnMoveBottomPDF', 'click', function () {	
	var count_category = 0;
	$.each($('#tblPDFList select :selected'), function (i, row) {
		count_category = count_category + 1
	});
	if (count_category > 0){
		var last_elem = $("#tblPDFList option:last");
		if(last_elem.is(':selected')) return true;
		while(!last_elem.next().length){
			$($('#tblPDFList select :selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#tblPDFList select').focus().blur();
		}
	}
});
  $("body").delegate('#btn_select_from_complete', 'click', function () {
		var lst = [];
		$("#tblPDFList option").each(function () {
			lst.push($(this).val());
		});	
		
		$.ajax({
			type: 'GET',
			url: "select_from_complete",
			dataType: 'script',
			data: {
				list_id_pdf: lst
			},
			success: function(data, textStatus, jqXHR){
				;
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});
	$("body").delegate('#btnMergePdf', 'click', function () {
		var lst = [];
		$("#tblPDFList option").each(function () {
			lst.push($(this).val());
		});			
		if(lst.length == 0) return;
		window.location.href = window.origin + '/pdf/merge_pdfs_to_one?tblPDFList='+lst;
	});
	$("body").delegate('#btn_delete_list_merge_pdf', 'click', function () {
		var x = document.getElementById("lstPdfMerge");
  		x.remove(x.selectedIndex);
	});

	
	window.$("#modal_select_from_computer").on("shown.bs.modal", function () {
		load_drop_zone_for_pdf();
	})

	$("body").delegate('#pdf_edit_show_comment', 'click', function () {
		var pdf_right_bar_content = el('pdf_right_bar_content');
		var display_none = 'd-none';
		if (pdf_right_bar_content.classList.contains(display_none)){
			this.setAttribute('selected',true);
			pdf_right_bar_content.classList.remove(display_none);
			document.querySelector(".view_tool_bar").style.setProperty('right', pdf_right_bar_content.offsetWidth + "px", 'important');
			
			
		}else{
			this.removeAttribute('selected');
			pdf_right_bar_content.classList.add(display_none);
			document.querySelector(".view_tool_bar").style.setProperty('right', '0px', 'important');
			
		}
		
	});
	
	window.$('#modal-pdf-discussions').on('shown.bs.modal', function (e) {
		load_quill_discussion();
	})

	if ($("#input_discussion").length > 0) {
    load_quill_discussion();
  }

  // $("#v-pills-tab-right-stamp > a#v-pills-message-tab2").click(function() {
  // 	// var nextURL = window.location.origin + "/bulletin_subject/index?bulletin_category_id=<%= @bulletin_category.id %>";
	// 	// var nextTitle = '';
	// 	// var nextState = '';
	// 	// window.history.pushState(nextState, nextTitle, nextURL);
	// 	// window.history.replaceState(nextState, nextTitle, nextURL);

  // 	if (($(this).length > 0) && (this.classList.contains("active"))) {
  // 		$.ajax({
	// 			type: 'GET',
	// 			url: "/pdf/display_modal_comment",
	// 			dataType: 'script',
	// 			data: {
	// 				pdf_slug: document.getElementById("pdf_slug").value
	// 			},
	// 			success: function(data, textStatus, jqXHR){
	// 			},
	// 			error:function(jqXHR, textStatus, errorThrown){
	// 				console.log("AJAX Error: #{textStatus}")
	// 			}
	// 		});
  // 	}
  // });

  $("#v-pills-tab-right-stamp > a.nav-link").click(function() {
  	var nextURL = window.location.href
		var nextTitle = '';
		var nextState = '';
		
		var tab_selected = this.id;
		switch (tab_selected) {
			case "v-pills-stamp-tab2":
				nextURL = window.location.href.split("?")[0];
				window.history.pushState(nextState, nextTitle, nextURL);
				window.history.replaceState(nextState, nextTitle, nextURL);
				break;
			case "v-pills-workflow-tab2":
				nextURL = window.location.href.split("?")[0] + "?tab_selected=" + tab_selected;
				window.history.pushState(nextState, nextTitle, nextURL);
				window.history.replaceState(nextState, nextTitle, nextURL);
				break;
			case "v-pills-message-tab2":
				nextURL = window.location.href.split("?")[0] + "?tab_selected=" + tab_selected;
				window.history.pushState(nextState, nextTitle, nextURL);
				window.history.replaceState(nextState, nextTitle, nextURL);
		}			
  	
  });
	
  $('body').delegate('#chatInput #upload-file-button', 'click', function (e) {
   		$("#js_add_more_attachment_stamp").click()
	});

	$('body').delegate('.js_add_attachment_stamp', 'click', function (e) {
    $(this).parent().find(".js_remove_attachment_stamp").click();   
	});

	$('body').delegate('#select_file_for_comment_box_stamp', 'cocoon:after-insert', function (e) {
    $(this).find("div.nested-fields:last input:first").click()
	});

	$('body').delegate('#select_file_for_comment_box_stamp div.nested-fields .js_input_attachment_stamp', 'cancel', function() {
		if (this.files.length < 1) {
			$(this).parent().find(".js_remove_attachment_stamp").click();
		}
	})

	$('body').delegate('#select_file_for_comment_box_stamp div.nested-fields .js_input_attachment_stamp', 'change', function() {
		var $input = $(this);
		if (this.files && this.files.length) {
			let reader = new FileReader();
			this.enabled = false
			reader.onload = (function (e) {
				const ImgType = new RegExp('^image\/*');
				let type_file = get_attchment_type(e)
				let template = []
				if(ImgType.test(type_file)) {
					template.push('<img src="'+e.target.result+'" />')
					$input.prev(".js_add_attachment_stamp").html(['<img src="'+e.target.result+'" />'].join(''))
				}	else {
					switch (type_file) {
						case 'application/pdf':
							template.push('<img src="/images/pdf.png" />')
							break;
						case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
							template.push('<img src="/images/word.png" />')
							break;
						case 'application/msword':
							template.push('<img src="/images/word.png" />')
							break;
						case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							template.push('<img src="/images/excel.png" />')
							break;
						case 'application/vnd.ms-excel':
							template.push('<img src="/images/excel.png" />')
							break;
						case 'applications/ppt':
							template.push('<img src="/images/powerpoint.png" />')
							break;
						case 'applications/pptx':
							template.push('<img src="/images/powerpoint.png" />')
							break;
						default:
							template.push('<img src="/images/file.png" />')
							break;
					}
				}

				template.push('<span class="custom-btn-destroy-attachment destroy_attachment">x</span>');
				$input.prev(".js_add_attachment_stamp").html(template.join(''));
			});
			$(this).parent().find(".js_label_attachment_stamp").html(`<label>${this.files[0].name}</label>`)
			reader.readAsDataURL(this.files[0]);
		}

	});



})

function get_attchment_type(ob) {
	let text_type = ob.target.result.split(";base64")[0];
	return text_type.substr(5);
}

function load_drop_zone_for_pdf(){
	$(function() {
		$('.dropzone').each(function () {

			let dropzoneControl = $(this)[0].dropzone;
			if (dropzoneControl) {
				dropzoneControl.destroy();
			}
		});
		//Dropzone class
		var dopzone=$('#dropzone_for_merge_pdf');
		if(dopzone.length>0){
		  $('#dropzone_for_merge_pdf').dropzone({
			url: "create_pdf_tem",
			paramName: "file",
			maxFilesize: 50,
			maxFiles: 10,			
			previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,
	
			maxFiles: 100,
			acceptedFiles: ".pdf",        
			clickable: true,
			accept: function(file, done) {
			  switch (file.type) {
				case 'application/pdf':
				  file.previewElement.querySelector("img").src= '/images/pdf.png';
				  break;	
				default:
				  var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
				  progressElement.querySelector(".progress-text").textContent = "error";
				  break;	
			  }
		  
			  done();
			},
			init: function() {
			  this.on("error", function(file, message, xhr) { 
				if (xhr == null)
				{
				  this.removeFile(file);				
				} 
				alert(file.name + " upload error : "+ message);
				
			 });
	
			   this.on("addedfile", function(file) {				 
				document.getElementById('borrow_id').style.display="block";
				document.getElementById('dropzone_for_merge_pdf').style.display="block";
				document.getElementById('dropzone_for_merge_pdf').style.minHeight="min-content";
			   });
				
				this.on("success", function(file, responseText) 
				{   						
					var fileSelectd=responseText.file;
					file.previewTemplate.querySelector('.id_file_pdf').value = fileSelectd.slug;
					file.previewTemplate.querySelector('.id_file_pdf').setAttribute("data-name",fileSelectd.filename);
					file.previewTemplate.querySelector('.id_file_pdf').setAttribute("data-path",fileSelectd.path.url);
					var removeButton = file.previewElement.querySelector('[data-dz-remove]');
					removeButton.addEventListener("click", function (e) {
					e.preventDefault();
					e.stopPropagation();
					var id_file_pdf = file.previewElement.querySelector('.id_file_pdf').value;
					$.ajax({
					  type: 'GET',
					  url: "destroy_file_pdf",
					  dataType: 'script',
					  data: {
						id_file_pdf: id_file_pdf
					  },
					  success: function(data, textStatus, jqXHR){							
						;
					  },
					  error:function(jqXHR, textStatus, errorThrown){
						console.log("AJAX Error: #{textStatus}")
					  }
					});
				})
				  });
				this.on("uploadprogress", function(file, progress, bytesSent) {
				  if (file.previewElement) {
					  var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
					  progressElement.style.width = progress + "%";
					  progressElement.querySelector(".progress-text").textContent = progress + "%";
				  }
				
				});
			 
			}
		});
	
		}
	});
}


window.load_quill_discussion = function() {
  var buttonSend = document.querySelector('.chat-send');
  var input = { 
	id: 'input_discussion',
		callbackMentionUrl: '/filter_mention_user_pdf_edit_comment',
		status: false,
		submit: function(){
			buttonSend.click();
		}
	}
  var text_Editor = new CustomEditorText(input)
  init_comment(text_Editor);  
  
  
  if(buttonSend){
	buttonSend.addEventListener('click',function(e){	
		
		var container = document.querySelector('#input_discussion');
		var reply_discussion_id = null;
		var repply = container.querySelector('#quote-repply');
		if(repply){
			reply_discussion_id = repply.getAttribute('data-discussion');
		}

		var form = document.querySelector('#new_discussion');	
		var body = form.querySelector('#discussion_body');
		body.value = text_Editor.value();		
		
		var discussion_parent_id = form.querySelector('#discussion_parent_id');
		discussion_parent_id.value = reply_discussion_id;	

		var reply = document.querySelector('#input_discussion #quote-repply');
		if(reply){
			reply.remove();
		}
		

		var btnSubmit = form.querySelector('#btn-submit-discussion');
		btnSubmit.click();
		$("#select_file_for_comment_box_stamp").find(".nested-fields").remove();
		init_comment(text_Editor);

	})
	// addBindingCreated(text_Editor.quill,buttonSend)
	// input_discussion.keyboard.bindings[13].unshift({
    //     key: 13,
    //     handler: (range, context) => {
    //         buttonSend.click();
    //     }
    // });
	// input_discussion.keyboard.bindings[13].unshift({
    //     key: 13,
    //     handler: (range, context) => {
    //         if (this.popupVisible) {
    //             return false;
    //         }
	// 		buttonSend.click();
    //         return true;
    //     }
    // });
	text_Editor.quill.keyboard.addBinding({
		key: 13,
		shiftKey: true,

		}, function(range, context) {
			buttonSend.click();
		});
  }

  $("body").delegate('.repply-discussion', 'click', function () {		
	var discussion_id = this.getAttribute('data-discussion');
	repply_discussion(discussion_id,text_Editor);
  });

  $("body").delegate('#quote-repply .delete-repply', 'click', function () {		
	var quote = this.closest('#quote-repply');
	quote.remove();
	document.querySelector('.ql-editor p').focus();
  });
}

// function addBindingCreated(quill,button){
//     const keyboard = quill.getModule('keyboard');

//     delete keyboard?.bindings['Enter']; // HERE clear all Enter bindings

//     console.log('keyboard 1', keyboard);

//     quill.keyboard.addBinding(    {
//         key: 13, //Enter key
      
//       handler: function(range, context) {
// 		console.log('keyboard 2', keyboard);
// 		button.click()
// 	  }}	
//     );
// 	quill.keyboard.addBinding(    {
//         key: 13, //Enter key
// 		shiftKey: true,

//       handler: function(range, context) {
// 		this.editor.insertText(range.index, '\n');
// 	  }}	
//     );
// }
function init_comment(text_Editor){
	var messageView = document.querySelector('#messageView')
	$(messageView).animate({scrollTop : messageView.scrollHeight},100);	
	text_Editor.reset();	
}
function repply_discussion(discussion_id,text_Editor){
	var container = document.querySelector(`.discussion-item[discussion-id='${discussion_id}']`);
	var user_name = container.querySelector('.user-name').innerHTML;
	var comment = container.querySelector('.discussion-content');
	var comment_clone =  comment.cloneNode(true)
	var reply = document.querySelector('#input_discussion #quote-repply');
	if(reply){
		reply.remove();
	}

	var repply_container = document.getElementById('quote-repply').cloneNode(true);
	repply_container.classList.remove('d-none')
	repply_container.setAttribute('data-discussion', discussion_id);
	var content = repply_container.querySelector('.repply-content');

	var head = content.querySelector('.label-head');
	head.innerHTML = user_name;
	var avartar = content.querySelector('.user-avartar');
	avartar.src = container.querySelector('.user-avartar').src;
	
	content.append(comment_clone);


	container.closest('#chatView').querySelector('#input_discussion').prepend(repply_container);
	container.closest('#chatView').querySelector('[name=toggle_tool]').checked = true;
	text_Editor.focus();

}

function defineDropZone(){
	// end draw
	$('.dropzone').each(function () {
 
	 let dropzoneControl = $(this)[0].dropzone;
	 if (dropzoneControl) {
		 dropzoneControl.destroy();
	 }
   });
 
   // Pre init code
   $(function() {
	//Dropzone class
	var dopzone=$('#myDropzone');
	if(dopzone.length>0){
		var max_file_sizeKb = parseInt(document.getElementById("file_maximum_capacity").value)*1000*1000/(1024*1024);;
	  $('#myDropzone').dropzone({
		url: "create",
		paramName: "file",
		maxFilesize: max_file_sizeKb,
		maxFiles: 10,
		// addRemoveLinks: true,
		previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML, 
		maxFiles: 100,
		acceptedFiles: ".pdf",        
		clickable: true,
		accept: function(file, done) {				
		   file.previewElement.querySelector("img").src= '/images/pdf.png';	   
		   done();
		},
		init: function() {	
			setMessageErrorFile(this.options);	   
		  this.on("complete", function(file) { 			 
			hideFormLoading();
		   });      
		  this.on("error", function(file, message, xhr) { 
				messageErrorFile(file, message);
				defineIcon(file);
		 });

		   this.on("addedfile", function(file) {
			   showFormLoading();		
			   document.getElementById('borrow_id').style.display="block";
			   document.getElementById('myDropzone').style.display="block";
			   document.getElementById('myDropzone').style.minHeight="min-content";
		   });
			
			this.on("success", function(file, responseText) 
			{   				
				var fileSelectd = responseText.file;
				checkEncryption(file,fileSelectd);
				file.previewTemplate.querySelector('.id_file').value = fileSelectd.id;					
				file.previewElement.addEventListener("click", function() 
				{
				  try
				  {					
				   fileSelectd=responseText.file;
				   var idFile = document.getElementById('file_pdf_id');
				   if (idFile) {
					   idFile.value = fileSelectd.id;
					   window.location.href = window.location.origin + `/pdf/view/${fileSelectd.slug}`;
					   return;
				   }           
				  }
				  catch(error)
				  {
					console.log(error);                   
				  }                  
				})
				var removeButton = file.previewElement.querySelector('[data-dz-remove]');
				removeButton.addEventListener("click", function (e) {
				e.preventDefault();
				e.stopPropagation();
				var id_file = file.previewElement.querySelector('.id_file').value;
				$.ajax({
				  type: 'GET',
				  url: "destroy_file_pdf",
				  dataType: 'script',
				  data: {
				   id_file_pdf: id_file
				  },
				  success: function(data, textStatus, jqXHR){
					var item = document.getElementsByClassName('dz-complete');
					if (item.length==0){
					  document.getElementById('myDropzone').style.display="flex";
					  document.getElementById('myDropzone').style.minHeight="500px";
					}
					
				  },
				  error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
				  }
				});
			})
			  });
			this.on("uploadprogress", function(file, progress, bytesSent) {
			  if (file.previewElement) {
				  var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
				  progressElement.style.width =  progress + "%";
				  progressElement.querySelector(".progress-text").textContent = parseInt(progress) + "%";
			  }
			
			});         
		}
	});

	}
  });
 
  }
 
  function checkEncryption(file,fileSelectd) {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = function () {

		var files = new Blob([reader.result], {type: 'application/pdf'});
		
		files.text().then(x=> {		
			var isEncrypted =  x.includes("Encrypt");
			if(isEncrypted){
				file.previewTemplate.querySelector('.file-locked').classList.remove("d-none");
				$.ajax({
					type: 'POST',
					url: "set_encryption",
					dataType: 'json',
					data: {
					  pdf_id: fileSelectd.id,
					  isEncrypted: isEncrypted
					},
					success: function(data, textStatus, jqXHR){
					 
					},
					error:function(jqXHR, textStatus, errorThrown){
					  console.log("AJAX Error: #{textStatus}")
					}
				  });
			}
			
		});

	}
  }
  async function auto_download_pdf(btn){	
	showFormLoading();
	var url = btn.getAttribute("data-url");
	
	var filename = btn.getAttribute("data-name");
	var pdf_id = btn.getAttribute("data-id");
	$.ajax({
		type: 'GET',
		url: window.origin + "/pdf/get_data_file",
		dataType: 'json',
		data: {
		  pdf_id: pdf_id
		},
		success: function(data, textStatus, jqXHR){
			sub_auto_download(pdf_id,url,filename,data.annotations,data.rotations);
			
		},
		error: function(jqXHR, textStatus, errorThrown) {
		  console.log("AJAX Error: " + errorThrown);
		  alert(errorThrown);
		  hideFormLoading(); 
		}
	
	});
	
	
}
async function sub_auto_download(id,url,filename,annotations,rotations){
	try {		
		var bytes =  await fetch_data(url);
		var helper = new PDFDownloadHelper(null);
		await helper.auto_download(id,bytes,filename,annotations,rotations); 	
		hideFormLoading(); 
	} catch (error) {
		alert(error);
		console.log(error);
		hideFormLoading(); 
	}	
}


async function auto_download_multi_pdf(){	
	showFormLoading();	
	// get list pdf_id
	var checkboxes = $("#tbody_tbl_pdf_complete tr td .col_checkbox");
	var lstPdf = [];
	checkboxes.filter(':checked').map(function() {
		lstPdf.push($(this).closest('tr')[0].getAttribute("data-item"));
	})

	if(lstPdf.length==0) {
		hideFormLoading(); 
		return;	
	}
	// get list data
	$.ajax({
		type: 'GET',
		url: window.origin + "/pdf/get_data_multi_file",
		dataType: 'json',
		data: {
			pdf_ids: lstPdf
		},
		success: function(data, textStatus, jqXHR){
			auto_download_multifile_create_data(data.list_data);				
		},
		error: function(jqXHR, textStatus, errorThrown) {
		  console.log("AJAX Error: " + errorThrown);
		  hideFormLoading(); 
		}
	
	});
}
async function auto_download_multifile_create_data(list_data){	
	var base64, data = [],item;
	try {
		var base64, data = [];
		for await(const element of list_data) {     
			item = element;

			if(element.is_exist_file_secured == true || element.is_exist_file_secured == "true") {
				// save annotations
				base64 = null;       
				data.push(JSON.stringify({id: element.id,is_exist_file_secured: element.is_exist_file_secured,url: element.url,filename: element.filename,base64: base64}))		
			}
			else{
				// save annotations
				base64 = await sub_auto_download_multifile(element.url,element.annotations,element.rotations);       
				data.push(JSON.stringify({id: element.id,is_exist_file_secured: element.is_exist_file_secured,url: element.url,filename: element.filename,base64: base64}))		
			}
			
		}
		download_multi_file_pdf_complete_secure(data)
	} catch (error) {
		alert("File name : " + item.filename +"\n"+ error)
		hideFormLoading(); 
	}
	
	//auto_download_multifile_zip_blob(data);
}
function download_multi_file_pdf_complete_secure(datas){
    $.ajax({
        type: "POST",
        url: window.location.origin + "/pdf/download_multi_file_pdf_complete_secure",
        data: {
            datas: datas
        },   
        success: function (data, text) {
            var objects = data.objects;
			if(objects.length != datas.length)
			{
				alert("error : data not matching");
			}
			else{
				get_blob_data_files(objects);
			}
        },
        error: function (request, status, error) {
            alert(request.responseText);           
        }
        
    });
}
async function sub_auto_download_multifile(url,annotations,rotations){	
	var bytes = await fetch_data(url);
	var helper = new PDFDownloadHelper(null);
	return await helper.get_data_base64_file_complete(bytes,annotations,rotations); 	
}
async function get_blob_data_files(list_data){	
	var bytes,blob, data = [];
	for await(const element of list_data) {        
        // save annotations
        bytes = await fetch_data(element.url); 
		blob = new Blob([bytes], {type: "application/pdf"});      
		data.push({filename: element.filename,blob: blob})		
    }
	auto_download_multifile_zip_blob(data)
}
function auto_download_multifile_zip_blob(data) {
	var zipContent = [];
	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		zipContent.push({
			name: element.filename,
			content: element.blob
		});	
	}
	var totalFiles = zipContent.length,file;
	var zip = new JSZip();
	for (var i = 0; i < totalFiles; i++) {
		file = zipContent[i];
		addFileToZip(file,zip);
	}
	zip.generateAsync({
		type: "blob"
	}).then(function (content) {
		// Create a download link
		var link = document.createElement("a");
		link.href = URL.createObjectURL(content);
		link.download = "Completed.zip";
		link.click();
		URL.revokeObjectURL(link.href);
		hideFormLoading(); 
	});
}

function addFileToZip(file,zip) {
	var filename = file.name;
	var content = file.content;
	const currDate = new Date();
    const dateWithOffset = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
    JSZip.defaults.date = dateWithOffset;
	zip.file(filename, content, {
		binary: true
	});	
}

function set_status_button_reject_share() {
	if(document.getElementById("pdf_user_reject_false").checked)
	
	{
		var $option = $("#user_shared_reject").find('option:selected'); //Added with the EDIT

		var value = $option.val(); //to get content of "value" attrib
		// var text = $option.text();//to get <option>Text</option> content
	
		if (value === "" || value === undefined ) {
		  document.getElementById("btn_reject_submit").disabled = true;
		} else {
		  document.getElementById("btn_reject_submit").disabled = false;
		}
	}
	else{
		document.getElementById("btn_reject_submit").disabled = false;
	}
}
 
window.handle_select2_stamp_reject = function(select2_departments, select2_users, modal_reject_pdf) {
  $(select2_departments).select2({dropdownParent: $(modal_reject_pdf)});
  $(select2_users).select2({dropdownParent: $(modal_reject_pdf)});

  $(select2_departments).on('select2:select', function (e) {
		$.ajax({
		  type: 'GET',
		  url: "/pdf/filter_users_reject",
		  dataType: 'script',
		  data: {
			department_id: $("#lst_department_reject option:selected").val()
		  },
		  success: function success(data, textStatus, jqXHR) {
			set_status_button_reject_share() ;
		  },
		  error: function error(jqXHR, textStatus, errorThrown) {
			console.log("AJAX Error");
		  }
		});
	});

  $(select2_users).on('select2:select', function (e) {
    set_status_button_reject_share();
  });
}