import consumer from "./consumer"
export class PdfAnnotationChannel{
  constructor(pdfViewer){
    this.pdfViewer = pdfViewer
    this.channel = create_channel_pdf_edit(pdfViewer);
  }
}

function create_channel_pdf_edit(pdfViewer) {
    return consumer.subscriptions.create({channel: "PdfAnnotationChannel",pdf_id: pdfViewer.file.id,user_id:pdfViewer.user_id}, {
        // return consumer.subscriptions.create("PdfChannel", {
        connected() {
          // Called when the subscription is ready for use on the server
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
      
        received(data) {
          pdfViewer.update_realtime(data);         
         
        }
      });
      
}

